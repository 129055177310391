import React, { Component } from 'react';
import './PanelProccess.scss';
import Icon from '../Icon';

class PanelProccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etapaProcesso: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dados !== state.etapaProcesso) {
      return {
        etapaProcesso: props.dados
      };
    }
    return null;
  }

  render() {

    const etapasDisplay = this.state.etapaProcesso.map((item, index) => {
      return (
        <span key={index} className="panel-step flex"><span className={`number flex ${item.ativo ? 'active' : ''}`}>{item.etapa + 1}</span></span>
      )
    });

    return (
      <div className={`card panel ${this.props.isPositive ? 'success' : 'warn'}`}>
        <div className="d-flex align-items-center justify-content-start mb-2">
          {etapasDisplay}
        </div>
        <div className="flex flex-column align-items-start justify-content-start">
          <p className="lead panel-sub mb-0">{this.props.local}</p>
          <h6 className="panel-title bold mb-0">{this.props.title}</h6>
          <p className="lead panel-sub mt-2 mb-1">autuado em: {this.props.date}</p>
          {this.props.hasLabel ?
            <label className="panel-label">{this.props.label}</label> :
            ''}
          {this.props.downloadLabel ?
            <a target="_blank" rel="noopener noreferrer" href={this.props.downloadLink} title={this.props.downloadLabel} className="button primary md mt-2">
              <Icon name="currency" size={18}></Icon>
              <label>
                {this.props.downloadLabel}
              </label>
            </a>
            :
            ''
          }
        </div>
      </div>
    )
  }
}

export default PanelProccess;