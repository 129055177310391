import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Button from "../../../components/Button";
import Grid from "@material-ui/core/Grid";
// import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { currencyFormatter } from "../../../common/br-currency";
import withdrawMoneyMdlImg from "../../../assets/img/withdraw-modal.png";

import API from "../../../api";
import { authService } from "../../../services/authService";

const ModalWithdraw = (props) => {
  const bankOptions = [
    { value: "1", title: "1 - Banco do Brasil" },
    { value: "33", title: "33 - Santander" },
    { value: "36", title: "36 - Banco Bradesco BBI S.A." },
    { value: "77", title: "77 - Banco Inter S.A." },
    { value: "104", title: "104 - Caixa Econômica Federal" },
    { value: "107", title: "104 - Caixa Econômica Federal" },
    { value: "121", title: "121 - Banco Agibank S.A." },
    { value: "208", title: "208 - Banco BTG Pactual S.A." },
    { value: "212", title: "212 - Banco Original S.A." },
    { value: "237", title: "237 - Banco Bradesco S.A." },
    { value: "246", title: "246 - Banco ABC Brasil S.A." },
    { value: "260", title: "260 - NuBank" },
    { value: "318", title: "318 - Banco BMG S.A." },
    { value: "341", title: "341 - Itaú Unibanco S.A." },
    { value: "422", title: "422 - Banco Safra S.A" },
    { value: "748", title: "748 - Banco Cooperativo Sicredi S.A." },
    { value: "756", title: "756 - Banco Cooperativo do Brasil S.A. - BANCOOB" },
  ];

  const [mdlInsertValue, setMdlInsertValue] = useState({
    openState: props.openState,
  });
  const [bankAcc, setBankAcc] = useState([]);
  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });
  const [investValue, setInvestValue] = useState("0");
  const [userValidate, setUserValidate] = useState({ password: "" });
  const [userValues, setUserValues] = useState(props.userValues);
  const [userId, setuserId] = useState(null);

  const auth = new authService();

  useEffect(() => {
    setMdlInsertValue({
      openState: props.openState,
    });
  }, [props.openState]);

  useEffect(() => {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then((res) => {
        let user_bankname = res.data[0].user_bank_name;
        let bankName = bankOptions.find((item) => item.value === user_bankname);

        setuserId(res.data[0].user_id);
        setUserValidate((prev) => ({
          ...prev,
          email: res.data[0].user_email,
        }));
        setBankAcc([
          { label: "Banco", value: bankName.title },
          { label: "Agência", value: res.data[0].user_bank_agency },
          { label: "C/C", value: res.data[0].user_bank_account },
          { label: "Titular", value: res.data[0].user_name },
          { label: "CNPJ/CPF", value: res.data[0].user_cpf },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const modalItem = () =>
    bankAcc.map((item, index) => {
      return (
        <div key={`modal-item-${index}`} className="content-item">
          <label className="lead">{item.label}</label>
          <h6 className="text-primary">{item.value}</h6>
        </div>
      );
    });

  const updatePass = (value) => {
    setUserValidate((prev) => ({ ...prev, password: value }));
  };

  const onHide = () => {
    setMdlInsertValue((prevState) => ({ ...prevState, openState: false }));
    props.onHide();
  };

  const withdrawMoney = (e) => {
    if (userValidate.password === null || userValidate.password === "") {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>A senha está <b>vazia</b></p>",
      });
      return false;
    } else {
      auth.postLogin(userValidate).then((res) => {
        console.log(res);
        switch (res) {
          case undefined:
          case null:
          case "":
            setSnack({
              openState: true,
              severity: "error",
              message: "<p>A senha está <b>incorreta</b></p>",
            });
            break;

          default:
            tryWithdrawVal();
            break;
        }
      });
    }
  };

  const tryWithdrawVal = () => {
    const { saldo_disponivel } = userValues;

    let newValorRetirada = +parseFloat(
      investValue.toString().replace(/\./g, "").replace(",", ".")
    ).toFixed(2);
    let newSaldoDisponivel = +parseFloat(
      saldo_disponivel.toString().replace(/\./g, "").replace(",", ".")
    ).toFixed(2);

    if (
      newValorRetirada === 0 ||
      newValorRetirada === "" ||
      newValorRetirada === null
    ) {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>Valor para investir é <b>vazio</b></p>",
      });
    } else if (newValorRetirada > newSaldoDisponivel) {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>Você não tem <b>saldo suficiente</b> para saque</p>",
      });
    } else {
      let obj = {
        desc: "Saque",
        status: 1,
        to: 1,
        value: Number(newValorRetirada),
      };
      // console.log(obj);
      // onHide();
      axios
        .post(`${API}/transaction/${userId}/withdraw`, obj)
        .then(function (response) {
          setSnack({
            openState: true,
            severity: "success",
            message: "<p>Saque realizado com <b>sucesso</b></p>",
          });
        })
        .catch(function (error) {
          console.log(error);
          setSnack({
            openState: true,
            severity: "error",
            message: "<p>Erro ao realizar saque</p>",
          });
        })
        .then(() => {
          setTimeout(() => {
            onHide();
          }, 1000);
        });
    }
  };

  function closeSnacks() {
    setSnack((prevState) => ({
      ...prevState,
      openState: false,
    }));
  }

  const mdlSetValueHeader = (
    <h5 className="text-primary title">Valor do investimento</h5>
  );

  const mdlSetValueFooter = (
    <Button
      label="Realizar saque"
      level="primary block"
      onClick={() => {
        withdrawMoney();
      }}
    ></Button>
  );

  return (
    <>
      <Snackbar
        open={snack.openState}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          <div dangerouslySetInnerHTML={{ __html: snack.message }}></div>
        </Alert>
      </Snackbar>
      {mdlInsertValue.openState && (
        <Dialog
          header={mdlSetValueHeader}
          footer={mdlSetValueFooter}
          visible={mdlInsertValue.openState}
          modal
          blockScroll
          onHide={() => {
            onHide();
          }}
        >
          <div className="modal-content withdraw">
            <Grid className="pt-2" container spacing={2}>
              <Grid item xs={12}>
                <p className="lead text-center">
                  Lembre-se que o saque deve ser realizada para uma conta de sua
                  titularidade
                </p>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div style={window.innerWidth < 728 ? { flex: "auto" } : {}}>
                  {modalItem()}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="d-flex align-items-start justify-content-center hideOnMob">
                  <img
                    className="img-full"
                    src={withdrawMoneyMdlImg}
                    alt="withdrawMoneyMdl imagem modal"
                  ></img>
                </div>
              </Grid>
            </Grid>
            <div>
              <Grid className="pt-2" container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="pt-1 mb-2">
                    <span className="p-float-label">
                      <InputText
                        keyfilter={/[\d]/g}
                        value={investValue}
                        inputMode={"numeric"}
                        onClick={() => setInvestValue("0")}
                        onChange={(e) => {
                          setInvestValue(currencyFormatter(e.target.value));
                        }}
                      />
                      <label>Valor</label>
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="pt-1 mb-2">
                    <span className="p-float-label">
                      <InputText
                        type="password"
                        value={userValidate.password}
                        onChange={(e) => {
                          updatePass(e.target.value);
                        }}
                      />
                      <label>Senha</label>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="mb-2">
            <p className="lead">
              * Você pode modificar sua conta bancária a qualquer momento nas
              configurações
            </p>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ModalWithdraw;
