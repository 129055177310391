import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_ListaUsuariosService {
  constructor() {
    moment.locale("pt-br");
  }

  getUsuarios() {
    var mainData = [];

    return axios
      .get(`${API}/user`)
      .then(function (response) {
        // Mapeia o dataAPI e adiciona os valores ao array mainData
        const dataAPI = response.data;
        console.log(dataAPI);

        dataAPI.forEach(function (item) {
          mainData.push({
            idCliente: item.user_id,
            pessoaFisica: item.user_name,
            tipoUsuario:
              item.user_accounttype === "c" ? "Cliente" : "Investidor",
            data: moment(item?.user_dateregister, "YYYY/MM/DD").format(
              "YYYY/MM/DD"
            ),
            saldoDisponivel: item?.balance,
            saldoInvFut: item?.balance2,
            status: getUserStatus(item?.user_rg),
            etapa: getUserStatusLabel(item?.user_rg),
          });
        });

        return mainData;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  getUsuarioByID(userID) {
    var mainData = [];

    return axios.get(`${API}/user/${userID}`).then(function (response) {
      const dataAPI = response.data[0];

      mainData.push({
        id: dataAPI.user_id,
        link:
          "https://app.juscredit.com.br/cadastro?code=" + dataAPI.user_hashcode,
        nome_completo: dataAPI.user_name,
        rg: dataAPI.user_rg,
        email: dataAPI.user_email,
        cpf: dataAPI.user_cpf,
        telefone: dataAPI.user_phone,
        profissao: dataAPI.user_occupation,
        data_nascimento: dataAPI.user_datebirth,
        nacionalidade: dataAPI.user_nationality,
        estado_civil: dataAPI.user_maritalstatus,
        endereco: dataAPI.user_address,
        nome_mae: dataAPI.user_mothername,
        pessoa_politica: dataAPI.user_politicallyexposed,
        renda_mensal: dataAPI.user_monthlyincome
          ?.toString()
          .replace(".", "")
          .replace(",", "."),
        patrimonio_aproximado: dataAPI.user_approximateassets
          ?.toString()
          .replace(".", "")
          .replace(",", "."),
        banco: dataAPI.user_bank_name,
        agencia: dataAPI.user_bank_agency,
        conta_digito: dataAPI.user_bank_account,
        tipo_perfil: dataAPI.user_accounttype,
        tipo_pessoa: dataAPI.user_persontype,
        usuario_aprovado: dataAPI.user_aproved,
        tipo_cliente: dataAPI.user_clienttype,
        status_usuario: dataAPI.user_status,
        primeiro_acesso: dataAPI.user_dateregister.replace("/", ""),
        user_hash: dataAPI.user_hashcode,
        user_fee: dataAPI.user_fee,
        user_withdrawfee: dataAPI.user_withdrawfee,
        user_minvalue: dataAPI.user_minvalue,
        user_proccess: {},
      });

      return mainData[0];
    });
  }

  getUserProccessByID(id, perfil) {
    var mainData = [];

    if (perfil === "c") {
      return axios.get(`${API}/proccess/${id}`).then(function (response) {
        const dataAPI = response.data[0];

        console.log(dataAPI);
        mainData.push({
          valor_bruto: dataAPI.proccess_brutevalue,
          valor_cliente: dataAPI.proccess_clientvalue,
          cnpj: dataAPI.proccess_cnpj,
          sobre_empresa: dataAPI.proccess_companyabout,
          nome_empresa: dataAPI.proccess_companyname,
          pontuacao_empresa: dataAPI.proccess_companyscore,
          data: dataAPI.proccess_date,
          data_finalizado: dataAPI.proccess_datefinalization,
          titulo_download: dataAPI.proccess_downloadlabel,
          link_download: dataAPI.proccess_downloadlink,
          expectparcspread: dataAPI.proccess_expectparcspread,
          expectperiodspread: dataAPI.proccess_expectperiodspread,
          expectspread: dataAPI.proccess_expectspread,
          expecttermspread: dataAPI.proccess_expecttermspread,
          expectvaluespread: dataAPI.proccess_expectvaluespread,
          id: dataAPI.proccess_id,
          label_status: dataAPI.proccess_label,
          valor_minimo: dataAPI.proccess_minvalue,
          numero: dataAPI.proccess_number,
          investidores_pagos: dataAPI.proccess_paid_investors,
          lugar: dataAPI.proccess_place,
          status: dataAPI.proccess_status,
          valor_total: dataAPI.proccess_totalvalue,
        });

        return mainData[0];
      });
    }
  }

  getTransactionByUserID(id) {
    var mainData = [];

    return axios.get(`${API}/transaction/user/${id}`).then((data) => {
      const dataAPI = data;
      console.log(dataAPI);

      data.forEach((value) => {
        mainData.push({
          idTransaction: value.transaction_id,
          descTransaction: value.transaction_desc,
          valueTransaction: value.transaction_value,
          statusTransaction: value.transaction_status,
          fromTransaction: value.transaction_from,
          toTransaction: value.transaction_to,
          registerDateTransaction: value.transaction_registerdate,
        });
      });

      return mainData;
    });
  }

  getTransacoesRealizadas(id, profile) {
    var userData = [];
    return axios.get(`${API}/transaction/user/${id}`).then((res) => {
      // Mapeia o dataAPI e adiciona os valores ao array userData
      const dataAPI = res.data;
      console.log(dataAPI);
      let arrStatus = ["", "Pendente", "Cancelado", "Realizado", "Investido"];

      dataAPI.forEach(function (item) {
        userData.push({
          idMovimentacao: item.transaction_id,
          metodo: item.transaction_desc,
          statusProcesso: item.transaction_status,
          status: arrStatus[item.transaction_status],
          dataMovimentacao: moment(
            item.transaction_registerdate,
            "DD/MM/YYYY"
          ).format("YYYY/MM/DD"),
          trending: getTrending(item.transaction_desc, profile),
          valorMovimentacao:
            item.transaction_value !== null
              ? parseFloat(item.transaction_value?.replace(/,/g, "."))
              : 0,
        });
      });
      return userData;
    });
  }

  postNewUser(newUser) {
    return axios.post(`${API}/user`, newUser);
  }
}

const getUserStatus = (userRG) => {
  switch (userRG) {
    case null:
    case undefined:
    case "":
      return "0";

    default:
      return "1";
  }
};

const getUserStatusLabel = (userRG) => {
  switch (userRG) {
    case null:
    case undefined:
    case "":
      return "Incompleto";

    default:
      return "Completo";
  }
};

const getTrending = (metodo, perfil) => {
  console.log(perfil);
  if (perfil === "i") {
    switch (metodo) {
      // Negativos
      case "Investimento":
      case "Saque":
        return "";

      // Positivos
      case "Depósito":
      case "Recebimento Líquido":
        return "trending-up";

      default:
        return "";
    }
  }
  if (perfil === "c") {
    switch (metodo) {
      // Negativos
      case "Saque":
      case "Taxa Juscredit - Cliente":
        return "";

      // Positivos
      case "Depósito":
      case "Recebimento Líquido":
      case "Investimento":
        return "trending-up";

      default:
        return "";
    }
  }
};
