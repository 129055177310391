import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_ListaProcessosService {
  constructor() {
    moment.locale("pt-br");
  }

  getProcessos() {
    let mainData = [];
    return axios.get(`${API}/proccess`).then(function (response) {
      let dataAPI = response.data;

      dataAPI.forEach((item) => {
        mainData.push({
          idProcesso: item.proccess_id,
          data: item.proccess_date
            ? moment(item.proccess_date, "DD/MM/YYYY").format("YYYY/MM/DD")
            : null,
          status: item.proccess_status,
          etapa: item.proccess_label,
          idCliente: item.clientid,
          pessoaFisica: item.user_name,
          valorProcessoCliente:
            item.proccess_brutevalue !== null
              ? parseFloat(item.proccess_brutevalue?.replace(/,/g, "."))
              : 0,
          valorBrutoAntecipado:
            item.receiptdetail_brute_value == null
              ? 0
              : item.receiptdetail_brute_value,
          valorLiqAntecipado:
            item.receiptdetail_liq_value == null
              ? 0
              : item.receiptdetail_liq_value,
          taxaTrabalhador:
            item.receiptdetail_user_fee_value == null
              ? 0
              : item.receiptdetail_user_fee_value,
        });
      });

      return mainData;
    });
  }

  getAtualizacoes(idProccess) {
    let mainData = [];
    return axios
      .get(`${API}/proccess/${idProccess}/add-detail`)
      .then(function (response) {
        let dataAPI = response.data;
        const { detail } = dataAPI;

        detail.map((item) => {
          mainData.push({
            idProcesso: item.proccess_id,
            idAtualizacao: item.proccessdetail_id,
            data: item.proccessdetail_date
              ? moment(item.proccessdetail_date, "YYYY/MM/DD").format(
                  "YYYY/MM/DD"
                )
              : null,
            text: item.proccessdetail_desc,
          });
        });

        return mainData;
      });
  }

  postUpdateProccess(idProccess, date, desc) {
    return axios
      .post(`${API}/proccess/${idProccess}/add-detail`, {
        date: date,
        desc: desc,
      })
      .then(function (response) {
        console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
