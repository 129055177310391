import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

const NovoUsuarioModal = (props) => {
  const [formValues, setFormValues] = useState({
    full_name: "",
    cpf: "",
    account_type: "",
    person_type: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const tipoPessoaArr = [
    { value: "f", label: "Pessoa Física" },
    { value: "j", label: "Pessoa Jurídica" },
  ];

  const tipoContaArr = [
    { value: "c", label: "Trabalhador" },
    { value: "i", label: "Investidor" },
  ];

  useEffect(() => {
    props.onUpdateValues(formValues);
    props.onDisabled(isDisabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const updateForm = (ev) => {
    const { target, checked } = ev;
    if (target.type === "checkbox") {
      setFormValues({ ...formValues, [target.name]: checked });
    } else {
      setFormValues({ ...formValues, [target.name]: target.value });
    }
  };

  const isDisabled = () => {
    const passwordRegex = /^.{6,}$/;
    let disabledValue = false;
    for (var key in formValues) {
      if (formValues["account_type"] !== "i") {
        delete formValues.investidor_qualificado;
      }
      if (!passwordRegex.test(formValues["password"])) {
        disabledValue = true;
      }
      if (validPassword()) {
        disabledValue = true;
      }
      if (errorCpf()) {
        disabledValue = true;
      }
      if (errorEmail()) {
        disabledValue = true;
      }
      if (formValues[key] === "") {
        disabledValue = true;
      }
    }
    return disabledValue;
  };

  const errorPassword = (params = { showMessage: true }) => {
    const regex = /^.{6,}$/;
    let pass = formValues.password;

    if (pass.length < 6 && !regex.test(pass)) {
      if (params.showMessage) {
        return (
          <div className="error-custom">
            <span>
              Mínimo de 6 caracteres.
              <br />
            </span>
          </div>
        );
      } else {
        return true;
      }
    }
  };

  const validPassword = (params = { showMessage: true }) => {
    let pass = formValues.password;
    let cpass = formValues.confirm_password;

    if (pass !== cpass) {
      if (params.showMessage) {
        return (
          <div className="error-custom">
            <span>Senhas não são iguais</span>
          </div>
        );
      } else {
        return true;
      }
    }
  };

  const errorCpf = (params = { showMessage: true }) => {
    const onlyNumberRegex = /^[0-9]*$/g;
    let cpf = formValues.cpf;
    let strCPF = cpf.replace(/\./g, "").replace(/\-/g, "");
    var Soma;
    var Resto;

    const defaultErrormsg = (
      <div className="error-custom">
        <span>CPF não é válido</span>
      </div>
    );

    Soma = 0;

    if (cpf.length !== 0) {
      if (!onlyNumberRegex.test(cpf)) {
        if (params.showMessage) {
          return (
            <div className="error-custom">
              <span>Não incluir caracteres especiais (exemplo: @!?.,)</span>
            </div>
          );
        } else {
          return true;
        }
      } else {
        if (strCPF === "00000000000" || strCPF.length < 10) {
          if (params.showMessage) {
            return defaultErrormsg;
          } else {
            return true;
          }
        }

        for (let i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
          if (params.showMessage) {
            return defaultErrormsg;
          } else {
            return true;
          }
        }

        Soma = 0;
        for (let i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
          if (params.showMessage) {
            return defaultErrormsg;
          } else {
            return true;
          }
        }
      }
    }
  };

  const errorEmail = (params = { showMessage: true }) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = formValues.email;

    if (email.length !== 0 && !emailRegex.test(email)) {
      if (params.showMessage) {
        return (
          <div className="error-custom">
            <span>Email não é válido</span>
          </div>
        );
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <div>
        <Grid className="pt-2" container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  className={errorEmail() ? "p-invalid" : ""}
                  name="email"
                  value={formValues.email}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>Email</label>
              </span>
              <label>{errorEmail()}</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  className={errorCpf() ? "p-invalid" : ""}
                  name="cpf"
                  value={formValues.cpf}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>CPF</label>
              </span>
              <label>{errorCpf()}</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  name="full_name"
                  value={formValues.full_name}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>Nome completo</label>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  name="number_proccess"
                  value={formValues.number_proccess}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>Número do processo</label>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputMask
                  name="phone"
                  value={formValues.phone}
                  mask="(99) 99999-9999"
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>Celular</label>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  className={errorPassword() ? "p-invalid" : ""}
                  type="password"
                  name="password"
                  value={formValues.password}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />

                <label>Senha</label>
              </span>
              <label>{errorPassword()}</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  className={validPassword() ? "p-invalid" : ""}
                  type="password"
                  name="confirm_password"
                  value={formValues.confirm_password}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                />
                <label>Confirmar Senha</label>
              </span>
              <label>{errorPassword()}</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <Dropdown
                  name="account_type"
                  value={formValues.account_type}
                  options={tipoContaArr}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                  optionLabel="label"
                />
                <label>Tipo de Acesso</label>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="p-field">
              <span className="p-float-label">
                <Dropdown
                  name="person_type"
                  value={formValues.person_type}
                  options={tipoPessoaArr}
                  onChange={(e) => {
                    updateForm(e);
                  }}
                  optionLabel="label"
                />
                <label>Tipo de Pessoa</label>
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NovoUsuarioModal;
