import React, { useState, useEffect } from "react";
import { storage } from "../../../../../firebase";

import { Button } from "primereact/button";
import { authenticateWithCustomToken } from '../../../../../auth';
import axios from 'axios';
import API from '../../../../../api';

const getSignedUrl = async (filePath) => {
  try {
    const response = await axios.post(`${API}/getSignedUrl`, {
      filePath: filePath,
    });
    return response.data.url;
  } catch (error) {
    console.error(error);
  }
};


const ListaDocumento = (props) => {
  const [listDocs, setListDocs] = useState([]);

  useEffect(() => {
    if (props.collection !== "") {
      getDocs(props.collection, props.childCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collection]);

  const getDocs = (collection, childCollection) => {
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    storage
      .ref(collection)
      .child(childCollection)
      .listAll()
      .then((res) => {
        res.items.forEach(function (itemRef, index) {
          var fullPath = itemRef
            .toString().replace(itemRef.bucket + '/','').replace('gs://','');
             
          //storage
            //.ref(`${collection}/${childCollection}`)
            //.child(fullPath)
            //.getDownloadURL()
           // .then((url) => {
              //console.log(url);
              //setListDocs((prev) => [
              //  ...prev,
               // {
               //   file: fullPath,
               //   fileURL: url,
              //  },
              //]);
              getSignedUrl(fullPath).then((url) => {
                setListDocs((prev) => [
                  ...prev,
                  {
                    file: fullPath,
                    fileURL: url,
                  },
                ]);
            });
        });
      });
  };

  const showDoc = (url) => {
    window.open(url);
  };

  return (
    <div className="list-docs-wrapper">
      {listDocs.map((item) => {
        return (
          <div key={item.file} className="list-row">
            <div className="list-column main-column">
              <i className="pi pi-file-pdf mr-1"></i>
              {item.file}
            </div>
            <div className="list-column">
              <Button
                label="Visualizar"
                icon="pi pi-eye"
                onClick={() => {
                  showDoc(item.fileURL);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListaDocumento;
