import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_SaquesService {
  constructor() {
    moment.locale("pt-br");
  }

  getAllSaques() {
    var mainData = [];
    return axios
      .get(`${API}/transaction/withdraw`)
      .then(function (response) {
        const dataAPI = response.data;
        let arrStatus = ["", "Pendente", "Cancelado", "Realizado"];

        console.log(dataAPI);
        mainData = dataAPI.map((transaction) => {
          return {
            data: moment(
              transaction.transaction_registerdate,
              "DD/MM/YYYY"
            ).format("YYYY/MM/DD"),
            idTransacao: transaction.transaction_id,
            idTrabalhador: transaction.user_id,
            tipoUsuario:
              transaction.user_accounttype === "i" ? "Investidor" : "Vendedor",
            nome: transaction.user_name,
            statusNum: transaction.transaction_status,
            status: arrStatus[transaction.transaction_status],
            valor: transaction.transaction_value,
          };
        });

        return mainData;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getSaqueByID(id, idUser) {
    var mainData = null;
    const reqSaque = axios.get(`${API}/transaction/withdraw/${id}`);
    const reqUser = axios.get(`${API}/user/${idUser}`);

    return axios
      .all([reqSaque, reqUser])
      .then(
        axios.spread((...responses) => {
          const resSaque = responses[0].data[0];
          const resUser = responses[1].data[0];

          mainData = {
            idTrabalhador: resSaque.user_id,
            nome: resSaque.user_name,
            user_cpf: resUser.user_cpf,
            user_bank_name: resUser.user_bank_name,
            user_bank_agency: resUser.user_bank_agency,
            user_bank_account: resUser.user_bank_account,
            idTransacao: resSaque.transaction_id,
            data: resSaque.transaction_registerdate,
            status: resSaque.transaction_status,
            value: resSaque.transaction_value,
          };

          return mainData;
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  updateSaqueByID(userData, statusNow) {
    console.log(userData);
    return axios.put(`${API}/transaction/withdraw/${userData.idTransacao}`, {
      id: userData.idTrabalhador,
      status: statusNow,
      value: userData.value,
    });
  }
}
