import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgKwS8hnay3Otfz9la7dGioiojWHkezM0",
  authDomain: "neonio-juscredit-firebase.firebaseapp.com",
  projectId: "neonio-juscredit-firebase",
  storageBucket: "neonio-juscredit-firebase.appspot.com",
  messagingSenderId: "756876646529",
  appId: "1:756876646529:web:af6419c89a31334ecd1580"
};

firebase.initializeApp(firebaseConfig);
 
const storage = firebase.storage();

 
export { storage, firebase as default };

// Chaves de teste
// apiKey: "AIzaSyA12XwDY8jSWTKZowE6ODALy9i7mSwIwmU",
// authDomain: "uploadimage-afbd2.firebaseapp.com",
// databaseURL: "https://uploadimage-afbd2.firebaseio.com",
// projectId: "uploadimage-afbd2",
// storageBucket: "uploadimage-afbd2.appspot.com",
// messagingSenderId: "859152511043",
// appId: "1:859152511043:web:85e36c2ac5018bd26a64f6"
