import React from 'react';
import Inputmask from "inputmask";
import './Input.scss';

import {
  Link
} from "react-router-dom";

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      stateClass: ''
    };
    this.handleChange.bind(this);
    this.handleBlur.bind(this);
    this.initMaskInputs.bind(this);
    this.verifyDirty.bind(this);
  }

  componentDidMount() {
    this.initMaskInputs();
    this.verifyDirty();
  }

  initMaskInputs = () => {
    Inputmask({ showMaskOnFocus: false, showMaskOnHover: false }).mask(document.querySelectorAll("input"));
  }

  verifyDirty = event => {
    const state = this.state;
    const props = this.props;
    if (state.value !== '' || this.props.readOnly || props.value !== '') {
      this.setState({ stateClass: 'dirty-value has-value' });
    }
  }


  handleChange = (event, maxNum, minNum) => {
    var self = this;
    this.setState({
      value: event.target.value,
    }, () => {
      if (this.state.value) {
        this.setState({ stateClass: 'dirty-value has-value' });
      }
      self.props.sideFunc(self.state.value);
    });
  }

  handleBlur = event => {
    var self = this;
    const state = this.state;
    if (state.value !== '') {
      this.setState({
        stateClass: 'dirty-value has-value',
        value: event.target.value
      }, () => {
        if (self.props.sideBlur)
          self.props.sideBlur(self.state.value);
      });
    } else {
      this.setState({
        stateClass: 'dirty-value invalid-value',
        value: event.target.value
      });
    }
  };

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <div className={`input-wrapper ${state.stateClass} ${props.hidden ? 'input-hidden' : ''}`}>
        <div className="input-inner">
          <input
            type={props.type}
            className={state.stateClass + props.value ? 'dirty-value has-value' : ''}
            data-inputmask={props.mask}
            data-inputmask-regex={props.regex}
            name={props.label}
            multiple={props.multiple}
            hidden={props.hidden}
            readOnly={props.readOnly}
            value={props.value ? props.value : state.value}
            onKeyDown={props.onKeyDown}
            onChange={e => { this.handleChange(e, props.maxNum, props.minNum) }}
            onBlur={e => { this.handleBlur(e) }}
            max={props.maxNum ? props.maxNum : ''}
            min={props.minNum ? props.minNum : ''}
            maxLength={props.maxLength}
          ></input>
          {props.type !== "file" ?
            <label>{props.label}</label> : ''}
        </div>
        {props.helpText ?
          <div className="input-helper-wrapper">
            {props.helpLink ?
              <Link to={props.helpLink}>
                <p className="input-helper"><small>{props.helpText}</small></p>
              </Link> : <p className="input-helper"><small>{props.helpText}</small></p>
            }
          </div>
          : ''}
      </div>
    )
  }
}

Input.defaultProps = {
  sideFunc: function () { }
}

export default Input;