import React, { useEffect, useState } from "react";
import axios from "axios";
import Inputmask from "inputmask";

import Button from "../../../components/Button";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

import setValueInvestmentImg from "../../../assets/img/img-value-investment.png";

import API from "../../../api";
import { currencyFormatter } from "../../../common/br-currency";

import { authService } from "../../../services/authService";

const ModalInvestment = (props) => {
  const [user] = useState(props.user);
  const [userValidate, setUserValidate] = useState({ password: "" });
  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });
  const [mdlInvestment, setMdlInvestment] = useState(props.modalInfo);
  const [mdlInsertValue, setMdlInsertValue] = useState({
    openState: false,
    minInvestValue: null,
  });
  const [investValue, setInvestValue] = useState("0");
  const [investCheck, setInvestCheck] = useState(false);
  const auth = new authService();

  useEffect(() => {
    setMdlInvestment(props.modalInfo);
  }, [props.modalInfo]);

  function insertValueInvestment(invID) {
    setMdlInvestment((prev) => ({ ...prev, openState: false }));

    axios
      .get(`${API}/user/${user.user_id}/proccess/${invID}`)
      .then(function (response) {
        let dataAPI = response.data[0];
        setMdlInsertValue({
          openState: true,
          minInvestValue: parseFloat(dataAPI.valor_minimo),
        });
        getUserBasic();
      });
  }

  function getUserBasic() {
    const reqBalance = axios.get(`${API}/transaction/${user.user_id}/balance`);
    const reqUser = axios.get(`${API}/user/${user.user_id}`);

    axios.all([reqBalance, reqUser]).then(
      axios.spread((...responses) => {
        const resBalance = responses[0].data[0];
        const resUser = responses[1].data[0];

        setUserValidate((prev) => ({
          ...prev,
          email: resUser.user_email,
          total_balance: resBalance.total_balance.toFixed(2) * 1,
        }));
      })
    );
  }

  function updatePass(value) {
    setUserValidate((prev) => ({ ...prev, password: value }));
  }

  function addMoneyInvestment(invItem) {
    if (userValidate.password === null || userValidate.password === "") {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>A senha está <b>vazia</b></p>",
      });
      return false;
    } else {
      auth.postLogin(userValidate).then((res) => {
        postNewInvestment(res, invItem);
      });
    }
  }

  function postNewInvestment(valid, invItem) {
    let newInvestValue = +parseFloat(
      investValue.toString().replace(/\./g, "").replace(",", ".")
    ).toFixed(2);

    if (valid === undefined || valid === null || valid === "") {
      setSnack({
        openState: true,
        severity: "error",
        message:
          "<p>Autenticação falhou, verifique sua senha e tente novamente</p>",
      });
    } else {
      if (newInvestValue > userValidate.total_balance) {
        setSnack({
          openState: true,
          severity: "error",
          message:
            "<p>O valor para investir ultrapassa o <b>saldo disponível</b></p>",
        });
        return;
      } else if (
        newInvestValue === null ||
        newInvestValue === "" ||
        newInvestValue === 0
      ) {
        setSnack({
          openState: true,
          severity: "error",
          message: "<p>O valor para investir está <b>vazio</b></p>",
        });
      } else if (
        newInvestValue < mdlInsertValue.minInvestValue &&
        newInvestValue !== invItem.valor_antecipacao - invItem.valor_investido
      ) {
        setSnack({
          openState: true,
          severity: "error",
          message:
            "<p>O valor para investir é <b>menor</b> que o valor <b>MÍNIMO para investimento!</b></p>",
        });
      } else if (
        newInvestValue >
        parseFloat(
          (invItem.valor_antecipacao - invItem.valor_investido).toFixed(2)
        )
      ) {
        setSnack({
          openState: true,
          severity: "error",
          message:
            "<p>O valor para investir é <b>maior</b> que o valor <b>DISPONÍVEL para investimento!</b></p>",
        });
      } else {
        setTimeout(() => {
          localStorage.setItem("refresh", true);
          axios
            .post(`${API}/investment`, {
              investorCod: user.user_id,
              clientCod: invItem.user_id,
              proccess_id: invItem.id,
              value: newInvestValue,
            })
            .then(function (response) {
              setSnack({
                openState: true,
                severity: "success",
                message:
                  "<p>Oportunidade de investimento aplicada com sucesso!</p>",
              });
              setMdlInsertValue((prevState) => ({
                ...prevState,
                openState: false,
              }));
              props.onMoneyInvest();
            })
            .catch(function (error) {
              console.log(error);
            });

          setMdlInsertValue((prevState) => ({
            ...prevState,
            openState: false,
          }));
        }, 1000);
      }
    }
  }

  function closeSnacks() {
    setSnack((prevState) => ({
      ...prevState,
      openState: false,
    }));
  }

  const mdlInvestmentFooter = (
    <div>
      <Button
        label="INVESTIR"
        level="primary block"
        size="md"
        onClick={() => {
          insertValueInvestment(mdlInvestment.infos.id);
        }}
      ></Button>
    </div>
  );

  const mdlInvestmentHeader = (
    <h5 className="text-primary title">Oportunidade de investimento</h5>
  );

  const mdlSetValueFooter = (
    <div>
      <Button
        disabled={investCheck ? false : true}
        label="Confirmar Investimento"
        level="primary block"
        size="md"
        onClick={(e) => {
          addMoneyInvestment(mdlInvestment.infos);
        }}
      ></Button>
    </div>
  );

  const mdlSetValueHeader = (
    <h5 className="text-primary title">Valor do investimento</h5>
  );

  return (
    <>
      <Snackbar
        open={snack.openState}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          <div dangerouslySetInnerHTML={{ __html: snack.message }}></div>
        </Alert>
      </Snackbar>
      {mdlInvestment.openState && (
        <Dialog
          header={mdlInvestmentHeader}
          footer={mdlInvestmentFooter}
          visible={mdlInvestment.openState}
          modal
          blockScroll
          onHide={() =>
            setMdlInvestment((prev) => ({ ...prev, openState: false }))
          }
        >
          <Grid className="investment-card" container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">CNPJ</label>
                <h6 className="text-primary">{mdlInvestment.infos?.cnpj}</h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">devedor</label>
                <h6 className="text-primary">{mdlInvestment.infos?.empresa}</h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">Processo</label>
                <h6 className="text-primary">
                  {mdlInvestment.infos?.numero_processo}
                </h6>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">valor do crédito</label>
                <h6 className="text-primary">
                  R${" "}
                  {Inputmask.format(mdlInvestment.infos?.valor_processo, {
                    alias: "currency",
                    radixPoint: ",",
                  })}
                </h6>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">valor da antecipação</label>
                <h6 className="text-primary">
                  R${" "}
                  {Inputmask.format(mdlInvestment.infos?.valor_antecipacao, {
                    alias: "currency",
                    radixPoint: ",",
                  })}
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">investidores</label>
                <h6 className="text-primary">
                  {mdlInvestment.infos?.investors}
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">
                  Expectativa de rentabilidade bruta no período
                </label>
                <h6 className="text-primary text-spread">
                  {Inputmask.format(mdlInvestment.infos?.valor_spread, {
                    alias: "decimal",
                    radixPoint: ",",
                    groupSeparator: ",",
                    autoGroup: true,
                    suffix: "%",
                  })}{" "}
                  {mdlInvestment.infos?.periodo_spread}
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">
                  perspectiva de prazo total de recebimento do processo
                </label>
                <h6 className="text-primary">
                  {mdlInvestment.infos?.recebimento_processo} Meses
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">sobre o processo</label>
                <h6
                  className="text-primary"
                  style={{ textTransform: "inherit", fontSize: ".92em" }}
                >
                  {mdlInvestment.infos?.sobre_processo}
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">sobre a empresa</label>
                <h6
                  className="text-primary"
                  style={{ textTransform: "inherit", fontSize: ".92em" }}
                >
                  {mdlInvestment.infos?.sobre}
                </h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="flex-0 mb-0">
                <label className="lead card-label">score da empresa </label>
                <h6 className="text-primary">{mdlInvestment.infos?.score}</h6>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="flex-0 mb-3" style={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={
                    100 -
                    ((mdlInvestment.infos?.valor_antecipacao -
                      mdlInvestment.infos?.valor_investido) *
                      100) /
                      mdlInvestment.infos?.valor_antecipacao
                  }
                />
                <label className="lead card-label">
                  {(
                    100 -
                    ((mdlInvestment.infos?.valor_antecipacao -
                      mdlInvestment.infos?.valor_investido) *
                      100) /
                      mdlInvestment.infos?.valor_antecipacao
                  )
                    .toFixed(2)
                    .replace(".", ",")}
                  % de R${" "}
                  {Inputmask.format(mdlInvestment.infos?.valor_antecipacao, {
                    alias: "currency",
                    radixPoint: ",",
                  })}{" "}
                  Investido
                </label>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      )}

      {mdlInsertValue.openState && (
        <Dialog
          header={mdlSetValueHeader}
          footer={mdlSetValueFooter}
          visible={mdlInsertValue.openState}
          modal
          blockScroll
          onHide={() =>
            setMdlInsertValue((prevState) => ({
              ...prevState,
              openState: false,
            }))
          }
        >
          <Grid className="pt-2" container spacing={2}>
            <Grid container item xs={12} sm={12}>
              <p className="text-center mb-3">
                Você está realizando um investimento de alto risco em créditos
                trabalhistas.
              </p>
            </Grid>
            <Grid container item xs={8}>
              <Grid item xs={12} sm={12}>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">
                    Valor disponível para investimento
                  </label>
                  <h6 className="text-primary">
                    R${" "}
                    {Inputmask.format(
                      mdlInvestment.infos.valor_antecipacao -
                        mdlInvestment.infos.valor_investido,
                      { alias: "currency", radixPoint: "," }
                    )}
                  </h6>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">
                    Valor mínimo para investimento
                  </label>
                  <h6 className="text-primary">
                    R${" "}
                    {Inputmask.format(
                      mdlInsertValue.minInvestValue !== null
                        ? mdlInsertValue.minInvestValue
                        : 0,
                      { alias: "currency", radixPoint: "," }
                    )}
                  </h6>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={12} sm={12}>
                <div className="flex-0 mb-0">
                  <img
                    className="img-full"
                    src={setValueInvestmentImg}
                    alt="Imagem de Investimentos"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="mb-3">
                <p className="lead">
                  Quando do recebimento do crédito, a plataforma cobrará taxa de
                  performance de {mdlInvestment.infos.taxa_investimento}% sobre
                  o lucro bruto do investidor.
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="investCheck-id"
                    checked={investCheck}
                    onChange={(e) => setInvestCheck(e.checked)}
                  />
                  <label htmlFor="investCheck-id">
                    Concordo com a formalização da Subcessão de Crédito da minha
                    fração do crédito com a JusCredit.
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="pt-2">
                <span className="p-float-label">
                  <InputText
                    keyfilter={/[\d]/g}
                    symbol=" BRL"
                    symbolPosition="s"
                    decimalSeparator="."
                    thousandSeparator=","
                    value={investValue}
                    inputMode={"monetary"}
                    onClick={() => setInvestValue("0")}
                    onChange={(e) => {
                      setInvestValue(currencyFormatter(e.target.value, true));
                    }}
                  />
                  <label>Valor</label>
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="pt-2">
                <span className="p-float-label">
                  <InputText
                    type="password"
                    value={userValidate.password}
                    onChange={(e) => {
                      updatePass(e.target.value);
                    }}
                  />
                  <label>Senha</label>
                </span>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default ModalInvestment;
