import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import XLSX from "xlsx";

import "./../../../styles/scss/Forms.scss";

import { BO_ListaUsuariosService } from "./services/MainService";
import { parseValue } from "./../../../common/format-number";
import NovoUsuarioModal from "./NovoUsuarioModal/NovoUsuarioModal";

const ListaUsuarios = () => {
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [mdlAddUser, setMdlAddUser] = useState({ openState: false });
  const [snack, setSnack] = useState({
    openState: false,
    severity: "",
    message: "",
  });
  const [userValues, setUserValues] = useState({});
  const [newUserForm, setnewUserForm] = useState({});
  const [newUserFormDisabled, setnewUserFormDisabled] = useState(false);
  const dt = useRef(null);

  let history = useHistory();

  const pageService = new BO_ListaUsuariosService();

  useEffect(() => {
    pageService.getUsuarios().then((data) => setTableData(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
          />
        </span>
        <div>
          {tableData.length !== 0 && (
            <Button
              label="Exportar"
              style={{ fontSize: "1rem" }}
              icon="pi pi-file-excel"
              onClick={() => exportTable()}
            />
          )}
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label="Ver detalhes"
          className="p-button-primary"
          onClick={() => {
            goToDetail(rowData.idCliente);
          }}
        ></Button>
      </React.Fragment>
    );
  };

  const idClienteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Trabalhador</span>
        {rowData.idCliente}
      </React.Fragment>
    );
  };

  const usuarioBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Usuário</span>
        {rowData.pessoaFisica}
      </React.Fragment>
    );
  };

  const tipoUsuarioBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Tipo de Perfil</span>
        {rowData.tipoUsuario}
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.data).format("L")}
      </React.Fragment>
    );
  };

  const saldoUsuarioBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Saldo Disponível</span>
        {parseValue(rowData.saldoDisponivel)}
      </React.Fragment>
    );
  };

  const saldoIFUsuarioBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Saldo Investido / Futuro</span>
        {parseValue(rowData.saldoInvFut)}
      </React.Fragment>
    );
  };

  const etapaBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={"status-badge-user status-" + rowData.status}>
          {rowData.etapa}
        </span>
      </React.Fragment>
    );
  };

  function atualizaFormValues(value, formItem) {
    setUserValues((userValues) => ({ ...userValues, [formItem]: value }));
  }

  function goToDetail(id) {
    history.push("/backoffice/lista-usuarios/usuario/" + id);
  }

  function openAddUserMdl() {
    setMdlAddUser({ openState: true });
  }

  function closeModal() {
    setMdlAddUser({ openState: false });
  }

  function closeSnacks() {
    setSnack({ ...snack, openState: false });
  }

  function exportTable() {
    let data = [];
    let columns = Object.keys(tableData[0]);
    data.push(columns);
    tableData.forEach((value) => {
      var data_value = [];
      columns.forEach((i) => data_value.push(value[i]));
      data.push(data_value);
    });
    data[0] = [
      "Id Cliente",
      "Usuário",
      "Tipo do usuário",
      "Data",
      "Saldo disponivel",
      "Saldo do Investimento futuro",
      "Status",
      "Etapa",
    ];
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    XLSX.writeFile(new_workbook, "usuarios.xlsx");
  }

  function submitUserValues() {
    if (Object.entries(newUserForm).length <= 5) {
      setSnack({
        ...snack,
        openState: true,
        severity: "error",
        message: "Campos não estão completos",
      });
    }

    if (Object.entries(newUserForm).length > 5) {
      pageService
        .postNewUser(newUserForm)
        .then((res) => {
          res.data.code
            ? setSnack({
                ...snack,
                openState: true,
                severity: "error",
                message:
                  "Houve um problema durante a criação do usuário, verifique se os campos estão corretos",
              })
            : setSnack({
                ...snack,
                openState: true,
                severity: "success",
                message: "Usuário criado com sucesso",
              });
        })
        .then(() => {
          pageService
            .getUsuarios()
            .then((data) => setTableData(data))
            .then(() => {
              closeModal();
            });
        });
    }
  }

  const header = renderHeader();

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => closeModal()}
          className="p-button-text"
        />
        <Button
          label="Salvar"
          icon="pi pi-check"
          disabled={newUserFormDisabled}
          onClick={() => submitUserValues()}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div>
      <Snackbar
        open={snack.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <div className="mb-2">
        <h4 className="title">Lista de Usuários</h4>
      </div>
      <div className="mb-4">
        <Button
          label="Adicionar Usuário"
          level="primary"
          size="md"
          onClick={() => {
            openAddUserMdl();
          }}
        ></Button>
      </div>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable
              ref={dt}
              value={tableData}
              resizableColumns={true}
              autoLayout={true}
              sortField="data"
              sortOrder={-1}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              globalFilter={globalFilter}
              paginator
              rows={50}
              emptyMessage="Nenhum dado foi encontrado"
              currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                field="idCliente"
                header="Id Trabalhador"
                body={idClienteBodyTemplate}
                sortable
              />
              <Column
                field="pessoaFisica"
                header="Usuário"
                body={usuarioBodyTemplate}
                sortable
              />
              <Column
                field="tipoUsuario"
                header="Tipo de Perfil"
                body={tipoUsuarioBodyTemplate}
                sortable
              />
              <Column
                field="data"
                header="Data"
                body={dateBodyTemplate}
                sortable
              />
              <Column
                field="saldoDisponivel"
                header="Saldo Disponível"
                body={saldoUsuarioBodyTemplate}
                sortable
              />
              <Column
                field="saldoInvFut"
                header="Saldo Investido / Futuro"
                body={saldoIFUsuarioBodyTemplate}
                sortable
              />
              <Column
                field="etapa"
                header="Status"
                body={etapaBodyTemplate}
                sortable
              />
              <Column
                body={actionBodyTemplate}
                headerStyle={{ width: "10em", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
              />
            </DataTable>
          </div>

          <Dialog
            header="Incluir usuário"
            resizable={false}
            draggable={false}
            modal
            visible={mdlAddUser.openState}
            footer={renderFooter()}
            onHide={() => {
              closeModal();
            }}
          >
            <NovoUsuarioModal
              onUpdateValues={(e) => {
                setnewUserForm(e);
              }}
              onDisabled={(e) => {
                setnewUserFormDisabled(e);
              }}
            ></NovoUsuarioModal>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ListaUsuarios;
