//Check URL is test
let API = "";
console.log("Version: 1.21");
function isTestUrl(url) {
  return url.includes("test");
}
function isLocalhost(url) {
  return url.includes("localhost");
}
Run();
function Run() {
  var color = "#b0d6d6";
  var h2s = window.document.getElementById("root");

  if (isTestUrl(window.location.href)) {
    API = "https://testapi.juscredit.com.br/api/v1";
    h2s.style.setProperty("background-color", color, "important");
    setTimeout(function () {
      var h3s = window.document.getElementsByClassName("main-content-wrapper");
      h3s[0].style.setProperty("background-color", color, "important");
    }, 1500);
  } else if (isLocalhost(window.location.href)) {
    API = "http://localhost:3001/api/v1";
    h2s.style.setProperty("background-color", color, "important");
    setTimeout(function () {
      var h3s = window.document.getElementsByClassName("main-content-wrapper");
      h3s[0].style.setProperty("background-color", color, "important");
    }, 1500);
  } else {
    API = "https://api.juscredit.com.br/api/v1";
  }
}
export default API;
