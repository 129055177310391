import React, { Component } from 'react';
import './Stepper.scss';

class StepperContainer extends Component {
  render() {
    const props = this.props;

    return (
      <div className="stepper-container">
        {props.children}
      </div>
    );
  }
}

export default StepperContainer;