import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// import { useHistory } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';

const InvestidoresProcessoTable = (props) => {

  const [tableData, setTableData] = useState(props.dataSource);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    setTableData(props.dataSource)
  }, [props.dataSource]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
      </div>
    );
  }

  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" label="Ver detalhes" className="p-button-primary" onClick={() => { goToDetail(rowData.idProcesso) }}></Button>
  //     </React.Fragment>
  //   );
  // }

  const idTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </React.Fragment>
    );
  }

  const dataInvestimentoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data de Investimento</span>
        {moment(rowData.investment_date).format('L')}
      </React.Fragment>
    );
  }

  const IdUsuarioTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID Usuário</span>
        {rowData.user_id}
      </React.Fragment>
    );
  }

  const investidorTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Investidor</span>
        {rowData.name}
      </React.Fragment>
    );
  }

  const valorInvestidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido</span>
        {rowData.investValue}
      </React.Fragment>
    );
  }

  const porcentagemTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Porcentagem</span>
        {rowData.perc}
      </React.Fragment>
    );
  }

  const lucroBrutoTotalTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Lucro B. Total</span>
        {rowData.totalBruteProfit}
      </React.Fragment>
    );
  }

  const taxaInvestidorTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Taxa Investidor</span>
        {rowData.investFeeR}
      </React.Fragment>
    );
  }

  const lucroLiquidoTotalTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Lucro L. Total</span>
        {rowData.totalLiqProfit}
      </React.Fragment>
    );
  }

  const recebidoBrutoTotalTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Recebido B. Total</span>
        {rowData.totalReceivedBrute}
      </React.Fragment>
    );
  }

  const recebidoLiquidoTotalTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Recebido L. Total</span>
        {rowData.totalReceivedLiq}
      </React.Fragment>
    );
  }

  const spreadBrutoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Spread B.</span>
        {rowData.bruteSpread}
      </React.Fragment>
    );
  }

  const spreadLiquidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Spread L.</span>
        {rowData.liqSpread}
      </React.Fragment>
    );
  }

  const header = renderHeader();

  return (
    <>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="idProcesso" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="id" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="id" header="ID" body={idTemplate} sortable />
              <Column field="investment_date" header="Data de Investimento" body={dataInvestimentoTemplate} sortable />
              <Column field="user_id" header="ID Usuário" body={IdUsuarioTemplate} sortable />
              <Column field="name" header="Investidor" headerStyle={{ minWidth: '12em' }} body={investidorTemplate} sortable />
              <Column field="investValue" header="Valor Investido" headerStyle={{ minWidth: '12em' }} body={valorInvestidoTemplate} sortable />
              <Column field="perc" header="Porcentagem" headerStyle={{ width: '10em' }} body={porcentagemTemplate} sortable />
              <Column field="totalBruteProfit" header="Lucro B. Total" headerStyle={{ minWidth: '12em' }} body={lucroBrutoTotalTemplate} sortable />
              <Column field="investFeeR" header="Taxa Investidor" body={taxaInvestidorTemplate} sortable />
              <Column field="totalLiqProfit" header="Lucro L. Total" headerStyle={{ minWidth: '12em' }} body={lucroLiquidoTotalTemplate} sortable />
              <Column field="totalReceivedBrute" header="Recebido B. Total" headerStyle={{ minWidth: '12em' }} body={recebidoBrutoTotalTemplate} sortable />
              <Column field="totalReceivedLiq" header="Recebido L. Total" headerStyle={{ minWidth: '12em' }} body={recebidoLiquidoTotalTemplate} sortable />
              <Column field="bruteSpread" header="Spread B." body={spreadBrutoTemplate} sortable />
              <Column field="liqSpread" header="Spread L." body={spreadLiquidoTemplate} sortable />
              {/* <Column body={actionBodyTemplate} headerStyle={{ width: '10em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvestidoresProcessoTable;