const rules = {
  required: "Campo não pode ser vazio.",
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Email inválido. Ex.: example@email.com",
    },
  },
  data: {
    pattern: {
      value: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i,
      message: "Data inválida. Ex.: 99/99/9999",
    },
  },
  rg: {
    pattern: {
      value:
        /(^(\d{2}\x2E\d{3}\x2E\d{3}[-]\d{1})$|^(\d{2}\x2E\d{3}\x2E\d{3})$)/i,
      // value: /[0-9]/i,
      message: "RG inválido. Ex.: 99.999.999 ? -9",
    },
  },
  cpf: {
    pattern: {
      value: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/i,
      // value: /[0-9]/i,
      message: "CPF inválido. Ex.: 999.999.999-99",
    },
  },
  cnpj: {
    pattern: {
      value: /^\d{2}\.\d{3}\.\d{3}[/]\d{4}\-\d{2}$/i,
      message: "CPF inválido. Ex.: 99.999.999/9999-99",
    },
  },
  telefone: {
    pattern: {
      value: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/i,
      message: "Telefone inválido. Ex.: (99) 99999-9999",
    },
  },
  cep: {
    pattern: {
      value: /[0-9]{5}-[\d]{3}/i,
      // value: /[0-9]{5}[\d]{3}/i,
      message: "CEP inválido. Ex.: 99999-999",
    },
  },
  brl: {
    pattern: {
      value: /^\s*(?:[1-9]\d{0,5}(?:\.\d{3})*|0),\d{2}$/i,
      //value: (?:[1-9]d{0,2}(?:,d{3})*|0)(?:.d{1,2})?,
      message: "Valor inválido. Ex.: 9.999,99",
    },
  },
  onlyNumber: {
    pattern: {
      value: /^[0-9]*$/i,
      message: "Valor inválido. Ex.: 99",
    },
  },
};

export default rules;
