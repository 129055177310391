import React, { useState, useEffect } from "react";
import { notNullStr } from "../../../../common/not-null";
import { useHistory } from "react-router-dom";
import { matchPath } from "react-router";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import Input from "../../../../components/Input";
import Panel from "../../../../components/Panel";
import Button from "../../../../components/Button";
import MiniModal from "../../../../components/MiniModal";

import API from "../../../../api";
import FieldsUsuario from "./components/fieldsUsuario";
import ListaDocumento from "./components/listaDocumento";
import TransactionsTable from "./components/transactionsTable";

import { BO_ListaUsuariosService } from "./../services/MainService";

const ListaUsuariosDetalhe = () => {
  const pageService = new BO_ListaUsuariosService();

  const [pageID, setPageID] = useState(null);
  const [proccessID, setProccessID] = useState(null);
  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });

  const [userBasicInfo, setUserBasicInfo] = useState({
    nome_completo: "",
    rg: "",
    email: "",
    cpf: "",
  });
  const [formValid, setFormValid] = useState();
  const [userData, setUserData] = useState({});
  const [userMoneyData, setUserMoneyData] = useState({
    saldo_disponivel: 0,
    saldo_investido: 0,
    saldo_receber: 0,
    saldo_total: 0,
  });
  const [proccessRows, setProccessRows] = useState([
    { id: 0, name: "", investValue: "0", status: "" },
  ]);
  const [transactions, setTransactions] = useState([]);

  const [depositModal, setDepositModal] = useState({});
  const [withdrawModal, setWithdrawModal] = useState({});
  const [deleteUserModal, setDeleteUserModal] = useState({});

  const [btnAprovar, setBtnAprovar] = useState({
    disabled: false,
    loading: false,
  });
  const [btnReenviarEmail, setBtnReenviarEmail] = useState({
    disabled: false,
    loading: false,
  });
  const [btnBloquear, setBtnBloquear] = useState({
    disabled: false,
    loading: false,
  });
  const [btnSacar, setBtnSacar] = useState({ disabled: false, loading: false });
  const [btnDeletar, setBtnDeletar] = useState({
    disabled: false,
    loading: false,
  });
  const [btnDepositar, setBtnDepositar] = useState({
    disabled: false,
    loading: false,
  });
  const [btnSalvar, setBtnSalvar] = useState({
    disabled: false,
    loading: false,
  });

  let history = useHistory();

  useEffect(() => {
    getRouterID();
  }, []);

  useEffect(() => {
    if (pageID) {
      getUsuarioByID();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageID]);

  useEffect(() => {
    if (proccessID) {
      getUserInfosAndData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proccessID]);

  const getRouterID = () => {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/backoffice/lista-usuarios/usuario/:id",
      exact: true,
      strict: false,
    });
    console.log(match.params.id);
    setPageID(match.params.id);
  };

  const aproveUser = () => {
    setBtnAprovar((prev) => ({
      ...prev,
      loading: true,
    }));
    axios
      .post(`${API}/aproveuser/${pageID}`, {})
      .then(function (response) {
        if (response.data.affectedRows === 1) {
          setTimeout(() => {
            setSnack({
              openState: true,
              severity: "success",
              message: "Usuário Aprovado!",
            });
          }, 500);
          setUserData((prev) => ({ ...prev, usuario_aprovado: "1" }));
        }
      })
      .finally(() => {
        setBtnAprovar((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const bloqUser = () => {
    setBtnBloquear((prev) => ({
      ...prev,
      loading: true,
    }));
    axios
      .post(`${API}/blockuser/${pageID}`, {})
      .then(function (response) {
        if (response.data.affectedRows === 1) {
          setTimeout(() => {
            setSnack({
              openState: true,
              severity: "success",
              message: "Usuário bloqueado com sucesso!",
            });
          }, 500);
          setUserData((prev) => ({ ...prev, status_usuario: "0" }));
        }
      })
      .finally(() => {
        setBtnBloquear((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const openDepositModal = () => {
    setDepositModal((prev) => ({ ...prev, statusModal: true }));
  };

  const openWithdrawModal = () => {
    setWithdrawModal((prev) => ({ ...prev, statusModal: true }));
  };

  const openDeleteUserModal = () => {
    setDeleteUserModal((prev) => ({ ...prev, statusModal: true }));
  };

  const resendMail = () => {
    setBtnReenviarEmail((prev) => ({
      ...prev,
      loading: true,
    }));
    axios
      .post(`${API}/resetPassword`, { email: userBasicInfo.email })
      .then(function (response) {
        setTimeout(() => {
          setSnack({
            openState: true,
            severity: "success",
            message: "Email enviado com sucesso!",
          });
        }, 500);
      })
      .finally(() => {
        setBtnReenviarEmail((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const closeModal = () => {
    setDepositModal((prev) => ({ ...prev, statusModal: false }));
  };

  const closeWithdrawModal = () => {
    setWithdrawModal((prev) => ({ ...prev, statusModal: false }));
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModal((prev) => ({ ...prev, statusModal: false }));
  };

  const updateUser = () => {
    setBtnSalvar((prev) => ({
      ...prev,
      loading: true,
    }));
    var obj = { ...userData };
    obj.endereco = {
      cep: userData.cep,
      numero: userData.numero,
      logradouro: userData.logradouro,
      bairro: userData.bairro,
      cidade: userData.cidade,
      estado: userData.estado,
      complemento: userData.complemento,
    };

    axios
      .put(`${API}/user`, obj)
      .then(function (response) {
        console.log(response);

        if (response.data.affectedRows === 1) {
          setTimeout(() => {
            setSnack({
              openState: true,
              severity: "success",
              message: "Usuário atualizado com sucesso!",
            });
          }, 500);
        } else {
          setTimeout(() => {
            setSnack({
              openState: true,
              severity: "error",
              message: "Houve um problema enquanto atualizava o usuário",
            });
          }, 500);
        }
      })
      .finally(() => {
        setBtnSalvar((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const getUsuarioByID = () => {
    axios
      .get(`${API}/user/${pageID}`)
      .then((response) => {
        const dataAPI = response.data[0];

        setUserBasicInfo({
          nome_completo: notNullStr(dataAPI.user_name),
          rg: notNullStr(dataAPI.user_rg),
          email: notNullStr(dataAPI.user_email),
          cpf: notNullStr(dataAPI.user_cpf),
          tipo_perfil: notNullStr(dataAPI.user_accounttype),
          tipo_pessoa: notNullStr(dataAPI.user_persontype),
        });
        setProccessID(dataAPI.proccess_id);
        pageService
          .getTransacoesRealizadas(pageID, dataAPI.user_accounttype)
          .then((r) => setTransactions(r));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let needBeTrue = userBasicInfo.tipo_perfil ? true : false;
    if (needBeTrue) {
      getUserInfosAndData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBasicInfo, userMoneyData.saldo_total]);

  const getUserInfosAndData = () => {
    if (userBasicInfo.tipo_perfil === "c") {
      axios
        .get(`${API}/proccess/${proccessID}`)
        .then(function (response) {
          const proccessData = response.data[0];
          console.log(response);
          setProccessRows([
            {
              id: proccessData.proccess_id,
              name: proccessData.proccess_number,
              investValue: proccessData.proccess_brutevalue,
              status: proccessData.proccess_label,
            },
          ]);

          axios
            .get(`${API}/transaction/${proccessData.proccess_id}/future`)
            .then(function (response) {
              setUserMoneyData((prev) => ({
                ...prev,
                saldo_receber:
                  response.data[0].total !== null
                    ? Number(response.data[0].total)
                    : 0,
              }));
            })
            .then(() => {
              axios
                .get(`${API}/transaction/${pageID}/balance`)
                .then(function (response) {
                  setUserMoneyData((prev) => ({
                    ...prev,
                    saldo_disponivel: response.data[0].total_balance,
                    saldo_total:
                      response.data[0].total_balance +
                      userMoneyData.saldo_receber,
                  }));
                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      axios
        .get(`${API}/investment/${pageID}/total_value`)
        .then(function (response) {
          let dataAPI = response.data[0];

          setUserMoneyData((prev) => ({
            ...prev,
            saldo_investido: dataAPI.Saldo,
          }));
        })
        .then(() => {
          axios
            .get(`${API}/transaction/${pageID}/balance`)
            .then(function (response) {
              setUserMoneyData((prev) => ({
                ...prev,
                saldo_disponivel: response.data[0].total_balance,
                saldo_total:
                  response.data[0].total_balance +
                  userMoneyData.saldo_investido,
              }));
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const atualizaDepositFormValues = (value, formItem) => {
    setDepositModal((prev) => ({
      ...prev,
      [formItem]: value,
    }));
    // this.setState({ depositModal: { ...this.state.depositModal, ...{ [formItem]: value } } });
  };

  const atualizaWithdrawFormValues = (event, formItem) => {
    setWithdrawModal((prev) => ({
      ...prev,
      [formItem]: event,
    }));
    // this.setState({ withdrawModal: { ...this.state.withdrawModal, ...{ [formItem]: event } } });
  };

  const navigateProcesso = () => {
    history.push(`/backoffice/lista-processos/processo/${proccessRows[0].id}`);
  };

  const depositValue = () => {
    setBtnDepositar((prev) => ({
      ...prev,
      loading: true,
    }));
    let obj = {
      desc: "Depósito",
      status: 3,
      from: 1,
      value: depositModal.value
        .replace("R$ ", "")
        .replaceAll(".", "")
        .replace(",", "."),
    };

    axios
      .post(`${API}/transaction/${pageID}/deposit`, obj)
      .then(function (response) {
        setTimeout(() => {
          setSnack({
            openState: true,
            severity: "success",
            message: "Depósito realizado com sucesso!",
          });
        }, 500);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setTimeout(() => {
          setSnack({
            openState: true,
            severity: "error",
            message: "Houve um problema com a sua requisição!",
          });
        }, 500);
      })
      .finally(() => {
        setTimeout(() => {
          setBtnDepositar((prev) => ({
            ...prev,
            loading: false,
          }));
          closeModal();
          getUserInfosAndData();
        }, 300);
      });
  };

  const deleteUser = (answer) => {
    if (answer === "y") {
      setBtnDeletar((prev) => ({
        ...prev,
        loading: true,
      }));

      axios
        .post(`${API}/user/${pageID}/delete`, {})
        .then(function (response) {
          if (response.data.affectedRows === 1) {
            setTimeout(() => {
              setSnack({
                openState: true,
                severity: "success",
                message: "Usuário deletado com sucesso!",
              });
              closeDeleteUserModal();
            }, 500);

            setUserData((prev) => ({
              ...prev,
              status_usuario: "0",
            }));

            // self.setState({ userData: Object.assign(self.state.userData, { "status_usuario": "0" }) });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setBtnDeletar((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    } else {
      closeDeleteUserModal();
    }
  };

  const withdrawValue = () => {
    setBtnSacar((prev) => ({
      ...prev,
      loading: true,
    }));
    let obj = {
      desc: "Saque",
      status: 1,
      to: 1,
      value: withdrawModal.value
        .replace("R$ ", "")
        .replaceAll(".", "")
        .replace(",", "."),
    };

    axios
      .post(`${API}/transaction/${pageID}/withdraw`, obj)
      .then(function (response) {
        setTimeout(() => {
          setSnack({
            openState: true,
            severity: "success",
            message: "Saque realizado com sucesso!",
          });
        }, 500);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setTimeout(() => {
          setSnack({
            openState: true,
            severity: "error",
            message: "Houve um problema com a sua requisição!",
          });
        }, 500);
      })
      .finally(() => {
        setBtnSacar((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const closeSnacks = () => {
    setSnack((prev) => ({
      ...prev,
      openState: false,
    }));
  };

  const contentModal = () => {
    return (
      <div>
        <Grid className="investment-card" container spacing={2}>
          <Grid item xs={12} sm={2}>
            <div className="flex-0 mb-0">
              <label className="lead card-label">id Trabalhador</label>
              <h6 className="text-primary">{pageID}</h6>
            </div>
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className="flex-0 mb-0">
              <label className="lead card-label">Trabalhador</label>
              <h6 className="text-primary">{userData.nome_completo}</h6>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="mb-2">
              <Input
                label="Valor"
                type="text"
                mask="
                  'alias': 'numeric',
                  'radixPoint': ',',
                  'groupSeparator': '.',
                  'autoGroup': true,
                  'digits': 2,
                  'digitsOptional': false,
                  'allowMinus': false,
                  'prefix': 'R$ ',
                  'rightAlign': false
                "
                sideFunc={(e) => {
                  atualizaDepositFormValues(e, "value");
                }}
              ></Input>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            {depositModal.value && (
              <Button
                label="INCLUIR VALOR"
                level="primary block"
                size="md"
                disabled={btnDepositar.loading}
                onClick={() => {
                  depositValue();
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const contentDeleteUserModal = () => {
    return (
      <div>
        <p>Tem certeza que deseja deletar este usuário?</p>
        <Grid className="pt-2 justify-content-around" container spacing={1}>
          <Grid item xs={4} sm={4}>
            <Button
              label="Sim"
              level="primary block"
              size="md"
              disabled={btnDeletar.loading}
              onClick={() => {
                deleteUser("y");
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              label="Não"
              level="primary block"
              size="md"
              disabled={btnDeletar.loading}
              onClick={() => {
                deleteUser("n");
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const contentWithdrawModal = () => {
    return (
      <div>
        <Grid className="investment-card" container spacing={2}>
          <Grid item xs={12} sm={2}>
            <div className="flex-0 mb-0">
              <label className="lead card-label">id Trabalhador</label>
              <h6 className="text-primary">{pageID}</h6>
            </div>
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className="flex-0 mb-0">
              <label className="lead card-label">Trabalhador</label>
              <h6 className="text-primary">{userData.nome_completo}</h6>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="mb-2">
              <Input
                label="Valor"
                type="text"
                mask="
                  'alias': 'numeric',
                  'radixPoint': ',',
                  'groupSeparator': '.',
                  'autoGroup': true,
                  'digits': 2,
                  'digitsOptional': false,
                  'allowMinus': false,
                  'prefix': 'R$ ',
                  'rightAlign': false
                "
                sideFunc={(e) => {
                  atualizaWithdrawFormValues(e, "value");
                }}
              ></Input>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              label="RETIRAR VALOR"
              level="primary block"
              size="md"
              disabled={btnSacar.loading}
              onClick={() => {
                withdrawValue();
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <Snackbar
        open={snack.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <div className="mb-3">
        <h4 className="title">ID Usuário: {pageID}</h4>
        <p className="lead">Visualizando detalhes do usuário {pageID}</p>
      </div>

      <div className="pb-5">
        {Object.keys(userData).length > 0 && (
          <div className="flex justify-content-start mb-3">
            <div className="flex justify-content-start mb-4">
              <div className="mr-1" style={{ flex: "initial" }}>
                <Button
                  label={
                    userData.usuario_aprovado === "1" ? "Aprovado" : "Aprovar"
                  }
                  level="primary"
                  size="md"
                  disabled={btnAprovar.loading}
                  onClick={() => {
                    aproveUser();
                  }}
                />
              </div>
              <div className="mr-1" style={{ flex: "initial" }}>
                <Button
                  label="Resetar senha"
                  level="primary"
                  size="md"
                  disabled={btnReenviarEmail.loading}
                  onClick={() => {
                    resendMail();
                  }}
                />
              </div>
              <div className="mr-1" style={{ flex: "initial" }}>
                <Button
                  label={
                    userData.status_usuario === "0" ? "Bloqueado" : "Bloquear"
                  }
                  level="primary"
                  size="md"
                  disabled={btnBloquear.loading}
                  onClick={() => {
                    bloqUser();
                  }}
                />
              </div>
              {userData.tipo_perfil === "i" ? (
                <div className="mr-1" style={{ flex: "initial" }}>
                  <Button
                    label="Depositar"
                    level="primary"
                    disabled={btnDepositar.loading}
                    size="md"
                    onClick={() => {
                      openDepositModal();
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="mr-1" style={{ flex: "initial" }}>
                <Button
                  label="Sacar"
                  level="primary"
                  size="md"
                  disabled={btnSacar.loading}
                  onClick={() => {
                    openWithdrawModal();
                  }}
                />
              </div>
              <div className="mr-1" style={{ flex: "initial" }}>
                <Button
                  label="Deletar"
                  level="primary"
                  size="md"
                  disabled={btnDeletar.loading}
                  onClick={() => {
                    openDeleteUserModal();
                  }}
                />
              </div>
            </div>
            <div className="flex justify-content-end mb-4">
              <Button
                label="Salvar"
                level="primary"
                size="md"
                disabled={btnSalvar.loading || !formValid}
                onClick={() => {
                  updateUser();
                }}
              />
            </div>
          </div>
        )}
        <div className="mb-3">
          <Panel isPositive>
            <Grid className="investment-card" container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="flex-0 mb-0">
                  <label
                    className="lead card-label mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    Saldo Disponível
                  </label>
                  <h6
                    className="text-primary title"
                    style={{ fontSize: "24px" }}
                  >
                    {Number(userMoneyData.saldo_disponivel || 0).toLocaleString(
                      "pt-br",
                      { style: "currency", currency: "BRL" }
                    )}
                  </h6>
                </div>
              </Grid>
              {userData.tipo_perfil === "c" && (
                <Grid item xs={12} sm={4}>
                  <div className="flex-0 mb-0">
                    <label
                      className="lead card-label mb-1"
                      style={{ fontSize: "12px" }}
                    >
                      Saldo a Receber
                    </label>
                    <h6
                      className="text-primary title"
                      style={{ fontSize: "24px" }}
                    >
                      {Number(userMoneyData.saldo_receber || 0).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )}
                    </h6>
                  </div>
                </Grid>
              )}
              {userData.tipo_perfil === "i" && (
                <Grid item xs={12} sm={4}>
                  <div className="flex-0 mb-0">
                    <label
                      className="lead card-label mb-1"
                      style={{ fontSize: "12px" }}
                    >
                      Saldo Investido
                    </label>
                    <h6
                      className="text-primary title"
                      style={{ fontSize: "24px" }}
                    >
                      {Number(
                        userMoneyData.saldo_investido || 0
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h6>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <div className="flex-0 mb-0">
                  <label
                    className="lead card-label mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    Saldo Total na JusCredit
                  </label>
                  <h6
                    className="text-primary title"
                    style={{ fontSize: "24px" }}
                  >
                    {Number(userMoneyData.saldo_total || 0).toLocaleString(
                      "pt-br",
                      { style: "currency", currency: "BRL" }
                    )}
                  </h6>
                </div>
              </Grid>
            </Grid>
          </Panel>
        </div>
        <FieldsUsuario
          onFormUpdate={(usuarioForm) => {
            setUserData(usuarioForm);
          }}
          onFormValid={(validForm) => {
            setFormValid(validForm);
          }}
        />
        {userData.tipo_perfil === "c" && (
          <div>
            <div className="mt-4 mb-2">
              <h4 className="title">Processo:</h4>
            </div>
            <Panel isPositive>
              <div>
                <Grid className="investment-card" container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <div className="flex-0 mb-0">
                      <label className="lead card-label">ID</label>
                      <h6 className="text-primary">{proccessRows[0].id}</h6>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className="flex-0 mb-0">
                      <label className="lead card-label">Processo</label>
                      <h6 className="text-primary">{proccessRows[0].name}</h6>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className="flex-0 mb-0">
                      <label className="lead card-label">Valor</label>
                      <h6 className="text-primary">
                        {proccessRows[0].investValue}
                      </h6>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className="flex-0 mb-0">
                      <label className="lead card-label">Status</label>
                      <h6 className="text-primary">{proccessRows[0].status}</h6>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className="d-flex justify-content-end">
                      <Button
                        level="primary"
                        size="md"
                        onClick={() => {
                          navigateProcesso();
                        }}
                      >
                        Ver
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Panel>
            {/* <CustomTable order="asc" rowsStatus={this.state.rowsStatus} dataSource={this.state.proccessRows} headCols={this.state.proccessHead}></CustomTable> */}
          </div>
        )}
        <div className="mb-5">
          <div className="mt-4 mb-2">
            <h4 className="title">Documentos:</h4>
          </div>
          <Panel isPositive>
            <div>
              <Grid className="investment-card" container spacing={2}>
                {pageID && (
                  <ListaDocumento
                    collection="docsCADASTRO"
                    childCollection={userBasicInfo.rg + ""}
                  />
                )}
              </Grid>
            </div>
          </Panel>
        </div>
        <div className="mb-5">
          <div className="d-flex align-items-center justify-content-start mb-3">
            <h4 className="title">Transações:</h4>
          </div>
          <TransactionsTable dataSource={transactions}></TransactionsTable>
        </div>
      </div>
      {depositModal.statusModal === true && (
        <MiniModal
          id="transacao-modal-include"
          title="Depositar"
          openState={depositModal.statusModal}
          actionClose={() => {
            closeModal();
          }}
        >
          {contentModal()}
        </MiniModal>
      )}
      {withdrawModal.statusModal === true && (
        <MiniModal
          id="transacao-modal-include"
          title="Sacar"
          openState={withdrawModal.statusModal}
          actionClose={() => {
            closeWithdrawModal();
          }}
        >
          {contentWithdrawModal()}
        </MiniModal>
      )}
      {deleteUserModal.statusModal === true && (
        <MiniModal
          id="transacao-modal-include"
          title="Atenção"
          openState={deleteUserModal.statusModal}
          actionClose={() => {
            closeDeleteUserModal();
          }}
        >
          {contentDeleteUserModal()}
        </MiniModal>
      )}
    </div>
  );
};

export default ListaUsuariosDetalhe;
