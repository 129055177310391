export function currencyFormatter(value, forInput) {
  if (value === "") return "0";
  let received = parseFloat(
    value.toString().replace(",", "").replace(/\./g, "")
  );
  let amount;
  if (!forInput) {
    amount = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: "BRL",
    }).format(received.toFixed(2) * 1);
  } else {
    amount = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: "BRL",
    }).format((received / 100).toFixed(2) * 1);
  }
  return `${amount}`;
}
