import React from 'react';
import Routes from './routes';

import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './styles/scss/App.scss';

const App = () => <Routes />

export default App;