import React, { Component } from "react";
import Button from "./../Button/index";
import Checkbox from "./../Checkbox/index";
import "./PanelOffer.scss";
import API from "../../api";

const axios = require("axios");

class PanelOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etapaProcesso: [
        {
          etapa: 1,
          status: "Processo em análise",
          local: "Vara do trabalho de Itapira/Juiz do Trabalho Titular",
          numero: "0000020-37.2010.5.15.0",
          data: "10/01/2018",
        },
      ],
      formValues: {
        direitos_creditario: false,
        termos_uso: false,
      },
    };
    this.aceitar = this.aceitar.bind(this);
    this.recusar = this.recusar.bind(this);
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.dados !== state.stepsArr) {
  //     return {
  //       stepsArr: props.dados,
  //       slideIndex: props.actualItem
  //     };
  //   }
  //   return null;
  // }

  atualizaFormValues = (event, formItem) => {
    this.setState({
      formValues: Object.assign(this.state.formValues, { [formItem]: event }),
    });
  };

  aceitar() {
    console.log(this.props.id);
    if (
      this.state.formValues.direitos_creditario !== false &&
      this.state.formValues.termos_uso !== false
    ) {
      axios
        .post(`${API}/proccess/aceita-proposta/${this.props.id.id}`, {
          proccess_status: 2,
        })
        .then(function (response) {
          document.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  recusar() {
    console.log("recusa");
  }

  render() {
    const { formValues } = this.state;

    let disableButton = () => {
      const buttonIsEnabled =
        formValues.direitos_creditario !== false &&
        formValues.termos_uso !== false;

      return buttonIsEnabled;
    };

    const isDisabled = disableButton();

    return (
      <div
        className={`card panel ${this.props.isPositive ? "success" : "warn"}`}
      >
        <h6 className="panel-title bold mb-1">
          Proposta de antecipação no valor de{" "}
          <span className="text-primary">
            {Number(this.props.anticipation).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>{" "}
          pelo seu crédito trabalhista
        </h6>
        <p className="lead panel-sub mb-2">
          O valor da sua parte do processo atualmente é de{" "}
          {Number(this.props.user_part).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}{" "}
          e da outra parte{" "}
          {Number(this.props.total - this.props.user_part).toLocaleString(
            "pt-br",
            { style: "currency", currency: "BRL" }
          )}
          . <br />O valor líquido da antecipação da sua parte é de{" "}
          {Number(this.props.anticipation - this.props.tax).toLocaleString(
            "pt-br",
            { style: "currency", currency: "BRL" }
          )}{" "}
          (taxa de intermediação pela plataforma é de{" "}
          {Number(this.props.tax).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
          ).
        </p>
        {/* <p className="lead panel-sub mb-2">
          O valor da sua parte do processo atualmente é de {Number(this.props.anticipation - this.props.tax).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} e
          da outra parte {Number(this.props.total - this.props.anticipation).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}.
          O valor bruto da antecipação da sua parte é de {Number(this.props.user_part).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} e
          a taxa de intermediação pela plataforma é de {Number(this.props.tax).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}.
        </p> */}
        <div className="pointer-events mb-2">
          <Checkbox
            group="direitos_creditario"
            id="aceito_credito"
            sendValue={true}
            helpText="Aceito ceder os direitos do meu crédito judicial"
            sideFunc={(e) => {
              this.atualizaFormValues(e, "direitos_creditario");
            }}
          ></Checkbox>
          <Checkbox
            group="termos_uso"
            id="aceito_termos"
            sendValue={true}
            helpText="Aceito os termos de uso, "
            actionLabel="clique aqui"
            actionLink="/docs/JusCredit_-_Temos_de_Uso_do_Cliente.pdf"
            sideFunc={(e) => {
              this.atualizaFormValues(e, "termos_uso");
            }}
          ></Checkbox>
        </div>
        <div className="flex align-items-end justify-content-start spaced-items">
          <Button
            disabled={!isDisabled}
            label="ACEITAR"
            level="primary"
            size="md"
            onClick={this.aceitar}
          ></Button>
          {/* <Button label="REJEITAR" level="secondary" size="md" onClick={this.recusar}></Button> */}
        </div>
      </div>
    );
  }
}

export default PanelOffer;
