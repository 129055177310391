import React, { Component } from 'react';

class StepperItem extends Component {
  render() {
    const props = this.props;

    return (
      <div className="stepper-item" key={props.id} name={props.slideName} data-finished={props.hasFinished}>
        {props.children}
      </div>
    );
  }
}

export default StepperItem;