export function bankName(bankCode) {
  let strBankCode = bankCode.toString()
  switch (strBankCode) {
    case '':
      return ''
    case '1':
      return '1 - Banco do Brasil'
    case '33':
      return '33 - Santander'
    case '36':
      return '36 - Banco Bradesco BBI S.A.'
    case '77':
      return '77 - Banco Inter S.A.'
    case '104':
      return '104 - Caixa Econômica Federal'
    case '121':
      return '121 - Banco Agibank S.A.'
    case '208':
      return '208 - Banco BTG Pactual S.A.'
    case '212':
      return '212 - Banco Original S.A.'
    case '237':
      return '237 - Banco Bradesco S.A.'
    case '246':
      return '246 - Banco ABC Brasil S.A.'
    case '260':
      return '260 - NuBank'
    case '318':
      return '318 - Banco BMG S.A.'
    case '341':
      return '341 - Itaú Unibanco S.A.'
    case '422':
      return '422 - Banco Safra S.A'
    case '748':
      return '748 - Banco Cooperativo Sicredi S.A.'
    case '756':
      return '756 - Banco Cooperativo do Brasil S.A. - BANCOOB'
    default:
      return 'Banco não encontrado'
  }
}