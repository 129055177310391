import React from 'react';
import './Panel.scss';

const Panel = (props) => {
  return (
    <div className={`card panel ${props.className} ${props.isPositive ? 'success' : 'warn'}`}>
      {!props.children ?
        <h6 className="panel-title bold mb-1">{props.title}</h6> : ''
      }
      {!props.children ?
        <p className="lead panel-sub mb-3">{props.text}</p> : ''}
      {props.hasLabel ?
        <label className="panel-label">{props.isPositive ? 'Aprovado' : 'Negado'}</label> :
        ''}
      {props.children}
    </div>
  )
}

export default Panel;