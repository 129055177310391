import React, { useState, useEffect } from "react";
import { matchPath } from "react-router";
import { uniqueId } from "lodash";
import { storage } from "../../../../firebase";
import moment from "moment";
import axios from "axios";
import Dropzone from "react-dropzone";
import filesize from "filesize";
import { addLocale, locale } from "primereact/api";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";

import Icon from "../../../../components/Icon";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import MiniModal from "../../../../components/MiniModal";
import Panel from "../../../../components/Panel";

import InvestidoresProcessoTable from "./components/investidoresProcessoTable";
import ListaRecebimentosTable from "./components/listaRecebimentosTable";
import FieldsProcesso from "./components/fieldsProcesso";
import ListaDocumento from "./components/listaDocumento";
import ListaAtualizacaoProcessoTable from "./components/listaAtualizacaoProcessoTable";

import API from "../../../../api";
import { BO_ListaProcessosService } from "../services/MainService";
import { authenticateWithCustomToken }  from "../../../../auth";

const ListaProcessosDetalhe = (props) => {
  const pageService = new BO_ListaProcessosService();

  const [idProccess, setIdProccess] = useState(null);
  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });
  const [formValid, setFormValid] = useState();

  const [btnDocsTrabalhador, setBtnDocsTrabalhador] = useState({
    disabled: false,
    loading: false,
  });
  const [btnDocsProcesso, setBtnDocsProcesso] = useState({
    disabled: false,
    loading: false,
  });
  const [btnSalvar, setBtnSalvar] = useState({
    disabled: false,
    loading: false,
  });
  const [btnRecebimentos, setBtnRecebimentos] = useState({
    disabled: false,
    loading: false,
    label: "Salvar",
  });
  const [btnAtualizacoes, setBtnAtualizacoes] = useState({
    disabled: false,
    loading: false,
    label: "Salvar",
  });

  const [feeConfigs, setFeeConfigs] = useState({
    clientFeePerc: "",
    investFee: "",
  });
  const [proccessFields, setProccessFields] = useState({});
  const [investRows, setInvestRows] = useState([]);
  const [receiptsRows, setReceiptsRows] = useState([]);
  const [updatesRows, setUpdatesRows] = useState([]);

  const [mdlRecebimentos, setMdlRecebimentos] = useState({ openState: false });
  const [mdlAtualizacoes, setMdlAtualizacoes] = useState({ openState: false });
  const [formMdlAtualizacoes, setFormMdlAtualizacoes] = useState({
    date: "",
    desc: "",
  });
  const [mdlProccess, setMdlProccess] = useState({
    date: "",
    numberParc: "",
    bruteValue: "",
    investValue: "",
    ir: "",
    desc: "",
    profitBruteParc: "",
    investFee: "",
    parcIr: "",
    profitLiqParc: "",
    valueLiqParc: "",
  });
  const [mdlDeleteUser, setMdlDeleteUser] = useState({ openState: false });

  const [uploadedFilesCliente, setUploadedFilesCliente] = useState([]);
  const [uploadedFilesProcesso, setUploadedFilesProcesso] = useState([]);

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  locale("pt-br");

  useEffect(() => {
    getRouterID();
  }, []);

  const getRouterID = () => {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/backoffice/lista-processos/processo/:id",
      exact: true,
      strict: false,
    });
    setIdProccess(+match.params.id);
  };

  const toggleDeleteUserModal = (isOpen) => {
    setMdlDeleteUser((prev) => ({
      ...prev,
      openState: isOpen,
    }));
  };

  const deleteUser = (answer) => {
    if (answer === "y") {
      axios
        .post(`${API}/proccess/${idProccess}/delete`, {})
        .then(function (response) {
          setTimeout(() => {
            setSnack({
              severity: "success",
              openState: true,
              message: "Processo deletado com sucesso!",
            });
            toggleDeleteUserModal(false);
          }, 500);
          setProccessFields((prev) => ({
            ...prev,
            proccess_status: "7",
          }));
        })
        .catch(function (error) {
          setTimeout(() => {
            setSnack({
              severity: "error",
              openState: true,
              message: error.response.data.msg,
            });
            toggleDeleteUserModal(false);
          }, 500);
        });
    } else {
      toggleDeleteUserModal(false);
    }
  };

  const toggleModal = (isOpen) => {
    setMdlRecebimentos({ openState: isOpen });
  };

  const toggleMdlAtualizacoes = (isOpen) => {
    setMdlAtualizacoes((prev) => ({
      ...prev,
      openState: isOpen,
    }));
  };

  const atualizaModalValues = (event, formItem) => {
    const { value } = event;
    setMdlProccess((prev) => ({
      ...prev,
      [formItem]: value ? value : event,
    }));
  };

  const atualizaMdlAtualizacoesValues = (value, name) => {
    setFormMdlAtualizacoes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getFeeConfigs = () => {
    axios.get(`${API}/config`).then(function (response) {
      setFeeConfigs((prev) => ({
        ...prev,
        clientFeePerc: response.data[0].configuration_feeclient.toString(),
        investFee: response.data[0].configuration_feeinvestor,
      }));
    });
  };

  useEffect(() => {
    if (idProccess !== null) {
      getFeeConfigs();
    }
  }, [idProccess]);

  const getRecebimentosInvestimentos = () => {
    let invest_fee_pond = 0;
    let LucroBrutoTotal = 0;
    let TaxadoInvestidorTotal = 0;
    let LucroLiquidoTotal = 0;
    let ValorLiquidoRecebidoTotal = 0;
    let ValorBrutoTotal = 0;
    let ValorInvestidoTotal = 0;

    axios
      .get(`${API}/receipt/investment/${idProccess}`)
      .then(function (response) {
        let dataAPI = response.data;

        dataAPI.forEach((value) => {
          if (value.receipt_brutevalue !== null) {
            let perc = value.investment_total / value.proccess_brutevalue;
            let invest_fee =
              value.user_fee == null || value.user_fee == 0
                ? feeConfigs.investFee / 100
                : value.user_fee;
            invest_fee_pond += perc * invest_fee;
          }
        });
        console.log(dataAPI);

        setInvestRows(
          dataAPI.map((receipt, index) => {
            if (receipt.receipt_brutevalue !== null) {
              let invest_fee =
                receipt.user_fee == null || receipt.user_fee == 0
                  ? feeConfigs.investFee / 100
                  : receipt.user_fee;
              let perc = receipt.investment_total / receipt.proccess_brutevalue;
              let received_brute = receipt.receipt_brutevalue * perc;
              let lucroBrutoDaParcela =
                received_brute - receipt.receipt_investmentvalue * perc;
              LucroBrutoTotal += lucroBrutoDaParcela;
              let feeInvestor = invest_fee * lucroBrutoDaParcela;
              if (feeInvestor < 0) {
                feeInvestor = 0;
              }
              ValorBrutoTotal = receipt.receipt_brutevalue;
              ValorInvestidoTotal = receipt.receipt_investmentvalue;
              TaxadoInvestidorTotal += feeInvestor;
              let lucro_liq = lucroBrutoDaParcela - feeInvestor;
              LucroLiquidoTotal += lucro_liq;
              let received_liq = received_brute - feeInvestor;
              ValorLiquidoRecebidoTotal += received_liq;
              let spreadLiq =
                (perc *
                  (receipt.receipt_brutevalue -
                    receipt.receipt_investmentvalue) -
                  feeInvestor) /
                (receipt.receipt_investmentvalue * perc);

              return {
                id: Number(index) + 1,
                investment_date: moment(
                  receipt.investment_date,
                  "DD/MM/YYYY"
                ).format("YYYY/MM/DD"),
                user_id: receipt.user_id,
                name: receipt.user_name,
                investValue: Number(receipt.investment_total).toLocaleString(
                  "pt-br",
                  { style: "currency", currency: "BRL" }
                ),
                perc: (perc * 100).toFixed(2) + "%",
                totalBruteProfit: lucroBrutoDaParcela.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                investFeeR: feeInvestor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalLiqProfit: lucro_liq.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalReceivedBrute: received_brute.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalReceivedLiq: received_liq.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                bruteSpread:
                  (
                    ((receipt.receipt_brutevalue -
                      receipt.receipt_investmentvalue) /
                      receipt.receipt_investmentvalue) *
                    100
                  ).toFixed(2) + "%",
                liqSpread: (spreadLiq * 100).toFixed(2) + "%",
              };
            } else {
              let perc = receipt.investment_total / receipt.proccess_brutevalue;
              return {
                id: Number(index) + 1,
                investment_date: moment(
                  receipt.investment_date,
                  "DD/MM/YYYY"
                ).format("YYYY/MM/DD"),
                user_id: receipt.user_id,
                name: receipt.user_name,
                investValue: Number(receipt.investment_total).toLocaleString(
                  "pt-br",
                  { style: "currency", currency: "BRL" }
                ),
                perc: (perc * 100).toFixed(2) + "%",
                totalBruteProfit: (0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                investFeeR: (0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalLiqProfit: (0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalReceivedBrute: (0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                totalReceivedLiq: (0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                bruteSpread: 0 + "%",
                liqSpread: 0 + "%",
              };
            }
          })
        );
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(() => {
        axios
          .get(`${API}/receipt/${idProccess}`)
          .then(function (response) {
            let dataAPI = response.data;

            setReceiptsRows(
              dataAPI.map((receipt, index) => {
                let lucro_bruto = LucroBrutoTotal;
                let invest_fee = TaxadoInvestidorTotal;
                let lucro_liq = LucroLiquidoTotal;
                let value_liq = ValorLiquidoRecebidoTotal;
                let value_inv = ValorInvestidoTotal;
                let value_bruto = ValorBrutoTotal;

                if (
                  receipt.receipt_brutevalue == null ||
                  receipt.receipt_brutevalue == undefined
                ) {
                  receipt.receipt_brutevalue = 0;
                }

                if (
                  receipt.receipt_investmentvalue == null ||
                  receipt.receipt_investmentvalue == undefined
                ) {
                  receipt.receipt_investmentvalue = 0;
                }

                return {
                  data: moment(receipt.receipt_date, "DD/MM/YYYY").format(
                    "YYYY/MM/DD"
                  ),
                  parcNumber: receipt.receipt_parc,
                  bruteValue: Number(
                    receipt.receipt_brutevalue.replace(",", ".")
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  investValue: Number(
                    receipt.receipt_investmentvalue
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  bruteProfit: lucro_bruto.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  investFee: invest_fee.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  liqProfit: lucro_liq.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  liqValue: value_liq.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  receivedValueTotal: value_bruto.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  investValueTotal: value_inv.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
                  desc: receipt.receipt_desc,
                };
              })
            );
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(() => {
        getAtualizacoesProcesso();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    if (feeConfigs !== null) {
      getRecebimentosInvestimentos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeConfigs]);

  const getModalProfitParc = (event, formItem) => {
    setMdlProccess((prev) => ({
      ...prev,
      [formItem]: event,
      profitBruteParc: (
        Number(mdlProccess.bruteValue) - Number(mdlProccess.investValue)
      ).toString(),
      investFee: 0,
      parcIr: 0,
      profitLiqParc: 0,
      valueLiqParc: 0,
    }));
  };

  const uploadFilesCliente = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFilesCliente(uploadedFilesCliente.concat(uploadedFiles));
  };

  const removeUploadedFileCliente = (e, uploadedId, index) => {
    var array = [...uploadedFilesCliente]; // make a separate copy of the array
    array.splice(index, 1);

    setUploadedFilesCliente(array);
  };

  const uploadFilesProcesso = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFilesProcesso(uploadedFilesProcesso.concat(uploadedFiles));
  };

  const removeUploadedFileProcesso = (e, uploadedId, index) => {
    var array = [...uploadedFilesProcesso]; // make a separate copy of the array
    array.splice(index, 1);

    setUploadedFilesProcesso(array);
  };

  const enviaRecebimento = () => {
    let obj = { ...mdlProccess, ...{ proccess_id: idProccess } };
    obj.date = moment(obj.date).format("DD/MM/YYYY");

    setBtnRecebimentos((prev) => ({
      ...prev,
      loading: true,
      label: "Criando Recebimento",
    }));

    axios
      .post(`${API}/receipt`, obj)
      .then(function (response) {
        setSnack({
          severity: "success",
          openState: true,
          message: "Recebimento criado com sucesso!",
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setSnack({
          severity: "error",
          openState: true,
          message: "Problema ao criar uma novo recebimento, contate o suporte.",
        });
      })
      .finally(() => {
        toggleModal(false);
        setBtnRecebimentos((prev) => ({
          ...prev,
          loading: false,
          label: "Salvar",
        }));
        getRecebimentosInvestimentos();
      });
  };

  const enviaAtualizacao = () => {
    const obj = { ...formMdlAtualizacoes };
    obj.date = moment(obj.date).format("YYYY/MM/DD");

    setBtnAtualizacoes((prev) => ({
      ...prev,
      loading: true,
      label: "Criando Atualização",
    }));
    pageService.postUpdateProccess(idProccess, obj.date, obj.desc).then((r) => {
      console.log(r);
      setBtnAtualizacoes((prev) => ({
        ...prev,
        loading: false,
        label: "Salvar",
      }));
      if (r.status === 200) {
        setSnack({
          severity: "success",
          openState: true,
          message: "Nova atualização criada com sucesso",
        });
        setMdlAtualizacoes((prev) => ({
          ...prev,
          openState: false,
        }));
        getAtualizacoesProcesso();
      } else {
        setSnack({
          severity: "error",
          openState: true,
          message: "Problema ao criar uma nova atualização, contate o suporte.",
        });
      }
    });

    // axios.post(`${API}/receipt`, obj)
    //   .then(function (response) {
    //     setSnack({
    //       severity: 'success',
    //       openState: true,
    //       message: 'Atualização criada com sucesso!'
    //     });
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     setSnack({
    //       severity: 'error',
    //       openState: true,
    //       message: 'Problema ao criar uma nova atualização, contate o suporte.'
    //     });
    //   })
    //   .finally(() => {
    //     toggleMdlAtualizacoes(false);
    //     setBtnAtualizacoes(prev => ({
    //       ...prev,
    //       loading: false,
    //       label: 'Salvar',
    //     }));
    //     getRecebimentosInvestimentos();
    //   })
  };

  const getAtualizacoesProcesso = () => {
    pageService.getAtualizacoes(idProccess).then((r) => {
      setUpdatesRows(r);
    });
  };

  const atualizaProcesso = () => {
    setBtnSalvar((prev) => ({ ...prev, disabled: true, loading: true }));
    axios
      .put(`${API}/proccess`, proccessFields)
      .then(() => {
        setSnack({
          severity: "success",
          openState: true,
          message: "Dados atualizados com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setSnack({
          severity: "error",
          openState: true,
          message: "Houve algum erro inesperado",
        });
      })
      .then(() => {
        setTimeout(() => {
          setBtnSalvar((prev) => ({
            ...prev,
            disabled: false,
            loading: false,
          }));
        }, 500);
      });
  };

  const closeSnacks = () => {
    setSnack((prev) => ({
      ...prev,
      openState: false,
      message: "",
    }));
  };

  const UploadDocsCliente = () => {
    // Primeiro os documentos são enviados pro storage
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    setBtnDocsTrabalhador((prev) => ({
      ...prev,
      loading: true,
      disabled: true,
    }));
    let arrayToUp = uploadedFilesCliente;
    for (let index = 0; index < arrayToUp.length; index++) {
      var extension = arrayToUp[index].name.substring(
        arrayToUp[index].name.lastIndexOf(".")
      );

      const uploadTask = storage
        .ref(`PROCESSOS/${idProccess}/doc-cliente${extension.toString()}`)
        .put(arrayToUp[index].file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
        },
        (error) => {
          // error function ...
          console.log(error);
          setBtnDocsTrabalhador((prev) => ({
            ...prev,
            loading: false,
            disabled: false,
          }));
          setSnack({
            severity: "error",
            openState: true,
            message: "Algo deu errado...",
          });
        },
        () => {
          storage
            .ref(`PROCESSOS`)
            .child(`${idProccess}`)
            .listAll()
            .then((res) => {
              setUploadedFilesCliente([]);
              setBtnDocsTrabalhador((prev) => ({
                ...prev,
                loading: false,
                disabled: false,
              }));
              setSnack({
                severity: "success",
                openState: true,
                message: "Documentos enviados com sucesso!",
              });
            });
        }
      );
    }
  };

  const UploadDocsProcesso = () => {
    // Primeiro os documentos são enviados pro storage
    setBtnDocsProcesso((prev) => ({ ...prev, loading: true, disabled: true }));
    let arrayToUp = uploadedFilesProcesso;
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    for (let index = 0; index < arrayToUp.length; index++) {
      var extension = arrayToUp[index].name.substring(
        arrayToUp[index].name.lastIndexOf(".")
      );
      const uploadTask = storage
        .ref(`PROCESSOS/${idProccess}/doc-processo${extension.toString()}`)
        .put(arrayToUp[index].file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
        },
        (error) => {
          // error function ...
          console.log(error);
          setBtnDocsProcesso((prev) => ({
            ...prev,
            loading: false,
            disabled: false,
          }));
          setSnack({
            severity: "error",
            openState: true,
            message: "Algo deu errado...",
          });
        },
        () => {
          storage
            .ref(`PROCESSOS`)
            .child(`${idProccess}`)
            .listAll()
            .then((res) => {
              setUploadedFilesProcesso([]);
              setBtnDocsProcesso((prev) => ({
                ...prev,
                loading: false,
                disabled: false,
              }));
              setSnack({
                severity: "success",
                openState: true,
                message: "Documentos enviados com sucesso!",
              });
            });
        }
      );
    }
  };

  let disableButtonC = () => {
    const buttonIsEnabled = uploadedFilesCliente.length > 0;

    return buttonIsEnabled;
  };

  let disableButtonP = () => {
    const buttonIsEnabled = uploadedFilesProcesso.length > 0;

    return buttonIsEnabled;
  };

  const isDisabledC = disableButtonC();
  const isDisabledP = disableButtonP();

  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <div>
          <Icon name="cloud" size={34}></Icon>
          <ul className="my-2" style={{ listStyle: "inside" }}>
            <li className="lead">Documentos</li>
          </ul>
          <p className="drop-text">
            Arraste seus arquivos ou clique com o mouse
          </p>
        </div>
      );
    }

    if (isDragReject) {
      return (
        <div>
          <Icon name="cloud" size={34}></Icon>
          <ul className="my-2" style={{ listStyle: "inside" }}>
            <li className="lead">Documentos</li>
          </ul>
          <p className="drop-text drop-text-error">Arquivo não suportado</p>
        </div>
      );
    }

    return (
      <div>
        <Icon name="cloud" size={34}></Icon>
        <ul className="my-2" style={{ listStyle: "inside" }}>
          <li className="lead">Documentos</li>
        </ul>
        <p className="drop-text">Solte os Arquivos aqui</p>
      </div>
    );
  };

  const fileListCliente = uploadedFilesCliente.map((uploadedFile, index) => {
    return (
      <li key={`uploadedFile-${uploadedFile.id}`} className="drop-file-item">
        <div className="drop-file-item-inner">
          {uploadedFile.file.type !== "application/pdf" ? (
            <img src={uploadedFile.preview} alt="Prévia da lista de upload" />
          ) : (
            ""
          )}
          <strong>{uploadedFile.name}</strong>
        </div>
        <strong style={{ display: "flex", alignItems: "center" }}>
          {uploadedFile.readableSize}
          <Button
            level="primary"
            size="only-icon"
            onClick={(e) => {
              removeUploadedFileCliente(e, uploadedFile.id, index);
            }}
          >
            <Icon name="close" size={18}></Icon>
          </Button>
        </strong>
      </li>
    );
  });

  const fileListProcesso = uploadedFilesProcesso.map((uploadedFile, index) => {
    return (
      <li key={`uploadedFile-${uploadedFile.id}`} className="drop-file-item">
        <div className="drop-file-item-inner">
          {uploadedFile.file.type !== "application/pdf" ? (
            <img src={uploadedFile.preview} alt="Prévia da lista de upload" />
          ) : (
            ""
          )}
          <strong>{uploadedFile.name}</strong>
        </div>
        <strong style={{ display: "flex", alignItems: "center" }}>
          {uploadedFile.readableSize}
          <Button
            level="primary"
            size="only-icon"
            onClick={(e) => {
              removeUploadedFileProcesso(e, uploadedFile.id, index);
            }}
          >
            <Icon name="close" size={18}></Icon>
          </Button>
        </strong>
      </li>
    );
  });

  const contentDeleteUserModal = () => {
    return (
      <div>
        <p>Tem certeza que deseja deletar este processo?</p>
        <Grid className="pt-2 justify-content-around" container spacing={1}>
          <Grid item xs={4} sm={4}>
            <Button
              label="Sim"
              level="primary block"
              size="md"
              onClick={() => {
                deleteUser("y");
              }}
            ></Button>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              label="Não"
              level="primary block"
              size="md"
              onClick={() => {
                deleteUser("n");
              }}
            ></Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const contentModal = () => {
    return (
      <div>
        <Grid className="pt-2" container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div className="p-float-label input-wrapper">
              <Calendar
                style={{ width: "100%" }}
                dateFormat="dd/mm/yy"
                value={mdlProccess.date}
                minDate={new Date()}
                onChange={(e) => {
                  atualizaModalValues(e, "date");
                }}
              />
              <label>Data</label>
            </div>
            {/* <Input
              label="Data"
              type="text"
              value={mdlProccess.date}
              // mask="'mask': '9{1,2}.9{1,3}.9{1,3}-9{1}','numericInput': true"
              sideFunc={e => { atualizaModalValues(e, 'date') }}
            ></Input> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Número da parcela"
              type="text"
              value={mdlProccess.numberParc}
              sideFunc={(e) => {
                atualizaModalValues(e, "numberParc");
              }}
            ></Input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Valor bruto"
              type="text"
              value={mdlProccess.bruteValue}
              sideFunc={(e) => {
                getModalProfitParc(e, "bruteValue");
              }}
            ></Input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Valor investido"
              type="text"
              value={mdlProccess.investValue}
              sideFunc={(e) => {
                getModalProfitParc(e, "investValue");
              }}
            ></Input>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Input
              label="Descrição (opcional)"
              type="text"
              value={mdlProccess.desc}
              sideFunc={(e) => {
                atualizaModalValues(e, "desc");
              }}
            ></Input>
          </Grid>
        </Grid>
        <div className="mb-4 flex justify-content-end">
          <Button
            disabled={btnRecebimentos.disabled || btnRecebimentos.loading}
            loading={btnRecebimentos.loading}
            label={btnRecebimentos.label}
            level="primary"
            size="md"
            onClick={() => {
              enviaRecebimento();
            }}
          ></Button>
        </div>
      </div>
    );
  };

  const contentModalAtualizacoes = () => {
    return (
      <div>
        <Grid className="pt-2" container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div className="p-float-label input-wrapper">
              <Calendar
                style={{ width: "100%" }}
                dateFormat="dd/mm/yy"
                name="date"
                value={formMdlAtualizacoes["date"]}
                minDate={new Date()}
                onChange={(e) => {
                  atualizaMdlAtualizacoesValues(e.value, "date");
                }}
              />
              <label>Data</label>
            </div>
          </Grid>
          <Grid item xs={12} sm={9}>
            <div className="p-float-label input-wrapper">
              <InputTextarea
                value={formMdlAtualizacoes["desc"]}
                rows={5}
                cols={30}
                name="desc"
                autoResize
                onChange={(e) => {
                  atualizaMdlAtualizacoesValues(e.target.value, "desc");
                }}
              />
              <label>Descrição</label>
            </div>
            {/* <Input
              label="Descrição"
              type="text"
              name="desc"
              value={mdlAtualizacoes.info['desc']}
              sideFunc={e => { atualizaMdlAtualizacoesValues(e, 'desc') }}
            ></Input> */}
          </Grid>
        </Grid>
        <div className="mb-4 flex justify-content-end">
          <Button
            disabled={btnAtualizacoes.disabled || btnAtualizacoes.loading}
            loading={btnAtualizacoes.loading}
            label={btnAtualizacoes.label}
            level="primary"
            size="md"
            onClick={() => {
              enviaAtualizacao();
            }}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Snackbar
        open={snack.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <div className="mb-3">
        <h4 className="title">Processo: {idProccess}</h4>
        <p className="lead">Visualizando detalhes do processo {idProccess}</p>
      </div>
      <div>
        <div className="flex justify-content-start mb-3">
          <div className="mb-4 flex justify-content-end">
            <div className="mr-2" style={{ flex: "initial" }}>
              <Button
                label="Deletar"
                level="primary"
                size="md"
                onClick={() => {
                  toggleDeleteUserModal(true);
                }}
              ></Button>
            </div>
            <div style={{ flex: "initial" }}>
              <Button
                disabled={btnSalvar.disabled || !formValid}
                loading={btnSalvar.loading}
                label="Salvar"
                level="primary"
                size="md"
                onClick={() => {
                  atualizaProcesso();
                }}
              ></Button>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <FieldsProcesso
            onFormUpdate={(proccessFields) => {
              setProccessFields(proccessFields);
            }}
            onFormValid={(validForm) => {
              setFormValid(validForm);
            }}
          />
        </div>
        <div className="mb-10">
          <Panel isPositive>
            <div>
              <Grid className="pt-2" container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <h5 className="title mb-1" style={{ textAlign: "center" }}>
                    Subir documentos do Trabalhador
                  </h5>
                  <Dropzone onDrop={uploadFilesCliente}>
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <section
                        className={`drop-container ${
                          isDragActive ? "drop-success" : ""
                        } ${isDragReject ? "drop-error" : ""}`}
                      >
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          {renderDragMessage(isDragActive, isDragReject)}
                        </div>
                        {!!uploadedFilesCliente.length && fileListCliente}
                      </section>
                    )}
                  </Dropzone>
                  <div className="flex justify-content-center mt-4 mb-1">
                    <Button
                      disabled={!isDisabledC}
                      loading={btnDocsTrabalhador.loading}
                      label="Enviar Documentos"
                      level="primary"
                      size="md"
                      onClick={() => {
                        UploadDocsCliente();
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h5 className="title mb-1" style={{ textAlign: "center" }}>
                    Subir documentos do Processo
                  </h5>
                  <Dropzone onDrop={uploadFilesProcesso}>
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <section
                        className={`drop-container ${
                          isDragActive ? "drop-success" : ""
                        } ${isDragReject ? "drop-error" : ""}`}
                      >
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          {renderDragMessage(isDragActive, isDragReject)}
                        </div>
                        {!!uploadedFilesProcesso.length && fileListProcesso}
                      </section>
                    )}
                  </Dropzone>
                  <div className="flex justify-content-center mt-4 mb-1">
                    <Button
                      disabled={!isDisabledP}
                      loading={btnDocsProcesso.loading}
                      label="Enviar Documentos"
                      level="primary"
                      size="md"
                      onClick={() => {
                        UploadDocsProcesso();
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="mt-3">
                    {idProccess &&
                      btnDocsProcesso.loading ===
                        btnDocsTrabalhador.loading && (
                        <ListaDocumento
                          collection="PROCESSOS"
                          childCollection={idProccess + ""}
                        />
                      )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Panel>
        </div>
        <div className="mb-10">
          <h5 className="title mb-3">Investidores no Processo</h5>
          <InvestidoresProcessoTable
            dataSource={investRows}
          ></InvestidoresProcessoTable>
        </div>
        <div className="mb-5">
          <div className="d-flex align-items-center justify-content-start mb-3">
            <h5 className="title mr-3">Recebimentos</h5>
            <Button
              label="Novo Recebimento"
              level="primary"
              size="md"
              onClick={() => {
                toggleModal(true);
              }}
            ></Button>
          </div>
          <ListaRecebimentosTable
            dataSource={receiptsRows}
          ></ListaRecebimentosTable>
        </div>
        <div className="mb-5">
          <div className="d-flex align-items-center justify-content-start mb-3">
            <h5 className="title mr-3">Atualizações sobre o processo</h5>
            <Button
              label="Nova Atualização"
              level="primary"
              size="md"
              onClick={() => {
                toggleMdlAtualizacoes(true);
              }}
            ></Button>
          </div>
          <ListaAtualizacaoProcessoTable
            dataSource={updatesRows}
          ></ListaAtualizacaoProcessoTable>
        </div>
        {mdlRecebimentos.openState && (
          <MiniModal
            id="recebimentos-modal-detail"
            title="Recebimentos"
            openState={mdlRecebimentos.openState}
            actionClose={() => {
              toggleModal(false);
            }}
          >
            {contentModal()}
          </MiniModal>
        )}
        {mdlAtualizacoes.openState && (
          <MiniModal
            id="atualizacoes-modal-detail"
            title="Atualizações sobre o processo"
            openState={mdlAtualizacoes.openState}
            actionClose={() => {
              toggleMdlAtualizacoes(false);
            }}
          >
            {contentModalAtualizacoes()}
          </MiniModal>
        )}
      </div>
      {mdlDeleteUser.openState && (
        <MiniModal
          id="transacao-modal-include"
          title="Atenção"
          openState={mdlDeleteUser.openState}
          actionClose={() => {
            toggleDeleteUserModal(false);
          }}
        >
          {contentDeleteUserModal()}
        </MiniModal>
      )}
    </div>
  );
};

export default ListaProcessosDetalhe;
