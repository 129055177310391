import React, { Component } from 'react';

import './../../styles/scss/Grid.scss';
// import './TestePerfil.scss';

import Panel from '../../components/Panel';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import Grid from '@material-ui/core/Grid';

class TestePerfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    }
  }

  render() {

    const submitUserValues = () => {
      console.log('OK')
      closeModal()
      setTimeout(() => window.location.href = "/minha-conta", 200)
    }

    function finishTest() {
      openModal()
    }

    const contentModal = () => {
      return(
        <div>
        <Grid className="pt-2" container spacing={1}>
          <Grid item xs={12} sm={12}>
            <p className="text-center mb-4">Tem certeza que deseja enviar essas respostas?</p>
          </Grid>
          <Grid className="pt-2 justify-content-around" container spacing={1}>
            <Grid item xs={4} sm={4}>
              <Button label="Sim" level="primary block" size="md" onClick={() => { submitUserValues() }}></Button>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Button label="Não" level="primary block" size="md" onClick={() => { closeModal() }}></Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      )
    }

    const closeModal = () => {
      this.setState({ openModal: false })
    }

    const openModal = () => {
      this.setState({ openModal: true })
    }

    return (
      <div className="pb-4">
        <h4 className="title">Questionário de Análise do Perfil do Investidor</h4>
        <div className="mt-3">
          <div className="mb-4">
            <Panel isPositive>
              <h6 className="title mt-3">1 - Em quanto tempo você pretende resgatar o valor total dos seus investimentos?*</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="tempo_resgate"
                  id="tr_menor_1_ano"
                  helpText="Em um prazo menor que 1 ano."
                ></Checkbox>
                <Checkbox
                  group="tempo_resgate"
                  id="tr_entre_1_3_anos"
                  helpText="Em um prazo entre 1 e 3 anos."
                ></Checkbox>
                <Checkbox
                  group="tempo_resgate"
                  id="tr_superior_3_anos"
                  helpText="Em um prazo superior a 3 anos."
                ></Checkbox>
              </div>
              <h6 className="title mt-3">2 - Independentemente do prazo que você vai investir, você acredita que precisaria de parte do seu investimento antecipadamente?</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="investimento_antecipado"
                  id="ia_complementar_renda"
                  helpText="Sim, preciso deste dinheiro para complementar minha renda"
                ></Checkbox>
                <Checkbox
                  group="investimento_antecipado"
                  id="ia_utilizar_parte"
                  helpText="Eventualmente posso precisar utilizar uma parte dele"
                ></Checkbox>
                <Checkbox
                  group="investimento_antecipado"
                  id="ia_tempo_aplicado"
                  helpText="Não precisarei deste dinheiro tão cedo. Pode ficar um bom tempo aplicado"
                ></Checkbox>
              </div>
              <h6 className="title mt-3">3 - Vamos supor que você tenha 100 mil para aplicar durante um ano. O quê você escolheria?</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="aplicar_no_ano"
                  id="ana_garantido"
                  helpText="Receber 106 mil garantidos!"
                ></Checkbox>
                <Checkbox
                  group="aplicar_no_ano"
                  id="ana_entre_106_110"
                  helpText="Algo entre R$ 106 mil e R$ 110 mil estaria ótimo!"
                ></Checkbox>
                <Checkbox
                  group="aplicar_no_ano"
                  id="ana_entre_90_125"
                  helpText="Tirando entre R$ 90 mil e R$ 125 mil a coisa fica mais interessante!"
                ></Checkbox>
              </div>
              <h6 className="title mt-3">4 - O que você pensa sobre um investimento que possui certo nível de risco?</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="risco_investimento"
                  id="ri_valorizacao"
                  helpText="Excelente, oportunidade de grande valorização!"
                ></Checkbox>
                <Checkbox
                  group="risco_investimento"
                  id="ri_100_seguro"
                  helpText="Arriscar perder meu dinheiro? Prefiro algo 100% seguro!"
                ></Checkbox>
                <Checkbox
                  group="risco_investimento"
                  id="ri_pouco_risco"
                  helpText="Uma pitadinha de risco em parte do meu dinheiro não faria mal..."
                ></Checkbox>
              </div>
              <h6 className="title mt-3">5 - Qual percentual dos seus investimentos você pretende investir na JusCredit?</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="percentual_investimento"
                  id="pi_10"
                  helpText="Até 10%"
                ></Checkbox>
                <Checkbox
                  group="percentual_investimento"
                  id="pi_10_20"
                  helpText="Entre 10% e 20%"
                ></Checkbox>
                <Checkbox
                  group="percentual_investimento"
                  id="pi_20"
                  helpText="Acima de 20%"
                ></Checkbox>
              </div>
              <h6 className="title mt-3">6 - Qual tipo de investimento de maior risco você já investiu/investe nos últimos 24 meses?</h6>
              <div className="mt-1 mb-3">
                <Checkbox
                  group="ultimos_investidos"
                  id="ui_cdb"
                  helpText="CDB ou Fundos de renda fixa"
                ></Checkbox>
                <Checkbox
                  group="ultimos_investidos"
                  id="ui_renda_fixa"
                  helpText="Fundos de renda fixa, Fundos Multimercado, Fundos de Ação, ETFs, Ações a Vista"
                ></Checkbox>
                <Checkbox
                  group="ultimos_investidos"
                  id="ui_pp_lending"
                  helpText="Crédito (Peer to Peer Lending)"
                ></Checkbox>
              </div>
              <div className="mb-3">
                <Button level="primary" size="md" label="Concluir Teste" onClick={() => { finishTest() }}></Button>
              </div>
              <Modal openState={this.state.openModal} modalTitle={`Confirmação`} actionClose={() => { closeModal() }}>
                {contentModal()}
              </Modal>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

export default TestePerfil;