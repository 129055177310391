import React, { useEffect, useState } from 'react';

import './../../styles/scss/Text.scss';
import './CardBalance.scss'

const CardBalance = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let newValue = props.value;
    if (props.value !== undefined) {
      setValue(newValue);
    }
  }, [props.value]);

  return (
    <div className={`card card-balance ${props.bgColor ? props.bgColor : ''}`}>
      <div>
        <label className="lead card-label">{props.label}</label>
        {value ?
          <div className="card-main-value">
            <h3 id={props.idValue} className="text-primary" style={{ fontSize: '1.45rem' }}>{value}</h3>
          </div> : ''}
      </div>
      {props.children && (
        <div className="card-buttons flex align-items-end justify-content-start" style={{ width: '100%' }}>
          {props.children}
        </div>
      )}
      <div className="dark-overlay"></div>
    </div>
  )
}

export default CardBalance;