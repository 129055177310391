import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import XLSX from 'xlsx';

import MiniModal from './../../../components/MiniModal';
import Select from './../../../components/Select';

import './../../../styles/scss/Forms.scss';

import { BO_SaquesService } from './services/MainService';
import { parseValue } from './../../../common/format-number';

const Saques = () => {

  const [tableData, setTableData] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [mdlSaque, setMdlSaque] = useState({ openState: false, data: {} });
  const [snack, setSnack] = useState({ openState: false, severity: '', message: '' });

  const [btnSalvar, setBtnSalvar] = useState({ disabled: false, loading: false });

  const dt = useRef(null);

  const statusOptions = [
    { value: '1', title: 'Pendente' },
    { value: '3', title: 'Realizado' },
    { value: '4', title: 'Confirmado' },
    { value: '2', title: 'Cancelado' },
    { value: '5', title: 'Recebendo' },
    { value: '6', title: 'Quitado' }
  ]
  const pageService = new BO_SaquesService();

  useEffect(() => {
    pageService.getAllSaques().then(data => setTableData(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
        <div>
          <Button label="Exportar" style={{ fontSize: '1rem' }} icon="pi pi-file-excel" onClick={() => exportTable()} />
        </div>
      </div>
    );
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button type="button" label="Ver detalhes" className="p-button-primary" onClick={() => { goToDetail(rowData.idTransacao, rowData.idTrabalhador) }}></Button>
      </React.Fragment>
    );
  }


  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.data).format('L')}
      </React.Fragment>
    );
  }

  const idTransacaoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Transação</span>
        {rowData.idTransacao}
      </React.Fragment>
    );
  }

  const idTrabalhadorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Trabalhador</span>
        {rowData.idTrabalhador}
      </React.Fragment>
    );
  }

  const tipoUsuarioBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Tipo de Usuário</span>
        {rowData.tipoUsuario}
      </React.Fragment>
    );
  }

  const trabalhadorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Trabalhador</span>
        {rowData.nome}
      </React.Fragment>
    );
  }

  const etapaBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={'status-badge status-' + rowData.statusNum}>{rowData.status}</span>
      </React.Fragment>
    );
  }

  const valorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor</span>
        {parseValue(rowData.valor)}
      </React.Fragment>
    );
  }

  function goToDetail(id, idUser) {
    pageService.getSaqueByID(id, idUser).then(data => {
      setMdlSaque({ ...mdlSaque, openState: true, data: data });
    })
  }

  function closeModal() {
    setMdlSaque({ ...mdlSaque, openState: false })
  }

  function closeSnacks() {
    setSnack({ ...snack, openState: false });
  }

  function updateSelect(value, formItem) {
    mdlSaque.data.status = value;
    setMdlSaque({ ...mdlSaque })
  }

  function salvarMudancas(userData, statusNow) {
    console.log(userData);
    setBtnSalvar(prev => ({
      ...prev,
      loading: true
    }));
    pageService.updateSaqueByID(userData, statusNow)
      .then(res => {
        res.data.code ?
          setSnack({
            ...snack,
            openState: true,
            severity: 'error',
            message: 'Houve um problema com a requisição, tente novamente.'
          })
          : setSnack({
            ...snack,
            openState: true,
            severity: 'success',
            message: 'Tarefa realizada com sucesso.'
          })
      })
      .then(() => {
        closeModal();
        pageService.getAllSaques().then(data => setTableData(data));
      })
      .finally(() => {
        setBtnSalvar(prev => ({
          ...prev,
          loading: false
        }));
      })
  }

  function exportTable() {
    let data = []
    let columns = Object.keys(tableData[0])
    data.push(columns)
    tableData.forEach((value) => {
      var data_value = []
      columns.forEach((i) => data_value.push(value[i]))
      data.push(data_value)
    })
    data[0] = [
      "Data",
      "ID Transação",
      "ID Trabalhador",
      "Tipo de Usuário",
      "Nome",
      "ID Status",
      "Status",
      "Valor",
    ]
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    XLSX.writeFile(new_workbook, "saques.xlsx");
  }

  const header = renderHeader();

  const contentModal = () => {
    return (
      <Grid className="investment-card" container spacing={2}>
        <Grid item xs={12} sm={3}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">id Trabalhador</label>
            <h6 className="text-primary">{mdlSaque.data.idTrabalhador}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Trabalhador</label>
            <h6 className="text-primary">{mdlSaque.data.nome}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">CPF/CNPJ</label>
            <h6 className="text-primary">{mdlSaque.data.user_cpf}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Banco</label>
            <h6 className="text-primary">{mdlSaque.data.user_bank_name}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Agência c/ dígito</label>
            <h6 className="text-primary">{mdlSaque.data.user_bank_agency}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Conta c/ dígito</label>
            <h6 className="text-primary">{mdlSaque.data.user_bank_account}</h6>
          </div>
        </Grid>
        <Grid item xs={6} sm={5}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">id Transação</label>
            <h6 className="text-primary">{mdlSaque.data.idTransacao}</h6>
          </div>
        </Grid>
        <Grid item xs={6} sm={7}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Data</label>
            <h6 className="text-primary">{mdlSaque.data.data}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className="flex-0 mb-0">
            <label className="lead card-label">Status</label>
            <div style={{ marginTop: '.4em' }}>
              <Select
                options={statusOptions}
                onKeyDown={(e) => { if (e.keyCode === 9 || e.which === 9) e.preventDefault() }}
                value={mdlSaque.data.status}
                sideFunc={e => { updateSelect(e, 'status') }}
              ></Select>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            label="Salvar Mudanças"
            level="primary block"
            size="md"
            disabled={btnSalvar.loading}
            onClick={() => { salvarMudancas(mdlSaque.data, mdlSaque.data.status) }} />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Snackbar open={snack.openState} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => { closeSnacks() }}>
        <Alert onClose={() => { closeSnacks() }} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
      <div className="mb-4">
        <h4 className="title">Saques</h4>
      </div>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="data" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="id" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="data" header="Data" body={dateBodyTemplate} sortable />
              <Column field="idTransacao" header="Id Transação" body={idTransacaoBodyTemplate} sortable />
              <Column field="idTrabalhador" header="Id Trabalhador" body={idTrabalhadorBodyTemplate} sortable />
              <Column field="nome" header="Trabalhador" body={trabalhadorBodyTemplate} sortable />
              <Column field="tipoUsuario" header="Tipo de Usuário" body={tipoUsuarioBodyTemplate} sortable />
              <Column field="status" header="Status" body={etapaBodyTemplate} sortable />
              <Column field="valor" header="Valor" body={valorBodyTemplate} sortable />
              <Column body={actionBodyTemplate} headerStyle={{ width: '10em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
            </DataTable>
            {mdlSaque.openState === true && (
              <MiniModal id="transacao-modal-detail" title="Informações do Saque" openState={mdlSaque.openState} actionClose={() => { closeModal() }}>
                {contentModal()}
              </MiniModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Saques;