import React, { Component } from "react";
import { Link } from "react-router-dom";
import Inputmask from "inputmask";
import Chart from "react-apexcharts";

import CardBalance from "../CardBalance";
import Button from "../Button";
import Modal from "../Modal";
import Icon from "../Icon";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ModalWithdraw from "./ModalWithdraw";

// import addMoneyMdlImg from '../../assets/img/add-modal.png';
import addMoneyMdlImg from "../../assets/img/qrcode_juscredit.jpeg";
import { parseValue } from "../../common/format-number";

import "../../styles/scss/App.scss";
import "./MainHeading.scss";
import API from "../../api";

const axios = require("axios");

class MainHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileAccount: "",
      modalAddState: false,
      modalWithdrawState: false,
      totalInvestValue: 0,
      cardsValue: {
        saldo_disponivel: 0,
        valor_investido: 0,
        total_na_juscredit: 0,
      },
      // Fake modal itens
      modalItens: [],
      investmentSnack: false,
      investmentSnackSeverity: "success",
      investmentSnackMsg: "",
      userId: 0,
      formValues: {
        retirada: "",
        senha: "",
      },
      hashPass: "",

      // Options para os gráficos normais
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#53B67F"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            gradientToColors: ["#086C7B"],
          },
        },
        xaxis: {
          categories: ["Mar", "Abr", "Mai", "Jun"],
        },
        yaxis: {
          tickAmount: 2,
          forceNiceScale: true,
        },
        responsive: [
          {
            breakpoint: 728,
            options: {
              chart: {
                height: 160,
              },
              yaxis: {
                show: false,
              },
            },
          },
        ],
      },

      // Series 1
      series: [
        {
          name: "Series 1",
          data: [38551, 45654.2, 19512, 23556.66],
        },
      ],

      // Options para os gráficos radiais
      optionsRadial: {
        chart: {
          id: "radial-bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {},
            dataLabels: {
              name: {
                offsetY: -10,
                fontSize: "0.85em",
              },
              value: {
                offsetY: -5,
                fontSize: "1.25em",
                fontWeight: 500,
                show: true,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        colors: ["#53B67F"],
        labels: ["R$"],
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            gradientToColors: ["#086C7B"],
            stops: [0, 100],
          },
        },
      },

      // Series 2
      seriesRadial: [0],
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeSnacks = this.closeSnacks.bind(this);
    this.addMoneyMdl = this.addMoneyMdl.bind(this);
    this.getUserMoney = this.getUserMoney.bind(this);
    this.maskValueEl = this.maskValueEl.bind(this);
    this.atualizaFormValues = this.atualizaFormValues.bind(this);
  }

  componentDidMount() {
    this.startLoading();
  }

  startLoading() {
    var self = this;
    setInterval(() => {
      if (localStorage.getItem("refresh")) {
        axios
          .get(`${API}/investment/${this.state.userId}/total_value`)
          .then(function (response) {
            let dataAPI = response.data[0];
            self.setState({ totalInvestValue: dataAPI.Saldo }, () => {
              self.getUserMoney();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
        localStorage.removeItem("refresh");
      }
    }, 2000);
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        let user_id = response.data[0].user_id;
        let account_type = response.data[0].user_accounttype;

        self.setState({
          hashPass: response.data[0].user_hashpassword,
        });
        if (response.data[0].user_accounttype === "c") {
          axios
            .get(`${API}/user/${user_id}/percproccess`)
            .then(function (response) {
              let dataAPI = response.data[0];
              console.log(dataAPI);
              self.setState(
                { seriesRadial: [dataAPI.perc !== null ? dataAPI.perc : 0] },
                () => {}
              );
              axios
                .get(`${API}/proccess/${localStorage.getItem("hashcode")}`)
                .then(function (proccess) {
                  axios
                    .get(
                      `${API}/transaction/${proccess.data[0].proccess_id}/future`
                    )
                    .then(function (response) {
                      self.setState(
                        {
                          totalInvestValue:
                            response.data[0].total !== null
                              ? Number(response.data[0].total)
                              : 0,
                        },
                        () => {
                          self.getUserMoney();
                        }
                      );
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        self.setState(
          {
            profileAccount:
              response.data[0].user_accounttype === "c" ? "cliente" : "i",
            userId: response.data[0].user_id,
          },
          () => {
            if (account_type === "i") {
              axios
                .get(`${API}/investment/${user_id}/total_value`)
                .then(function (response) {
                  let dataAPI = response.data[0];
                  self.setState({ totalInvestValue: dataAPI.Saldo }, () => {
                    self.getUserMoney();
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  closeModal() {
    this.setState({
      modalAddState: false,
      modalWithdrawState: false,
    });
  }

  closeSnacks() {
    this.setState({
      investmentSnack: false,
    });
  }

  addMoneyMdl(e) {
    this.setState({ modalAddState: true });
  }

  withdrawMoneyMdl(e) {
    this.setState({ modalWithdrawState: true });
  }

  getUserMoney() {
    var self = this;
    axios
      .get(`${API}/transaction/${this.state.userId}/balance`)
      .then(function (response) {
        const dataAPI = response.data[0];
        self.setState({
          cardsValue: {
            saldo_disponivel: dataAPI.total_balance,
            valor_investido:
              self.state.totalInvestValue !== null
                ? self.state.totalInvestValue
                : 0,
            total_na_juscredit:
              dataAPI.total_balance + self.state.totalInvestValue,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  maskValueEl(elID, rowMask) {
    const el = document.querySelector(elID);
    if (el) Inputmask(rowMask).mask(el);
  }

  atualizaFormValues = (stateForm, event, formItem) => {
    if (formItem === "retirada") {
      let the_VALUE = event;
      Inputmask.unmask(the_VALUE, {
        alias: "numeric",
        radixPoint: ",",
        groupSeparator: ".",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        rightAlign: false,
      });
      the_VALUE = the_VALUE
        .replace(".", "")
        .replace(",", ".")
        .replace("R$ ", "");
      this.setState({
        [stateForm]: Object.assign(this.state.formValues, {
          [formItem]: +the_VALUE,
        }),
      });
    } else {
      this.setState({
        [stateForm]: Object.assign(this.state.formValues, {
          [formItem]: event,
        }),
      });
    }
  };

  render() {
    const {
      cardsValue,
      modalAddState,
      modalWithdrawState,
      investmentSnack,
      investmentSnackMsg,
      investmentSnackSeverity,
    } = this.state;

    const mdl_AddContent = () => {
      return (
        <div className="modal-content add">
          <p className="lead text-center">
            Atendendo as normas do Banco Central informamos que a transferência
            deve ser realizada através de uma conta de sua titularidade via Pix
            e TED *.
          </p>
          <div className="content-body">
            <div>
              <img
                className="img-full mb-3"
                style={{ float: "none" }}
                src={addMoneyMdlImg}
                alt="addMoneyMdl imagem modal"
              ></img>
            </div>
            <div>
              <div
                className="content-item pb-2"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <label className="lead">Pix</label>
                <h6 className="text-primary">35.604.390/0001-93</h6>
              </div>
              <div className="content-item">
                <label className="lead">Banco</label>
                <h6 className="text-primary">77 - Banco Inter</h6>
              </div>
              <div className="content-item">
                <label className="lead">Agência</label>
                <h6 className="text-primary">0001</h6>
              </div>
              <div className="content-item">
                <label className="lead">C/C</label>
                <h6 className="text-primary">4874204-0</h6>
              </div>
              <div className="content-item">
                <label className="lead">Titular</label>
                <h6 className="text-primary">
                  JUSCREDIT TECNOLOGIA E SERVIÇOS LTDA
                </h6>
              </div>
              <div className="content-item">
                <label className="lead">CNPJ/CPF</label>
                <h6 className="text-primary">35.604.390/0001-93</h6>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <p className="lead">
              * O processo de identificação da transação com o Banco pode levar
              até 1 dia útil.
            </p>
          </div>
          <Button
            label="Entendi, vou transferir"
            level="primary block"
            size="lg"
            onClick={() => {
              this.closeModal();
            }}
          ></Button>
        </div>
      );
    };

    return (
      <div>
        <Snackbar
          open={investmentSnack}
          autoHideDuration={3000}
          onClose={() => {
            this.closeSnacks();
          }}
        >
          <Alert
            onClose={() => {
              this.closeSnacks();
            }}
            severity={investmentSnackSeverity}
          >
            {investmentSnackMsg}
          </Alert>
        </Snackbar>
        <div>
          {this.state.profileAccount === "i" && (
            <>
              <div className="card-wrapper">
                <CardBalance
                  bgColor="primary"
                  label="Saldo Disponível"
                  idValue="saldoDisponivelCard"
                  value={parseValue(cardsValue.saldo_disponivel)}
                >
                  <Button
                    style={{ flex: "1 1 !important" }}
                    label="Adicionar"
                    level="balance"
                    size="md"
                    onClick={() => {
                      this.addMoneyMdl();
                    }}
                  >
                    <Icon name="currency" size={18}></Icon>
                  </Button>
                  <Button
                    style={{ flex: "1 1 !important" }}
                    label="Retirar"
                    level="balance"
                    size="md"
                    onClick={() => {
                      this.withdrawMoneyMdl();
                    }}
                  >
                    <Icon name="withdraw" size={18}></Icon>
                  </Button>
                </CardBalance>
                <CardBalance
                  label="valor investido"
                  idValue="valorInvestidoCard"
                  value={parseValue(cardsValue.valor_investido)}
                >
                  <Link
                    to="/investimentos-realizados"
                    title="Investimentos Realizados"
                    className="button primary sm"
                  >
                    <Icon name="chart-line" size={18}></Icon>
                    <span className="ml-1">Ver Investimentos</span>
                  </Link>
                </CardBalance>
                <CardBalance
                  label="Total na juscredit"
                  idValue="valorTotalCard"
                  value={parseValue(cardsValue.total_na_juscredit)}
                ></CardBalance>
              </div>
              <Modal
                openState={modalAddState}
                modalTitle="Adicionar dinheiro"
                actionClose={() => {
                  this.closeModal();
                }}
              >
                {mdl_AddContent()}
              </Modal>
              {modalWithdrawState && (
                <ModalWithdraw
                  openState={modalWithdrawState}
                  userValues={cardsValue}
                  onHide={() => {
                    this.closeModal();
                    setTimeout(() => {
                      this.getUserMoney();
                    }, 1000);
                  }}
                />
              )}
            </>
          )}
          {this.state.profileAccount === "cliente" && (
            <>
              <div className="card-wrapper">
                <CardBalance
                  bgColor="primary"
                  label="SALDO DISPONÍVEL"
                  idValue="saldoDisponivelCard"
                  value={parseValue(cardsValue.saldo_disponivel)}
                >
                  <Button
                    label="Sacar antecipação"
                    level="balance"
                    size="md"
                    onClick={() => {
                      this.withdrawMoneyMdl();
                    }}
                  >
                    <Icon name="withdraw" size={18}></Icon>
                  </Button>
                </CardBalance>
                <CardBalance
                  label="TOTAL A RECEBER"
                  idValue="valorInvestidoCard"
                  value={parseValue(cardsValue.valor_investido)}
                ></CardBalance>
                <CardBalance label="PROGRESSO DA CAPTAÇÃO">
                  <div className="chart-wrapper round-graphic">
                    <div className="chart-el">
                      <Chart
                        options={this.state.optionsRadial}
                        series={this.state.seriesRadial}
                        type="radialBar"
                      />
                    </div>
                    <div className="chart-labels">
                      <label className="label-previsto">Total previsto</label>
                      <label className="label-arrecadado">
                        Total arrecadado
                      </label>
                    </div>
                  </div>
                </CardBalance>
              </div>
              {modalWithdrawState && (
                <ModalWithdraw
                  openState={modalWithdrawState}
                  userValues={cardsValue}
                  onHide={() => {
                    this.closeModal();
                    setTimeout(() => {
                      this.getUserMoney();
                    }, 1000);
                  }}
                />
              )}
            </>
          )}
          {this.state.profileAccount === "" && (
            <div>Carregando informações...</div>
          )}
        </div>
      </div>
    );
  }
}

export default MainHeading;
