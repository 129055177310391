import API from './api';
import firebase from './firebase/index';
const axios = require('axios');

export function authenticateWithCustomToken(customToken) {
  firebase.auth().signInWithCustomToken(customToken)
    .then(() => {
      console.log("Usuário autenticado no Firebase com sucesso!");
    })
    .catch((error) => {
      console.error("Erro ao autenticar com Firebase:", error);
    });
}

export const isAuthenticated = () => {
  var hash = localStorage.getItem('hashcode');
  if (hash) {
    return true
  } else {
    return false
  }
};

export const isInvestor = () => {
  return true;
};

export const isAdmin = () => {
  return true
};

function getTypeUser(hash) {
  return axios.get(`${API}/user/${hash}`)
    .then(res => {
      return res.data[0].user_accounttype
    })
}