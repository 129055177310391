import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Panel from "../../components/Panel";
import Button from "../../components/Button";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import API from "../../api";
import { authService } from "../../services/authService";

import "./../../styles/scss/Grid.scss";
import "./ConfiguracoesConta.scss";

const ConfiguracoesConta = () => {
  const auth = new authService();
  const bankOptions = [
    { value: "", title: "Selecione seu banco" },
    { value: "1", title: "1 - Banco do Brasil" },
    { value: "33", title: "33 - Santander" },
    { value: "36", title: "36 - Banco Bradesco BBI S.A." },
    { value: "77", title: "77 - Banco Inter S.A." },
    { value: "104", title: "104 - Caixa Econômica Federal" },
    { value: "121", title: "121 - Banco Agibank S.A." },
    { value: "208", title: "208 - Banco BTG Pactual S.A." },
    { value: "212", title: "212 - Banco Original S.A." },
    { value: "237", title: "237 - Banco Bradesco S.A." },
    { value: "246", title: "246 - Banco ABC Brasil S.A." },
    { value: "260", title: "260 - NuBank" },
    { value: "318", title: "318 - Banco BMG S.A." },
    { value: "341", title: "341 - Itaú Unibanco S.A." },
    { value: "422", title: "422 - Banco Safra S.A" },
    { value: "748", title: "748 - Banco Cooperativo Sicredi S.A." },
    { value: "756", title: "756 - Banco Cooperativo do Brasil S.A. - BANCOOB" },
  ];

  const [investorType, setInvestorType] = useState("");
  const [accType, setAccType] = useState("");
  const [user, setUser] = useState({ name: "", email: "" });

  // Form Items
  const [currentPassword, setCurrentPassword] = useState("");
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
    bank: "",
    agency: "",
    account: "",
  });

  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });
  const [formLock, setFormLock] = useState({
    name: "",
    email: "",
    cpf: "",
    phone: "",
  });
  const history = useHistory();

  useEffect(() => {
    getPerfil();
    setInvestorType("Teste não realizado");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getPerfil() {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then((response) => {
        let data = response.data[0];
        console.log(data);
        setAccType(data.user_accounttype);
        setUser({
          name: data.user_name,
          email: data.user_email,
          userHash: data.user_hashcode,
        });
        setFormLock({
          name: data.user_name,
          email: data.user_email,
          cpf: data.user_cpf,
          phone: data.user_phone,
        });
        setFormValues((prev) => ({
          ...prev,
          bank: data.user_bank_name,
          agency: data.user_bank_agency,
          account: data.user_bank_account,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function goTestePerfil() {
    history.push("/teste-perfil");
  }

  function saveOptions() {
    if (
      currentPassword === null ||
      currentPassword === "" ||
      currentPassword === undefined
    ) {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>Senha atual está vazia</p>",
      });
      return false;
    }

    let userData = { email: user.email, password: currentPassword };
    auth.postLogin(userData).then((res) => {
      if (res === undefined || res === null || res === "") {
        setSnack({
          openState: true,
          severity: "error",
          message:
            "<p>Autenticação falhou, verifique sua senha e tente novamente</p>",
        });
      } else {
        updateUserData();
      }
    });
  }

  function updateUserData() {
    if (formValues.password !== formValues.confirmPassword) {
      setSnack({
        openState: true,
        severity: "error",
        message: "<p>Senha e Confirmar Senha devem ser iguais</p>",
      });
    } else {
      // clone main form
      let newUserData = {
        ...formValues,
        hashcode: user.userHash,
      };

      // Delet empty values
      for (var key in newUserData) {
        if (
          newUserData[key] === null ||
          newUserData[key] === undefined ||
          newUserData[key] === ""
        ) {
          delete newUserData[key];
        }
      }

      // Delet other values --forced
      delete newUserData.confirmPassword;

      // REQ
      axios
        .patch(`${API}/user`, newUserData)
        .then(() => {
          setSnack({
            openState: true,
            severity: "success",
            message: "<p>Dados alterados com sucesso</p>",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const updateForm = (ev) => {
    const { name, type, value, checked } = ev.target;
    const blockedList = [];

    // eslint-disable-next-line array-callback-return
    if (!blockedList.includes(name)) {
      if (type === "checkbox") {
        setFormValues((prev) => ({
          ...prev,
          [name]: checked,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  function closeSnacks() {
    setSnack((prevState) => ({
      ...prevState,
      openState: false,
    }));
  }

  return (
    <>
      <Snackbar
        open={snack.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          <div dangerouslySetInnerHTML={{ __html: snack.message }}></div>
        </Alert>
      </Snackbar>
      <div className="pb-5">
        <h4 className="title">Configurações da conta</h4>
        <div className="mt-3">
          {accType === "i" && (
            <div className="mb-3">
              <Panel isPositive>
                <h5 className="title mb-2">Perfil do investidor</h5>
                <div className="mb-3">
                  <p className="title mb-1">
                    Seu perfil é:{" "}
                    <b className="text-primary light">{investorType}</b>
                  </p>
                </div>
                <div>
                  <Button
                    level="primary"
                    size="md"
                    label="Realizar Teste"
                    onClick={() => goTestePerfil()}
                  ></Button>
                </div>
              </Panel>
            </div>
          )}
          <div className="mb-3">
            <Panel isPositive>
              <h5 className="title mb-2">Informações pessoais</h5>
              <p className="lead">
                Para alteração das informações pessoais, entre em contato pelo{" "}
                <span className="text-primary light">oi@juscredit.com.br</span>
              </p>
              <div className="my-3">
                <Grid className="pt-2" container spacing={2}>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText type="text" disabled value={formLock.name} />
                      <label>Nome Completo</label>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText type="mail" disabled value={formLock.email} />
                      <label>E-mail</label>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText type="text" disabled value={formLock.cpf} />
                      <label>CPF</label>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText type="phone" disabled value={formLock.phone} />
                      <label>Telefone</label>
                    </span>
                  </Grid>
                </Grid>
              </div>
            </Panel>
          </div>
          <div className="mb-4">
            <Panel isPositive>
              <h5 className="title mb-2">Modificar Dados</h5>
              <p className="lead">
                Você pode alterar sua senha JusCredit e também modificar sua
                conta de saque
              </p>
              <div className="my-3">
                <Grid className="pt-2" container spacing={2}>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText
                        type="password"
                        name="password"
                        value={formValues.password}
                        onChange={(e) => {
                          updateForm(e);
                        }}
                      />
                      <label>Nova Senha</label>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText
                        type="password"
                        name="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={(e) => {
                          updateForm(e);
                        }}
                      />
                      <label>Confirmar Nova Senha</label>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="">
                      <Dropdown
                        value={formValues.bank}
                        name="bank"
                        options={bankOptions}
                        onChange={(e) => {
                          updateForm(e);
                        }}
                        optionLabel="title"
                        placeholder="Banco"
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        name="agency"
                        value={formValues.agency}
                        onChange={(e) => {
                          updateForm(e);
                        }}
                      />
                      <label>Agência</label>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        name="account"
                        value={formValues.account}
                        onChange={(e) => {
                          updateForm(e);
                        }}
                      />
                      <label>Conta</label>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 className="lead mt-3">
                      Coloque sua senha atual para salvar as informações
                    </h6>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <span className="p-float-label">
                      <InputText
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <label>Senha atual</label>
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      level="primary block"
                      size="md"
                      label="Salvar alterações"
                      onClick={() => {
                        saveOptions();
                      }}
                    ></Button>
                  </Grid>
                </Grid>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfiguracoesConta;
