import React, { useState, useEffect } from "react";

import CardBalance from "../../../../components/CardBalance";
import Grid from "@material-ui/core/Grid";

import { BO_FinanceiroService } from "./../services/MainService";

const ProcessosTab = () => {
  const pageService = new BO_FinanceiroService();

  const [data, setData] = useState({ withdraw: {} });

  useEffect(() => {
    pageService.getFinanceiroByProccess().then((res) => setData(res));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <CardBalance
            label="Número de Processos (Antecipação Finalizada)"
            bgColor="accordion-card"
            value={data.antecipacao_finalizada - data.investidores_pagos || "0"}
          ></CardBalance>
        </Grid>
        <Grid item xs={6} md={4}>
          <CardBalance
            label="Número de Processos (Em Captação)"
            bgColor="accordion-card"
            value={data.em_captacao || "0"}
          ></CardBalance>
        </Grid>
        <Grid item xs={6} md={4}>
          <CardBalance
            label="Número de Processos (Investidores Pagos)"
            bgColor="accordion-card"
            value={data.investidores_pagos || "0"}
          ></CardBalance>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProcessosTab;
