import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/Input";
import Divider from "../../components/Divider";
import Button from "../../components/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { authService } from "../../services/authService";

import LoginImg from "../../assets/img/media1.png";
import LoginImgMobile from "../../assets/img/media1_mob.png";
import "./Login.scss";

const Login = () => {
  const [formValues, setFormValues] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [snack, setSnack] = useState({ openState: false, severity: "success" });
  const mobile = window.innerWidth < 728 ? true : false;
  const history = useHistory();
  const authServ = new authService();

  useEffect(() => {
    getAutoLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAutoLogin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("autoLogin")) {
      const whoAuto = urlParams.get("autoLogin");

      authServ.postHash(whoAuto).then((accType) => {
        switch (accType) {
          case "b":
            history.push("/backoffice/dashboard");
            break;

          case "i":
          case "c":
            history.push("/dashboard");
            break;

          default:
            setSnack({ openState: true, severity: "error" });
            break;
        }
      });

      // if (auto) {
      //   let user = urlParams.get('user');
      //   let pass = urlParams.get('pass');

      //   authServ.postLogin({ email: user, password: pass }).then(accType => {
      //     setLoadingBtn(false);
      //     switch (accType) {
      //       case 'b':
      //         history.push("/backoffice/dashboard");
      //         break;

      //       case 'i':
      //       case 'c':
      //         history.push("/dashboard");
      //         break;

      //       default:
      //         setSnack({ openState: true, severity: 'error' });
      //         break;
      //     }
      //   })
      // }
    }
  };

  function goBackToSite() {
    window.location.href = "https://www.juscredit.com.br/";
  }

  function redirectCreateAcc(role) {
    var baseURL = "https://www.juscredit.com.br?";
    var params = new URLSearchParams(baseURL.search);
    params.set(role, "true");

    // nova-conta
    var newURL = baseURL + params;

    window.location.href = newURL;
  }

  function loginToDash() {
    setLoadingBtn(true);
    authServ.postLogin(formValues).then((accType) => {
      setLoadingBtn(false);
      switch (accType) {
        case "b":
          history.push("/backoffice/dashboard");
          break;

        case "i":
        case "c":
          history.push("/dashboard");
          break;

        default:
          setSnack({ openState: true, severity: "error" });
          break;
      }
    });
  }

  function closeSnacks() {
    setSnack((prevState) => ({ ...prevState, openState: false }));
  }

  function atualizaFormValues(value, formItem) {
    setFormValues((prevState) => ({ ...prevState, [formItem]: value }));
  }

  return (
    <div className="flex wrapper-page inverse-mobile">
      <Snackbar
        open={snack.openState}
        autoHideDuration={10000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snack.severity}
        >
          <p className="m-0">
            Usuário e/ou senha <strong>incorretos!</strong>
          </p>
        </Alert>
      </Snackbar>
      <div className="col-1">
        <img
          src={mobile ? LoginImgMobile : LoginImg}
          className="side-img"
          alt="Imagem"
        />
        <Button
          label="Voltar ao Site"
          level="primary float-btn-login"
          size="sm"
          onClick={goBackToSite}
        ></Button>
      </div>
      <div className="px-2 pl-sm-4 pr-sm-5 mobile-text-form d-block d-sm-none">
        <div className="mb-4">
          <h1 className="title text-white">Olá, seja bem vindo!</h1>
          <p className="lead text-white mb-2">
            Digite seu e-mail e senha para realizar o acesso
          </p>
          <Divider height="1"></Divider>
        </div>
      </div>
      <div className="col-2">
        <div className="flex justify-content-start">
          <div
            className="px-2 py-3 py-sm-0 pl-sm-4 pr-sm-5"
            style={{ maxWidth: "660px" }}
          >
            <div className="mb-4 d-none d-sm-block">
              <h1 className="title">Olá, seja bem vindo!</h1>
              <p className="lead mb-2">
                Digite seu e-mail e senha para realizar o acesso
              </p>
              <Divider height="1"></Divider>
            </div>
            <form className="mb-3" autoComplete="true">
              <Input
                label="E-mail"
                type="email"
                sideFunc={(e) => {
                  atualizaFormValues(e, "email");
                }}
              ></Input>
              <Input
                label="Senha"
                type="password"
                sideFunc={(e) => {
                  atualizaFormValues(e, "password");
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.which === 13) loginToDash();
                }}
                helpText="Esqueceu sua senha?"
                helpLink="/recuperar-senha"
              ></Input>
            </form>
            <div className="mb-4">
              <Button
                loading={loadingBtn}
                label="Entrar"
                level="primary mr-2"
                onClick={() => loginToDash()}
              ></Button>
            </div>
            <div>
              <p className="lead mb-1">Ainda não tem conta? Crie uma agora</p>
              <div className="mb-2 flex justify-content-start">
                <Button
                  label="Nova Conta"
                  level="outline sm mr-2"
                  onClick={() => {
                    redirectCreateAcc("nova-conta");
                  }}
                ></Button>
                {/* <Button label="Quero Vender" level="outline sm mr-2" onClick={() => { redirectCreateAcc('quero-vender') }}></Button>
                <Button label="Quero Investir" level="outline sm" onClick={() => { redirectCreateAcc('quero-investir') }}></Button> */}
              </div>
            </div>
            <p className="lead opacity-1">
              <small>
                Ao clicar em "Entrar", concordo com os Termos de Uso do{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.juscredit.com.br/docs/JusCredit_Termo_Cedente_V5_14_02_22.pdf`}
                >
                  Vendedor
                </a>
                , do{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.juscredit.com.br/docs/JusCredit_Termo_Cessionario_V5_14_02_22.pdf`}
                >
                  Investidor
                </a>{" "}
                e a{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${window.location.origin}/docs/JusCredit_-_Politica_de_Privacidade.pdf`}
                >
                  Política de Privacidade
                </a>{" "}
                da JusCredit.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
