import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_RecebimentosService {
  constructor() {
    moment.locale("pt-br");
  }

  getAllRecebimentos() {
    var mainData = [];
    return axios
      .get(`${API}/receipt`)
      .then(function (response) {
        const dataAPI = response.data;

        dataAPI.map((item) => {
          mainData.push({
            idProcesso: item.proccess_id,
            idUsuario: item.user_id,
            nomeUsuario: item.user_name,
            dataInvestimento: moment(item.investment_date, "DD/MM/YYYY").format(
              "YYYY/MM/DD"
            ),
            dataRecebimento: moment(item.receipt_date, "DD/MM/YYYY").format(
              "YYYY/MM/DD"
            ),
            valorInvestido: item.investment || 0,
            valorRecebidoBruto: item.receiptdetail_brute_value || 0,
            valorRecebidoLiq: item.receiptdetail_liq_value || 0,
            taxaInvestidor: item.receiptdetail_user_fee_value || 0,
          });
        });

        return mainData;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
