import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import XLSX from 'xlsx';

import './../../../styles/scss/Forms.scss';

import { BO_DepositosService } from './services/MainService';
import { parseValue } from './../../../common/format-number';

const Depositos = () => {

  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);

  const pageService = new BO_DepositosService();

  useEffect(() => {
    pageService.getDepositos().then(data => setTableData(data))

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
        <div>
          {(tableData.length !== 0) && (
            <Button label="Exportar" style={{ fontSize: '1rem' }} icon="pi pi-file-excel" onClick={() => exportTable()} />
          )}
        </div>
      </div>
    );
  }

  function exportTable() {
    let data = []
    let columns = Object.keys(tableData[0])
    data.push(columns)
    tableData.forEach((value) => {
      var data_value = []
      columns.forEach((i) => data_value.push(value[i]))
      data.push(data_value)
    })
    data[0] = [
      "ID Depósito",
      "ID Trabalhador",
      "Nome do Trabalhador",
      "Data do Depósito",
      "Valor"
    ]
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    XLSX.writeFile(new_workbook, "depositos.xlsx");
  }

  const idDepositoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Depósito</span>
        {rowData.idDeposito}
      </React.Fragment>
    );
  }

  const idClienteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Trabalhador</span>
        {rowData.idCliente}
      </React.Fragment>
    );
  }

  const nomeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Trabalhador</span>
        {rowData.nomeCliente}
      </React.Fragment>
    );
  }

  const valorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor</span>
        {parseValue(rowData.valor)}
      </React.Fragment>
    );
  }

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.data).format('L')}
      </React.Fragment>
    );
  }

  const header = renderHeader();

  return (
    <div>
      <div className="mb-4">
        <h4 className="title">Depósitos</h4>
      </div>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="data" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="id" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="idDeposito" header="Id Depósito" body={idDepositoBodyTemplate} sortable />
              <Column field="idCliente" header="Id Trabalhador" body={idClienteBodyTemplate} sortable />
              <Column field="nomeCliente" header="Trabalhador" body={nomeBodyTemplate} sortable />
              <Column field="data" header="Data" body={dateBodyTemplate} sortable />
              <Column field="valor" header="Valor" body={valorBodyTemplate} sortable />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Depositos;