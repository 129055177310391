import React from 'react';
import { isAuthenticated, isAdmin, isInvestor } from './auth';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Login from './views/Login';
import RecuperarSenha from './views/RecuperarSenha';
import Cadastro from './views/Cadastro';
// import Dashboard from './views/Dashboard';
import Dashboard from './views/Dashboard';
import InvestimentosRealizados from './views/InvestimentosRealizados';
import HistoricoMovimentacoes from './views/HistoricoMovimentacoes';
import ConfiguracoesConta from './views/ConfiguracoesConta'
import Faq from './views/FAQ';
import ListaProcessos from './views/Backoffice/ListaProcessos';
import ListaUsuarios from './views/Backoffice/ListaUsuarios';
import ListaUsuariosDetalhe from './views/Backoffice/ListaUsuarios/ListaUsuariosDetalhe';
// import ListaUsuariosDetalheV2 from './views/Backoffice/ListaUsuarios/ListaUsuariosDetalhe/detail';
import ListaProcessosDetalhe from './views/Backoffice/ListaProcessos/ListaProcessosDetalhe';
import BO_Dashboard from './views/Backoffice/Dashboard';
import Saques from './views/Backoffice/Saques';
import Depositos from './views/Backoffice/Depositos';
import ConfiguracoesBackoffice from './views/Backoffice/Configuracoes';
import TestePerfil from './views/TestePerfil';
import Recebimentos from './views/Backoffice/Recebimentos';
import Investimentos from './views/Backoffice/Investimentos';
import Sidebar from './components/Sidebar';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    )}
  />
)

const InvestorRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated() && isInvestor() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    )}
  />
)

const BackofficeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated() && isAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    )}
  />
)

const Routes = () => (
  <Router>
    <Sidebar>
      <div>
        <Switch>
          <Route path="/recuperar-senha" component={RecuperarSenha} />
          <Route path="/recuperar-senha/:id" component={RecuperarSenha} />
          <Route path="/cadastro" component={Cadastro} />

          {/* INTERNAS */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <InvestorRoute path="/investimentos-realizados" component={InvestimentosRealizados} />
          <PrivateRoute path="/transacoes" component={HistoricoMovimentacoes} />
          <PrivateRoute path="/faq" component={Faq} />
          <PrivateRoute path="/minha-conta" component={ConfiguracoesConta} />
          <PrivateRoute path="/teste-perfil" component={TestePerfil} />

          {/* BACKOFFICE */}
          <BackofficeRoute path="/backoffice/dashboard" component={BO_Dashboard} />
          <BackofficeRoute path="/backoffice/saques" component={Saques} />
          <BackofficeRoute path="/backoffice/depositos" component={Depositos} />
          <BackofficeRoute path="/backoffice/recebimentos" component={Recebimentos} />
          <BackofficeRoute path="/backoffice/investimentos" component={Investimentos} />
          <BackofficeRoute path="/backoffice/configuracoes" component={ConfiguracoesBackoffice} />
          <BackofficeRoute path="/backoffice/lista-processos/processo/:id" component={ListaProcessosDetalhe} />
          <BackofficeRoute path="/backoffice/lista-processos" component={ListaProcessos} />
          <BackofficeRoute path="/backoffice/lista-usuarios/usuario/:id" component={ListaUsuariosDetalhe} />
          <BackofficeRoute path="/backoffice/lista-usuarios" component={ListaUsuarios} />

          <Route exact path="/" component={Login} />

        </Switch>
      </div>
    </Sidebar>
  </Router>
);

export default Routes;