import React, { Component } from 'react';
import Button from '../Button'
import Icon from '../Icon';

class MiniModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openState: props.openState,
      actionClose: props.actionClose
    }
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(e) {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      this.state.actionClose();
    }
  }

  render() {
    if (this.state.openState) {
      return (
        <div id={this.props.id} className="custom-modal-backdrop" onClick={e => { this.closeModal(e) }}>
          <div className="custom-modal">
            {this.props.title && (
              <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '-10px' }}>
                <h5 className="text-primary title">{this.props.title}</h5>
                <Button level="transparent" size="only-icon" onClick={e => { this.closeModal(e) }}>
                  <Icon name="close" bgColor="text-primary" size={24}></Icon>
                </Button>
              </div>
            )}
            <div>
              {this.props.children}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        ''
      );
    }
  }
}

export default MiniModal;