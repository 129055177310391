import React, { useState, useEffect } from "react";

import CardBalance from "../../../../components/CardBalance";
import Grid from "@material-ui/core/Grid";
import { Chart } from "primereact/chart";

import { BO_FinanceiroService } from "./../services/MainService";
import { parseValue } from "../../../../common/format-number";

const VendedoresTab = () => {
  const pageService = new BO_FinanceiroService();

  const [data, setData] = useState({ withdraw: {} });
  const [chartValues, setChartValues] = useState([]);

  const chartData = {
    labels: ["Saldo Disponível", "Saque Pendente", "Saldo Futuro"],
    responsive: false,
    maintainAspectRatio: false,
    datasets: [
      {
        data: chartValues,
        backgroundColor: [
          "#1A535C",
          "#349090",
          "#4ECDC4",
          "#BEF6FF",
          "#FF6B6B",
          "#FFA96C",
          "#FFE66D",
        ],
      },
    ],
  };
  const lightOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ||
            0;
          var label = data.labels[tooltipItem.index] || "";

          if (label) {
            label += ": ";
          }
          label += parseValue(value);
          return label;
        },
      },
    },
    legend: {
      display: false,
      labels: {
        fontColor: "#495057",
      },
      position: "right",
    },
  };

  useEffect(() => {
    pageService.getFinanceiroByCustomer().then((res) => setData(res));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function getDataChart() {
    const obj = data.withdraw;
    let arr = [];
    for (var prop in obj) {
      arr.push(obj[prop]);
    }
    setChartValues(arr);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardBalance
            label="Taxa Total Recolhida"
            bgColor="accordion-card"
            value={parseValue(data.total_fee_collected || 0)}
          ></CardBalance>
        </Grid>
        <Grid item xs={12} md={4}>
          <CardBalance
            label="Quantidade"
            bgColor="accordion-card"
            value={data.quantity || "0"}
          ></CardBalance>
        </Grid>
        <Grid item xs={12} md={4}>
          <CardBalance
            label="Total Pago"
            bgColor="accordion-card"
            value={parseValue(
              data.total_gross_received - data.total_fee_collected || 0
            )}
          ></CardBalance>
        </Grid>
      </Grid>
      <div className="mt-2">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Chart type="doughnut" data={chartData} options={lightOptions} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {chartValues.map((res, index) => (
              <div key={"bo-chart-item-" + index} className="bo-list-values">
                <span
                  className="bo-list-square"
                  style={{
                    background: chartData.datasets[0].backgroundColor[index],
                  }}
                ></span>
                <span className="bo-list-value" style={{ color: "#1A535C" }}>
                  <label>{chartData.labels[index]}:</label>
                  <br /> {parseValue(res)}
                </span>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default VendedoresTab;
