import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// import { useHistory } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';

const ListaRecebimentosTable = (props) => {

  const [tableData, setTableData] = useState(props.dataSource);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    setTableData(props.dataSource)
  }, [props.dataSource]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
      </div>
    );
  }

  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" label="Ver detalhes" className="p-button-primary" onClick={() => { goToDetail(rowData.idProcesso) }}></Button>
  //     </React.Fragment>
  //   );
  // }

  const dataTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.data).format('L')}
      </React.Fragment>
    );
  }

  const numeroParcelaTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Número da Parcela</span>
        {rowData.parcNumber}
      </React.Fragment>
    );
  }

  const valorBrutoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Bruto</span>
        {rowData.bruteValue}
      </React.Fragment>
    );
  }

  const valorInvestidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido</span>
        {rowData.investValue}
      </React.Fragment>
    );
  }

  const lucroBrutoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Lucro Bruto Total</span>
        {rowData.bruteProfit}
      </React.Fragment>
    );
  }

  const taxaInvestidorTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Taxa do Investidor Total</span>
        {rowData.investFee}
      </React.Fragment>
    );
  }

  const lucroLiquidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Lucro Líquido Total</span>
        {rowData.liqProfit}
      </React.Fragment>
    );
  }

  const valorRecebidoTotalTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Recebido Total</span>
        {rowData.receivedValueTotal}
      </React.Fragment>
    );
  }

  const valorInvestidoTotal = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido Total</span>
        {rowData.investValueTotal}
      </React.Fragment>
    );
  }

  const valorLiquidoRecebidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Líquido Recebido Total</span>
        {rowData.liqValue}
      </React.Fragment>
    );
  }

  const descricaoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Descrição</span>
        {rowData.desc}
      </React.Fragment>
    );
  }

  const header = renderHeader();

  return (
    <>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="idProcesso" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="id" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="data" header="Data" body={dataTemplate} sortable />
              <Column field="parcNumber" header="Número da Parcela" body={numeroParcelaTemplate} sortable />
              <Column field="bruteValue" header="Valor Bruto" body={valorBrutoTemplate} sortable />
              <Column field="investValue" header="Valor Investido" body={valorInvestidoTemplate} sortable />
              <Column field="bruteProfit" header="Lucro Bruto Total" body={lucroBrutoTemplate} sortable />
              <Column field="investFee" header="Taxa do Investidor Total" body={taxaInvestidorTemplate} sortable />
              <Column field="receivedValueTotal" header="Valor Recebido Total" body={valorRecebidoTotalTemplate} sortable />
              <Column field="investValueTotal" header="Valor Investido Total" body={valorInvestidoTotal} sortable />
              <Column field="liqProfit" header="Lucro Líquido Total" body={lucroLiquidoTemplate} sortable />
              <Column field="liqValue" header="Valor Líquido Recebido Total" body={valorLiquidoRecebidoTemplate} sortable />
              <Column field="desc" header="Descrição" body={descricaoTemplate} sortable />
              {/* <Column body={actionBodyTemplate} headerStyle={{ width: '10em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListaRecebimentosTable;