import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../api";

export class InvestimentosRealizadosService {
  constructor() {
    moment.locale("pt-br");
  }

  getInvestimentoByID(id) {
    return axios
      .get(`${API}/proccess/status/2`)
      .then((res) => {
        const dataAPI = res.data;
        return dataAPI.filter((item) => item.proccess_id === id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getInvestimentosByUser(id) {
    var userData = [];
    return axios.get(`${API}/investment/${id}`).then((res) => {
      // Mapeia o dataAPI e adiciona os valores ao array userData
      const dataAPI = res.data;
      let arrStatus = [
        "Pedido em analise",
        "Proposta de antecipação",
        "Captação de investimentos",
        "Envio de documentação",
        "Anexando documento",
        "Antecipação finalizada",
        "Recusado",
        "Deletado",
        "Investidores Pagos", // 8
        "100% Investido", // 9
        "Recebimento Parcelado", // 10
      ];

      dataAPI.forEach(function (item) {
        userData.push({
          idProcesso: item.proccess_proccess_id,
          userName: item.user_name,
          dataProcesso: moment(item.investment_date, "DD-MM-YYYY").format(
            "YYYY/MM/DD"
          ),
          statusProcesso:
            +item.status === 8 || +item.status === 9
              ? item.status
              : item.proccess_status,
          status:
            +item.status === 8 || +item.status === 9
              ? arrStatus[item.status]
              : arrStatus[item.proccess_status],
          valorInvestido:
            item.investment_value !== null
              ? parseFloat(item.investment_value)
              : 0,
          valorRecebido:
            item.receiptdetail_liq_value == null
              ? 0
              : item.receiptdetail_liq_value,
          percPedido: Number(item.receiptdetail_perc) || 0,
        });
      });

      return userData;
    });
  }

  getChartInvestimentosByProcesso(id) {
    var userData = [];
    return axios.get(`${API}/investment/${id}/graphic_proccess`).then((res) => {
      // Mapeia o dataAPI e adiciona os valores ao array userData
      const dataAPI = res.data;

      let ivsID = [];
      let ivsSeller = [];
      let ivsValue = [];
      const baseColors = [
        "#1A535C",
        "#349090",
        "#4ECDC4",
        "#BEF6FF",
        "#FF6B6B",
        "#FFA96C",
        "#FFE66D",
      ];
      let colors = [];

      ivsID = dataAPI.investiment.map((a) => a.proccess_id);
      ivsSeller = dataAPI.investiment.map((a) => a.user_name);
      ivsValue = dataAPI.investiment.map((a) => a.investment_value);

      colors = repeatFor(baseColors, dataAPI.investiment.length);

      userData.push({
        seller: ivsSeller,
        averageFee: dataAPI.taxa_media !== null ? dataAPI.taxa_media : 0,
        onGoingInvestments:
          dataAPI.num_investiment !== null ? dataAPI.num_investiment : 0,
        totalLiqReceived:
          dataAPI.received_liq_total !== null ? dataAPI.received_liq_total : 0,
        labels: ivsID,
        datasets: [
          {
            data: ivsValue,
            backgroundColor: colors,
          },
        ],
      });

      return userData[0];
    });
  }

  getChartTotalJuscredit(id) {
    var userData = [];
    return axios
      .get(`${API}/investment/${id}/graphic_investment`)
      .then((res) => {
        // Mapeia o dataAPI e adiciona os valores ao array userData
        const dataAPI = res.data.reverse();

        console.log(dataAPI);
        let dataLabels = [];
        let dataValues = [];

        dataLabels = dataAPI.map((a) => a.mes);
        dataValues = dataAPI.map((a) => a.total);

        userData.push({
          labels: dataLabels,
          datasets: [
            {
              label: "Patrimônio",
              data: dataValues,
              fill: true,
              borderColor: "#1A535C",
              backgroundColor: "rgba(26, 83, 92, 0.25)",
            },
          ],
        });

        return userData[0];
      });
  }

  getAtualizacoesProcesso(id) {
    let mainData = [];
    return axios
      .get(`${API}/proccess/${id}/add-detail`)
      .then(function (response) {
        let dataAPI = response.data;
        const { detail } = dataAPI;

        detail.map((item) => {
          mainData.push({
            idProcesso: item.proccess_id,
            idAtualizacao: item.proccessdetail_id,
            data: item.proccessdetail_date
              ? moment(item.proccessdetail_date, "YYYY/MM/DD").format(
                  "YYYY/MM/DD"
                )
              : null,
            text: item.proccessdetail_desc,
          });
        });

        return mainData;
      });
  }
}

function repeatFor(arr, size) {
  var newArr = new Array(size);
  var repeat = 0;

  for (var i = 0; i < size; i++) {
    newArr[i] = arr[repeat];
    repeat++;
    if (repeat === 7) {
      repeat = 0;
    }
  }

  return newArr;
}
