import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import CardBalance from "../../../components/CardBalance";
import InvestidoresTab from "./components/investidoresTab";
import VendedoresTab from "./components/vendedoresTab";
import ProcessosTab from "./components/processosTab";
import Grid from "@material-ui/core/Grid";
import { Accordion, AccordionTab } from "primereact/accordion";

import { BO_FinanceiroService } from "./services/MainService";
import { parseValue } from "../../../common/format-number";

import "./../../../styles/scss/Forms.scss";

const BO_Dashboard = () => {
  const [valuesFinanceiro, setValuesFinanceiro] = useState({});
  const [width, height] = useWindowSize();

  const pageService = new BO_FinanceiroService();

  useEffect(() => {
    getInfos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getInfos() {
    pageService.getFinanceiro().then((data) => setValuesFinanceiro(data));
  }

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setWindowSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return windowSize;
  }

  return (
    <div className="pb-3">
      <div className="mb-4">
        <h3 className="title">Painel Principal</h3>
        <p className="lead">Todos os status JusCredit</p>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <div className="mb-2">
              <CardBalance
                label="Valor Total de Saque Pedente"
                bgColor="primary"
                value={parseValue(
                  valuesFinanceiro.total_pending_withdrawal || 0
                )}
              ></CardBalance>
            </div>
            <div className="mb-2">
              <CardBalance
                label="Valor Aberto para Investimento"
                bgColor="primary"
                value={parseValue(valuesFinanceiro.total_open_investment || 0)}
              >
                {/* <p> (Saldo Disponivel + Saldo Futuro do Trabalhador)</p> */}
              </CardBalance>
            </div>
            <div className="mb-2">
              <CardBalance
                label="Total Intermediado"
                bgColor="primary"
                value={parseValue(
                  valuesFinanceiro.total_possible_withdrawal || 0
                )}
              >
                {/* <p> (Saldo Disponivel + Saldo Futuro do Trabalhador)</p> */}
              </CardBalance>
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Accordion className="accordion-backoffice" activeIndex={0}>
              <AccordionTab
                header={
                  <React.Fragment>
                    <i className="pi pi-user"></i>
                    <span>Investidores</span>
                  </React.Fragment>
                }
              >
                <InvestidoresTab></InvestidoresTab>
              </AccordionTab>
              <AccordionTab
                header={
                  <React.Fragment>
                    <i className="pi pi-user"></i>
                    <span>Vendedores</span>
                  </React.Fragment>
                }
              >
                <VendedoresTab></VendedoresTab>
              </AccordionTab>
              <AccordionTab
                header={
                  <React.Fragment>
                    <i className="pi pi-paperclip"></i>
                    <span>Processos</span>
                  </React.Fragment>
                }
              >
                <ProcessosTab></ProcessosTab>
              </AccordionTab>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BO_Dashboard;
