import React, { Component } from "react";
import { matchPath } from "react-router";

import Input from "../../components/Input";
import Divider from "../../components/Divider";
import Button from "../../components/Button";

import "./RecuperarSenha.scss";
import RecuperarSenhaImg from "./../../assets/img/media1.png";
import RecuperarSenhaImgMobile from "./../../assets/img/media1_mob.png";
import API from "../../api";
const axios = require("axios");

class RecuperarSenha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordToken: false,
      idPath: null,
      email: "",
      password: "",
      confirmpassword: "",
    };
    this.verifyPasswordToken = this.verifyPasswordToken.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.savePassword = this.savePassword.bind(this);
  }

  componentDidMount() {
    // document.getElementsByClassName("main-content-wrapper")[0].style.padding = '0';
    this.verifyPasswordToken();
  }

  verifyPasswordToken() {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/recuperar-senha/:id",
      exact: true,
      strict: false,
    });
    this.setState({ passwordToken: match ? true : false }, () => {
      if (match != null || match != undefined) {
        this.getUrlPathID();
      }
    });
  }

  getUrlPathID() {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/recuperar-senha/:id",
      exact: true,
      strict: false,
    });
    this.setState({ idPath: match.params.id }, () => {
      // console.log(this.state.idPath);
    });
  }

  resetPassword() {
    console.log(this.state);
    axios
      .post(`${API}/resetpassword`, { email: this.state.email })
      .then(function (response) {
        console.log(response);
        alert(response.data.message);
      });
  }

  savePassword() {
    var self = this;
    if (this.state.password === this.state.confirmpassword) {
      let link = window.location.href;
      link = link.split("/");
      link = link[link.length - 1];
      axios
        .post(`${API}/savepassword`, {
          password: this.state.password,
          hashid: link,
        })
        .then(function (response) {
          console.log(response);
          alert("Senha salva com sucesso!");
          self.props.history.push("/");
        });
    } else {
      alert("As senhas estão diferentes");
    }
  }

  novaSenha() {
    this.props.history.push("/");
  }

  render() {
    const mobile = window.innerWidth < 728 ? true : false;

    if (this.state.passwordToken) {
      return (
        <div className="flex wrapper-page inverse-mobile">
          <div className="col-1">
            <img
              src={mobile ? RecuperarSenhaImgMobile : RecuperarSenhaImg}
              className="side-img"
              alt="Imagem"
            />
          </div>
          <div className="px-2 mobile-text-form d-block d-sm-none">
            <div className="mb-4">
              <h1 className="title text-white">Crie uma nova senha</h1>
              <p className="lead text-white mb-2">
                Crie uma nova senha para ter acesso a juscredit
              </p>
              <Divider height="1"></Divider>
            </div>
          </div>
          <div className="col-2">
            <div className="flex justify-content-start">
              <div
                className="px-2 py-3 py-sm-0 pl-sm-4 pr-sm-5"
                style={{ maxWidth: "660px" }}
              >
                <div className="mb-4 d-none d-sm-block">
                  <h1 className="title">Crie uma nova senha</h1>
                  <p className="lead mb-2">
                    Crie uma nova senha para ter acesso a juscredit
                  </p>
                  <Divider height="1"></Divider>
                </div>
                <form className="mb-4">
                  <Input
                    type="password"
                    label="Senha"
                    value={this.state.password}
                    sideFunc={(e) => {
                      this.setState({ password: e });
                    }}
                  ></Input>
                  <Input
                    type="password"
                    label="Confirmar Senha"
                    value={this.state.confirmpassword}
                    sideFunc={(e) => {
                      this.setState({ confirmpassword: e });
                    }}
                  ></Input>
                </form>
                <Button
                  label="Redefinir Senha"
                  level="primary"
                  onClick={this.savePassword}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex wrapper-page inverse-mobile">
          <div className="col-1">
            <img
              src={mobile ? RecuperarSenhaImgMobile : RecuperarSenhaImg}
              className="side-img"
              alt="Imagem"
            />
          </div>
          <div className="px-2 mobile-text-form d-block d-sm-none">
            <div className="mb-4">
              <h1 className="title text-white">Esqueceu sua senha?</h1>
              <p className="lead text-white mb-2">
                Digite seu e-mail para recuperar redefinir sua senha de acesso
                ao Juscredit.
              </p>
              <Divider height="1"></Divider>
            </div>
          </div>
          <div className="col-2">
            <div className="flex justify-content-start">
              <div
                className="px-2 py-3 py-sm-0 pl-sm-4 pr-sm-5"
                style={{ maxWidth: "660px" }}
              >
                <div className="mb-4 d-none d-sm-block">
                  <h1 className="title">Esqueceu sua senha?</h1>
                  <p className="lead mb-2">
                    Digite seu e-mail para recuperar redefinir sua senha de
                    acesso ao Juscredit.
                  </p>
                  <Divider height="1"></Divider>
                </div>
                <form className="mb-4">
                  <Input
                    label="E-mail"
                    value={this.state.email}
                    sideFunc={(e) => {
                      this.setState({ email: e }, () => {
                        console.log(this.state);
                      });
                    }}
                  ></Input>
                </form>
                <Button
                  label="Recuperar Senha"
                  onClick={this.resetPassword}
                  level="primary"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default RecuperarSenha;
