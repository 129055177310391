import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../api";
import { authenticateWithCustomToken } from '../auth';  

export class authService {
  constructor() {
    moment.locale("pt-br");
  }

  postHash(hash) {
    return axios
      .post(`${API}/login?autoLogin=${hash}`)
      .then((res) => {
        localStorage.setItem("hashcode", res.data[0].user_hashcode);
        sessionStorage.setItem("token", res.data[0].token);
        return res.data[0].user_accounttype;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  postLogin(user) {
    return axios
      .post(`${API}/login`, {
        email: user.email,
        password: user.password,
      })
      .then(function (res) {
        localStorage.setItem("hashcode", res.data[0].user_hashcode);
        sessionStorage.setItem("token", res.data[0].token);
        sessionStorage.setItem("firebaseToken", res.data[0].firebaseToken);
        authenticateWithCustomToken(res.data[0].firebaseToken);
        return res.data[0].user_accounttype;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}
