import React, { Component } from "react";
import { storage } from "../../../firebase";
import axios from "axios";

import Panel from "../../../components/Panel";
import PanelProccess from "../../../components/PanelProccess";
import PanelOffer from "../../../components/PanelOffer";
import Button from "../../../components/Button";
import MainHeading from "../../../components/MainHeading";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import placeholderImg from "../../../assets/img/placeholder.jpg";

import API from "../../../api";

class DashClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileAccount: "i",
      minValue: 0,
      fee: 0,
      user_id: 0,
      verifiedAcc: null,
      investmentSnack: false,
      investmentSnackSeverity: "success",
      investmentSnackMsg: "",
      etapaProccess: [
        { etapa: 0, ativo: false }, // Pedido em Analise
        { etapa: 1, ativo: false }, // Proposta de Antecipação
        { etapa: 7, ativo: false }, // Assinatura Digital
        { etapa: 2, ativo: false }, // Captação de Investimentos
        // { etapa: 3, ativo: false }, // Envio de Documentação
        // { etapa: 4, ativo: false }, // Anexando Documento
        { etapa: 5, ativo: false }, // Antecipação Finalizada
      ],
      process: {
        id: 0,
        proccess_step: 0,
        local: "",
        title: "",
        date_proccess: "",
        label: "",
        downloadLabel: "",
        antecipation: "",
        downloadlink: "",
        client_value: "",
        fee: "",
        total_value: "",
        loadingProcess: true,
      },
    };
    this.closeSnacks = this.closeSnacks.bind(this);
  }

  componentDidMount() {
    var date = new Date();
    this.getUserandProccess();
    setInterval(() => {
      this.getUserandProccess();
    }, (60 - date.getSeconds()) * 1000);
  }

  getUserandProccess() {
    var etapaProccess = this.state.etapaProccess;
    var arrEtapaProcess = [];
    var self = this;
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        var data = response.data[0];

        self.setState(
          {
            profileAccount: data.user_accounttype,
            fee: data.user_fee,
            minValue: data.user_minvalue,
            user_id: data.user_id,
            user_hash: data.user_hashcode,
            verifiedAcc:
              data?.user_bank_account !== null &&
              data?.user_dateregister !== null,
          },
          () => {
            if (self.state.fee === null || self.state.fee === "") {
              axios.get(`${API}/config`).then(function (response) {
                self.setState({
                  fee:
                    self.state.profileAccount !== "i"
                      ? response.data[0].configuration_feeclient
                      : response.data[0].configuration_feeinvestor,
                  minValue:
                    self.state.profileAccount === "i" &&
                    (self.state.minValue === "0" ||
                      self.state.minValue === null)
                      ? response.data[0].configuration_feeinvestment
                      : self.state.minValue,
                });
              });
            }
          }
        );
        if (data.user_accounttype === "c") {
          axios
            .get(`${API}/proccess/${localStorage.getItem("hashcode")}`)
            .then(function (response) {
              var procData = response.data[0];

              for (var item in etapaProccess) {
                if (
                  etapaProccess[item].etapa === Number(procData.proccess_status)
                ) {
                  arrEtapaProcess.push({ etapa: Number(item), ativo: true });
                } else {
                  arrEtapaProcess.push({ etapa: Number(item), ativo: false });
                }
              }

              self.setState({ etapaProccess: arrEtapaProcess });
              self.setState(
                {
                  process: {
                    id: procData.proccess_id,
                    proccess_step: procData.proccess_status,
                    local: procData.proccess_place,
                    title: procData.proccess_number,
                    date_proccess: procData.proccess_date,
                    label: procData.proccess_label,
                    downloadLabel: procData.downloadLabel,
                    downloadlink: procData.downloadLink,
                    client_value:
                      procData.proccess_clientvalue !== null
                        ? parseFloat(
                            procData.proccess_clientvalue
                              .replace(".", "")
                              .replace(",", ".")
                          ).toFixed(2)
                        : 0,
                    antecipation:
                      procData.proccess_brutevalue !== null
                        ? parseFloat(
                            procData.proccess_brutevalue
                              .replace(".", "")
                              .replace(",", ".")
                          ).toFixed(2)
                        : 0,
                    total_value:
                      procData.proccess_totalvalue !== null
                        ? parseFloat(
                            procData.proccess_totalvalue
                              .replace(".", "")
                              .replace(",", ".")
                          ).toFixed(2)
                        : 0,
                    fee:
                      procData.proccess_brutevalue !== null
                        ? parseFloat(
                            procData.proccess_brutevalue
                              .replace(".", "")
                              .replace(",", ".")
                          ).toFixed(2)
                        : 0,
                  },
                },
                () => {
                  var toDownloadLink = "";
                  storage
                    .ref(`PROCESSOS`)
                    .child(procData.proccess_id.toString())
                    .listAll()
                    .then((res) => {
                      res.items.forEach(function (itemRef, index) {
                        // All the items under listRef.
                        var fullPath = itemRef
                          .toString()
                          .substring(itemRef.toString().lastIndexOf("/") + 1);
                        var namePath = fullPath
                          .split(".")
                          .slice(0, -1)
                          .join(".");
                        var extensionPath = fullPath
                          .split(".")
                          .slice(1)
                          .join(".");

                        if (
                          procData.proccess_status === "3" &&
                          namePath === "doc-cliente"
                        ) {
                          storage
                            .ref(`PROCESSOS/${procData.proccess_id}`)
                            .child(namePath + "." + extensionPath)
                            .getDownloadURL()
                            .then((url) => {
                              toDownloadLink = url;
                              self.setState({
                                process: {
                                  ...self.state.process,
                                  downloadLabel: "Baixar Documento",
                                  downloadlink: toDownloadLink,
                                },
                              });
                            });
                        }
                      });
                    });

                  self.setState({
                    process: { ...self.state.process, loadingProcess: false },
                  });
                }
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addDefaultSrc(ev) {
    ev.target.src = placeholderImg;
  }

  closeSnacks() {
    this.setState({
      investmentSnack: false,
    });
  }

  endRegister(user_hash) {
    document.location.href = `/cadastro?code=${user_hash}`;
  }

  render() {
    const {
      verifiedAcc,
      profileAccount,
      process,
      fee,
      user_hash,
      etapaProccess,
      investmentSnack,
      investmentSnackSeverity,
      investmentSnackMsg,
    } = this.state;

    return (
      <div>
        <MainHeading></MainHeading>
        <Snackbar
          open={investmentSnack}
          autoHideDuration={3000}
          onClose={() => {
            this.closeSnacks();
          }}
        >
          <Alert
            onClose={() => {
              this.closeSnacks();
            }}
            severity={investmentSnackSeverity}
          >
            {investmentSnackMsg}
          </Alert>
        </Snackbar>
        {profileAccount === "c" && (
          <div className="py-4">
            <h4 className="title">Status do seu processo</h4>
            <div className="mt-3">
              {!process.loadingProcess && (
                <>
                  {verifiedAcc !== null && verifiedAcc === false && (
                    <Panel>
                      <p>
                        Complete seu cadastro para visualizar o estado do seu
                        processo.
                      </p>
                      <Button
                        label="Finalizar"
                        level="primary mt-2"
                        onClick={() => {
                          this.endRegister(user_hash);
                        }}
                      ></Button>
                    </Panel>
                  )}
                  {verifiedAcc &&
                    (process.proccess_step !== "1" ? (
                      <PanelProccess
                        isPositive
                        hasLabel
                        dados={etapaProccess}
                        local={process.local}
                        title={`Processo número ${process.title}`}
                        date={process.date_proccess}
                        label={process.label}
                        downloadLabel={process.downloadLabel}
                        downloadLink={process.downloadlink}
                      ></PanelProccess>
                    ) : (
                      <PanelOffer
                        isPositive
                        anticipation={process.antecipation}
                        user_part={process.client_value}
                        total={process.total_value}
                        tax={process.fee * (fee / 100)}
                        id={process}
                      ></PanelOffer>
                    ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DashClient;
