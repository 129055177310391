import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { notNullStr } from "../../../common/not-null";
import { currencyFormatter } from "../../../common/br-currency";
import Panel from "../../../components/Panel";
import Button from "../../../components/Button";
import Grid from "@material-ui/core/Grid";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import rules from "../../../common/validation-rules";

import API from "../../../api";

import "./../../../styles/scss/Forms.scss";

const ConfiguracoesBackoffice = () => {
  const [form, setForm] = useState({});
  const [formValid, setFormValid] = useState(null);
  const [defaultValues] = useState({
    feeclient: 0,
    feeinvestor: 0,
    feewithdraw: "",
    feeinvestment: "",
  });

  const {
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  /* OBSERVA O FORM PRA ATUALIZAR O VALOR */
  useEffect(() => {
    const subscription = watch((form) => {
      setForm(form);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    getConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getConfig() {
    axios
      .get(`${API}/config`)
      .then(function (response) {
        let dataAPI = response.data[0];
        // setForm({
        //   feeclient: dataAPI.configuration_feeclient,
        //   feeinvestor: dataAPI.configuration_feeinvestor,
        //   feewithdraw: dataAPI.configuration_feewithdraw,
        //   feeinvestment: dataAPI.configuration_feeinvestment
        // });
        console.log(dataAPI);
        setValue("feeclient", notNullStr(dataAPI.configuration_feeclient), {
          shouldValidate: true,
        });
        setValue("feeinvestor", notNullStr(dataAPI.configuration_feeinvestor), {
          shouldValidate: true,
        });
        setValue(
          "feewithdraw",
          currencyFormatter(notNullStr(dataAPI.configuration_feewithdraw, "0")),
          { shouldValidate: true }
        );
        // setValue('feeinvestment', currencyFormatter(notNullStr(dataAPI.configuration_feeinvestment)), { shouldValidate: true });
        setValue(
          "feeinvestment",
          notNullStr(dataAPI.configuration_feeinvestment),
          { shouldValidate: true }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function attModalValues(event, formItem) {
  //   setForm(prevState => ({
  //     ...prevState,
  //     [formItem]: event.value
  //   }))
  // }

  // function attModalValuesBrCur(event, formItem) {
  //   const { value } = event.target;
  //   setForm(prevState => ({
  //     ...prevState,
  //     [formItem]: currencyFormatter(value)
  //   }))
  // }

  // function handleFocusBrCur(event) {
  //   event.target.select();
  // }

  function saveConfig() {
    axios
      .post(`${API}/config`, form)
      .then(function (response) {
        if (response.data.affectedRows === 1) {
          alert("Dados salvos com sucesso!");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div>
      <div className="mb-4">
        <h4 className="title">Configurações</h4>
        <p className="lead">Altere taxas da pltaforma</p>
      </div>
      <div className="mb-3">
        <Panel isPositive>
          <h6 className="title">Modificar Dados</h6>
          <p className="lead"></p>
          <div className="my-3">
            <Grid className="pt-2" container spacing={2}>
              <Grid item xs={6} sm={3}>
                <span className="p-float-label input-wrapper">
                  <Controller
                    name="feeclient"
                    control={control}
                    rules={{ pattern: rules.onlyNumber.pattern }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        inputMode={"numeric"}
                        min={0}
                        max={100}
                        maxLength={3}
                        keyfilter={/[\d]/g}
                        onChange={(e) =>
                          field.onChange(
                            +e.target.value > 100 ? 100 : +e.target.value
                          )
                        }
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    className={classNames({ "p-error": errors.feeclient })}
                  >
                    Taxa trabalhador (%)
                  </label>
                  <p className="input-helper">
                    <small>Valor de 0 a 100</small>
                  </p>
                  {getFormErrorMessage("feeclient")}
                </span>
                {/* <span className="p-float-label">
                  <InputNumber value={form.feeclient} min={0} max={100} onValueChange={(e) => attModalValues(e, 'feeclient')} />
                  <label>Taxa trabalhador (%)</label>
                  <p className="input-helper"><small>Valor de 0 a 100</small></p>
                </span> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                <span className="p-float-label input-wrapper">
                  <Controller
                    name="feeinvestor"
                    control={control}
                    rules={{ pattern: rules.onlyNumber.pattern }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        inputMode={"numeric"}
                        min={0}
                        max={100}
                        maxLength={3}
                        keyfilter={/[\d]/g}
                        onChange={(e) =>
                          field.onChange(
                            +e.target.value > 100 ? 100 : +e.target.value
                          )
                        }
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    className={classNames({ "p-error": errors.feeinvestor })}
                  >
                    Taxa investidor (%)
                  </label>
                  <p className="input-helper">
                    <small>Valor de 0 a 100</small>
                  </p>
                  {getFormErrorMessage("feeinvestor")}
                </span>
                {/* <span className="p-float-label">
                  <InputNumber value={form.feeinvestor} min={0} max={100} onValueChange={(e) => attModalValues(e, 'feeinvestor')} />
                  <label>Taxa investidor (%)</label>
                  <p className="input-helper"><small>Valor de 0 a 100</small></p>
                </span> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                <span className="p-float-label input-wrapper">
                  <Controller
                    name="feewithdraw"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        inputMode={"numeric"}
                        keyfilter={/[\d]/g}
                        onChange={(e) =>
                          field.onChange(currencyFormatter(e.target.value))
                        }
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    className={classNames({ "p-error": errors.feewithdraw })}
                  >
                    Taxa saque (R$)
                  </label>
                  <p className="input-helper">
                    <small>Apenas números</small>
                  </p>
                  {getFormErrorMessage("feewithdraw")}
                </span>
                {/* <span className="p-float-label">
                  <InputText keyfilter={/[\d]/g} value={currencyFormatter(form.feewithdraw)} inputMode={'numeric'} onFocus={(e) => handleFocusBrCur(e)} onChange={(e) => { attModalValuesBrCur(e, 'feewithdraw'); }} />
                  <label>Taxa saque (R$)</label>
                  <p className="input-helper"><small>Apenas números</small></p>
                </span> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                <span className="p-float-label input-wrapper">
                  <Controller
                    name="feeinvestment"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        inputMode={"numeric"}
                        keyfilter={/[\d]/g}
                        onChange={(e) => field.onChange(+e.target.value)}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    className={classNames({ "p-error": errors.feeinvestment })}
                  >
                    Valor investimento (R$)
                  </label>
                  <p className="input-helper">
                    <small>Apenas números</small>
                  </p>
                  {getFormErrorMessage("feeinvestment")}
                </span>
                {/* <span className="p-float-label">
                  <InputText keyfilter={/[\d]/g} value={form.feeinvestment} inputMode={'numeric'} onFocus={(e) => handleFocusBrCur(e)} onChange={(e) => attModalValuesBrCur(e, 'feeinvestment')} />
                  <label>Valor investimento (R$)</label>
                  <p className="input-helper"><small>Apenas números</small></p>
                </span> */}
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="flex justify-content-end">
                  <Button
                    level="primary"
                    size="md"
                    label="Salvar alterações"
                    disabled={!formValid}
                    onClick={() => {
                      saveConfig();
                    }}
                  ></Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default ConfiguracoesBackoffice;
