import React, { useState, useEffect } from "react";
import { matchPath } from "react-router";
import { notNullStr } from "../../../../../common/not-null";
// import { currencyFormatter } from '../../common/br-currency';
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import rules from "../../../../../common/validation-rules";

import Grid from "@material-ui/core/Grid";

import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";

import Panel from "../../../../../components/Panel";

import API from "../../../../../api";

const FieldsUsuario = (props) => {
  const [pageID, setPageID] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [listNacionalidade] = useState([
    { value: "brasileiro", label: "Brasileiro" },
    { value: "americano", label: "Estrangeiro" },
  ]);
  const [listEstadoCivil] = useState([
    { value: "solteiro", label: "Solteiro" },
    { value: "uniaoEstavel", label: "União Estável" },
    { value: "casado", label: "Casado" },
    { value: "separadoJudicial", label: "Separado Judicialmente" },
    { value: "divorciado", label: "Divorciado" },
    { value: "viuvo", label: "Viúvo" },
  ]);
  const [listTipoPessoa] = useState([
    { value: "f", label: "Física" },
    { value: "j", label: "Jurídica" },
  ]);
  const [listTipoTrabalhador] = useState([
    { value: "0", label: "Não definido" },
    { value: "t", label: "Trabalhador" },
    { value: "a", label: "Advogado" },
  ]);
  const [listBancos] = useState([
    { value: "0", label: "Selecione seu banco" },
    { value: "1", label: "1 - Banco do Brasil" },
    { value: "33", label: "33 - Santander" },
    { value: "36", label: "36 - Banco Bradesco BBI S.A." },
    { value: "77", label: "77 - Banco Inter S.A." },
    { value: "104", label: "104 - Caixa Econômica Federal" },
    { value: "121", label: "121 - Banco Agibank S.A." },
    { value: "208", label: "208 - Banco BTG Pactual S.A." },
    { value: "212", label: "212 - Banco Original S.A." },
    { value: "237", label: "237 - Banco Bradesco S.A." },
    { value: "246", label: "246 - Banco ABC Brasil S.A." },
    { value: "260", label: "260 - NuBank" },
    { value: "318", label: "318 - Banco BMG S.A." },
    { value: "341", label: "341 - Itaú Unibanco S.A." },
    { value: "422", label: "422 - Banco Safra S.A" },
    { value: "748", label: "748 - Banco Cooperativo Sicredi S.A." },
    { value: "756", label: "756 - Banco Cooperativo do Brasil S.A. - BANCOOB" },
  ]);
  const [listPoliticamenteExposto] = useState([
    { value: "0", label: "Não" },
    { value: "1", label: "Sim" },
  ]);
  const [defaultValues] = useState({
    id: "",
    link: "",
    nome_completo: "",
    rg: "",
    email: "",
    cpf: "",
    telefone: "",
    profissao: "",
    data_nascimento: "",
    nacionalidade: "",
    estado_civil: "",
    cep: "",
    cidade: "",
    complemento: "",
    bairro: "",
    numero: "",
    estado: "",
    logradouro: "",
    nome_mae: "",
    pessoa_politica: "",
    renda_mensal: "",
    patrimonio_aproximado: "",
    banco: "",
    agencia: "",
    conta_digito: "",
    tipo_perfil: "",
    tipo_pessoa: "",
    cnpj: "",
    usuario_aprovado: "",
    tipo_cliente: "",
    status_usuario: "",
    primeiro_acesso: "",
    user_hash: "",
    user_fee: "",
    user_withdrawfee: "",
    user_minvalue: "",
  });

  const {
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const subscription = watch((form) => {
      setFormValues(form);
      props.onFormUpdate(form);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    getRouterID();
  }, []);

  useEffect(() => {
    if (pageID !== null) {
      getUsuarioByID(pageID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageID]);

  useEffect(() => {
    props.onFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const getRouterID = () => {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/backoffice/lista-usuarios/usuario/:id",
      exact: true,
      strict: false,
    });

    setPageID(match.params.id);
  };

  // const updateForm = (ev) => {
  //   const { name, type, value, checked } = ev.target;
  //   const blockedList = [
  //     'id',
  //     'primeiro_acesso'
  //   ];

  //   // eslint-disable-next-line array-callback-return
  //   if (!blockedList.includes(name)) {
  //     if (type === "checkbox") {
  //       setFormValues(prev => ({
  //         ...prev,
  //         [name]: checked
  //       }));
  //     } else {
  //       setFormValues(prev => ({
  //         ...prev,
  //         [name]: value
  //       }));
  //     }
  //   }
  // }

  const getUsuarioByID = (userID) => {
    axios
      .get(`${API}/user/${userID}`)
      .then(function (response) {
        const dataAPI = response.data[0];
        // setFormValues({
        //   id: notNullStr(dataAPI.user_id),
        //   link: "https://app.juscredit.com.br/cadastro?code=" + dataAPI.user_hashcode,
        //   nome_completo: notNullStr(dataAPI.user_name),
        //   rg: notNullStr(dataAPI.user_rg),
        //   email: notNullStr(dataAPI.user_email),
        //   cpf: notNullStr(dataAPI.user_cpf),
        //   telefone: notNullStr(dataAPI.user_phone),
        //   profissao: notNullStr(dataAPI.user_occupation),
        //   data_nascimento: notNullStr(dataAPI.user_datebirth),
        //   nacionalidade: notNullStr(dataAPI.user_nationality),
        //   estado_civil: notNullStr(dataAPI.user_maritalstatus),
        //   cep: notNullStr(dataAPI.user_address_cep),
        //   cidade: notNullStr(dataAPI.user_address_city),
        //   complemento: notNullStr(dataAPI.user_address_complement),
        //   bairro: notNullStr(dataAPI.user_address_neighborhood),
        //   numero: notNullStr(dataAPI.user_address_number),
        //   estado: notNullStr(dataAPI.user_address_state),
        //   logradouro: dataAPI.user_address_street ? notNullStr(dataAPI.user_address_street) : notNullStr(dataAPI.user_address),
        //   nome_mae: notNullStr(dataAPI.user_mothername),
        //   pessoa_politica: notNullStr(dataAPI.user_politicallyexposed),
        //   renda_mensal: notNullStr(dataAPI.user_monthlyincome).toString().replace(".", "").replace(",", "."),
        //   patrimonio_aproximado: notNullStr(dataAPI.user_approximateassets).toString().replace(".", "").replace(",", "."),
        //   banco: notNullStr(dataAPI.user_bank_name, '0'),
        //   agencia: notNullStr(dataAPI.user_bank_agency),
        //   conta_digito: notNullStr(dataAPI.user_bank_account),
        //   tipo_perfil: notNullStr(dataAPI.user_accounttype),
        //   tipo_pessoa: notNullStr(dataAPI.user_persontype),
        //   cnpj: '',
        //   usuario_aprovado: notNullStr(dataAPI.user_aproved),
        //   tipo_cliente: notNullStr(dataAPI.user_clienttype, '0'),
        //   status_usuario: notNullStr(dataAPI.user_status),
        //   primeiro_acesso: notNullStr(dataAPI.user_dateregister),
        //   user_hash: notNullStr(dataAPI.user_hashcode),
        //   user_fee: notNullStr(dataAPI.user_fee),
        //   user_withdrawfee: notNullStr(dataAPI.user_withdrawfee),
        //   user_minvalue: notNullStr(dataAPI.user_minvalue)
        // });

        setValue("id", notNullStr(dataAPI.user_id), { shouldValidate: true });
        setValue(
          "link",
          "https://app.juscredit.com.br/cadastro?code=" + dataAPI.user_hashcode,
          { shouldValidate: true }
        );
        setValue("nome_completo", notNullStr(dataAPI.user_name), {
          shouldValidate: true,
        });
        setValue("rg", notNullStr(dataAPI.user_rg), { shouldValidate: true });
        setValue("email", notNullStr(dataAPI.user_email), {
          shouldValidate: true,
        });
        setValue("cpf", notNullStr(dataAPI.user_cpf), { shouldValidate: true });
        setValue("telefone", notNullStr(dataAPI.user_phone), {
          shouldValidate: true,
        });
        setValue("profissao", notNullStr(dataAPI.user_occupation), {
          shouldValidate: true,
        });
        setValue("data_nascimento", notNullStr(dataAPI.user_datebirth), {
          shouldValidate: true,
        });
        setValue("nacionalidade", notNullStr(dataAPI.user_nationality), {
          shouldValidate: true,
        });
        setValue("estado_civil", notNullStr(dataAPI.user_maritalstatus), {
          shouldValidate: true,
        });
        setValue("cep", notNullStr(dataAPI.user_address_cep), {
          shouldValidate: true,
        });
        setValue("cidade", notNullStr(dataAPI.user_address_city), {
          shouldValidate: true,
        });
        setValue("complemento", notNullStr(dataAPI.user_address_complement), {
          shouldValidate: true,
        });
        setValue("bairro", notNullStr(dataAPI.user_address_neighborhood), {
          shouldValidate: true,
        });
        setValue("numero", notNullStr(dataAPI.user_address_number), {
          shouldValidate: true,
        });
        setValue("estado", notNullStr(dataAPI.user_address_state), {
          shouldValidate: true,
        });
        setValue(
          "logradouro",
          dataAPI.user_address_street
            ? notNullStr(dataAPI.user_address_street)
            : notNullStr(dataAPI.user_address),
          { shouldValidate: true }
        );
        setValue("nome_mae", notNullStr(dataAPI.user_mothername), {
          shouldValidate: true,
        });
        setValue(
          "pessoa_politica",
          notNullStr(dataAPI.user_politicallyexposed),
          { shouldValidate: true }
        );
        setValue(
          "renda_mensal",
          notNullStr(dataAPI.user_monthlyincome)
            .toString()
            .replace(".", "")
            .replace(",", "."),
          { shouldValidate: true }
        );
        setValue(
          "patrimonio_aproximado",
          notNullStr(dataAPI.user_approximateassets)
            .toString()
            .replace(".", "")
            .replace(",", "."),
          { shouldValidate: true }
        );
        setValue("banco", notNullStr(dataAPI.user_bank_name, "0"), {
          shouldValidate: true,
        });
        setValue("agencia", notNullStr(dataAPI.user_bank_agency), {
          shouldValidate: true,
        });
        setValue("conta_digito", notNullStr(dataAPI.user_bank_account), {
          shouldValidate: true,
        });
        setValue("tipo_perfil", notNullStr(dataAPI.user_accounttype), {
          shouldValidate: true,
        });
        setValue("tipo_pessoa", notNullStr(dataAPI.user_persontype), {
          shouldValidate: true,
        });
        setValue("cnpj", "", { shouldValidate: true });
        setValue("usuario_aprovado", notNullStr(dataAPI.user_aproved), {
          shouldValidate: true,
        });
        setValue("tipo_cliente", notNullStr(dataAPI.user_clienttype, "0"), {
          shouldValidate: true,
        });
        setValue("status_usuario", notNullStr(dataAPI.user_status), {
          shouldValidate: true,
        });
        setValue("primeiro_acesso", notNullStr(dataAPI.user_dateregister), {
          shouldValidate: true,
        });
        setValue("code", notNullStr(dataAPI.user_hashcode), {
          shouldValidate: true,
        });
        setValue("user_fee", notNullStr(dataAPI.user_fee), {
          shouldValidate: true,
        });
        setValue("user_withdrawfee", notNullStr(dataAPI.user_withdrawfee), {
          shouldValidate: true,
        });
        setValue("user_minvalue", notNullStr(dataAPI.user_minvalue), {
          shouldValidate: true,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <Panel isPositive>
        <div>
          <Grid className="pt-2" container spacing={1}>
            <Grid item xs={12} md={9}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="link"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled
                      readOnly
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.link })}>
                  Link de Cadastro
                </label>
                {getFormErrorMessage("link")}
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="primeiro_acesso"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled
                      readOnly
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.primeiro_acesso })}
                >
                  Primeiro acesso em:
                </label>
                {getFormErrorMessage("primeiro_acesso")}
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="nome_completo"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.nome_completo })}
                >
                  Nome Completo
                </label>
                {getFormErrorMessage("nome_completo")}
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="nome_mae"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.nome_mae })}>
                  Nome da Mãe
                </label>
                {getFormErrorMessage("nome_mae")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="rg"
                  control={control}
                  rules={{ pattern: rules.rg.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.rg })}>
                  RG
                </label>
                {getFormErrorMessage("rg")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="cpf"
                  control={control}
                  rules={{ pattern: rules.cpf.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.cpf })}>
                  CPF
                </label>
                {getFormErrorMessage("cpf")}
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="email"
                  control={control}
                  rules={{ pattern: rules.email.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.email })}>
                  Email
                </label>
                {getFormErrorMessage("email")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="telefone"
                  control={control}
                  rules={{ pattern: rules.telefone.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.telefone })}>
                  Telefone
                </label>
                {getFormErrorMessage("telefone")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="data_nascimento"
                  control={control}
                  rules={{ pattern: rules.data.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.data_nascimento })}
                >
                  Data de Nascimento
                </label>
                {getFormErrorMessage("data_nascimento")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="nacionalidade"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listNacionalidade}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Nacionalidade</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="estado_civil"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listEstadoCivil}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Estado Civil</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="tipo_pessoa"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listTipoPessoa}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Tipo de Pessoa</label>
              </span>
            </Grid>
            {formValues.tipo_pessoa === "j" && (
              <Grid item xs={4} md={5}>
                <span className="p-float-label input-wrapper">
                  <Controller
                    name="cnpj"
                    control={control}
                    rules={{ pattern: rules.cnpj.pattern }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label className={classNames({ "p-error": errors.cnpj })}>
                    CNPJ
                  </label>
                  {getFormErrorMessage("cnpj")}
                </span>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="cep"
                  control={control}
                  rules={{ pattern: rules.cep.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.cep })}>
                  CEP
                </label>
                {getFormErrorMessage("cep")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="estado"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.estado })}>
                  Estado
                </label>
                {getFormErrorMessage("estado")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="cidade"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.cidade })}>
                  Cidade
                </label>
                {getFormErrorMessage("cidade")}
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="logradouro"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.logradouro })}>
                  Endereço
                </label>
                {getFormErrorMessage("logradouro")}
              </span>
            </Grid>
            <Grid item xs={4} md={2}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="numero"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.numero })}>
                  Número
                </label>
                {getFormErrorMessage("numero")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="bairro"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.bairro })}>
                  Bairro
                </label>
                {getFormErrorMessage("bairro")}
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="complemento"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.complemento })}
                >
                  Complemento
                </label>
                {getFormErrorMessage("complemento")}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>
            <Grid item xs={4} md={4}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="banco"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listBancos}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Banco</label>
              </span>
            </Grid>
            <Grid item xs={4} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="agencia"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.agencia })}>
                  Agencia
                </label>
                {getFormErrorMessage("agencia")}
              </span>
            </Grid>
            <Grid item xs={4} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="conta_digito"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.conta_digito })}
                >
                  Conta c/ Digito
                </label>
                {getFormErrorMessage("conta_digito")}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="tipo_cliente"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listTipoTrabalhador}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Tipo de Trabalhador</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="pessoa_politica"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listPoliticamenteExposto}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Políticamente Exposto</label>
              </span>
            </Grid>
            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="user_fee"
                  control={control}
                  rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      // onChange={(e) => field.onChange(currencyFormatter(e.target.value))}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.user_fee })}>
                  Taxa do Trabalhador/Investidor
                </label>
                {getFormErrorMessage("user_fee")}
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="user_withdrawfee"
                  control={control}
                  rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      // onChange={(e) => field.onChange(currencyFormatter(e.target.value))}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.user_withdrawfee })}
                >
                  Taxa de Saque
                </label>
                {getFormErrorMessage("user_withdrawfee")}
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="user_minvalue"
                  control={control}
                  rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      // onChange={(e) => field.onChange(currencyFormatter(e.target.value))}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.user_minvalue })}
                >
                  Valor Mínimo para Investir
                </label>
                {getFormErrorMessage("user_minvalue")}
              </span>
            </Grid>
          </Grid>
        </div>
      </Panel>
      {/* <Panel isPositive>
        <div>
          <Grid className="pt-2" container spacing={1}>
             <Grid item xs={12} md={9}>
              <span className="p-float-label input-wrapper">
                <InputText
                  readOnly
                  type="text"
                  name="link"
                  value={formValues.link}
                  onChange={(e) => { updateForm(e) }} />
                <label>Link de Cadastro</label>
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  disabled
                  readOnly
                  type="text"
                  name="primeiro_acesso"
                  keyfilter={/[\d]/g}
                  value={formValues.primeiro_acesso}
                  onChange={(e) => { updateForm(e) }} />
                <label>Primeiro acesso em:</label>
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="nome_completo"
                  value={formValues.nome_completo}
                  onChange={(e) => { updateForm(e) }} />
                <label>Nome Completo</label>
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="nome_mae"
                  value={formValues.nome_mae}
                  onChange={(e) => { updateForm(e) }} />
                <label>Nome da Mãe</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="rg"
                  value={formValues.rg}
                  onChange={(e) => { updateForm(e) }} />
                <label>RG</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="cpf"
                  keyfilter={/[\d]/g}
                  value={formValues.cpf}
                  onChange={(e) => { updateForm(e) }} />
                <label>CPF</label>
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="email"
                  value={formValues.email}
                  onChange={(e) => { updateForm(e) }} />
                <label>Email</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="telefone"
                  keyfilter={/[\d]/g}
                  value={formValues.telefone}
                  onChange={(e) => { updateForm(e) }} />
                <label>Telefone</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="data_nascimento"
                  keyfilter={/[\d]/g}
                  value={formValues.data_nascimento}
                  onChange={(e) => { updateForm(e) }} />
                <label>Data de Nascimento</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="nacionalidade"
                  value={formValues.nacionalidade}
                  options={listNacionalidade}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Nacionalidade</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="estado_civil"
                  value={formValues.estado_civil}
                  options={listEstadoCivil}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Estado Civil</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="tipo_pessoa"
                  value={formValues.tipo_pessoa}
                  options={listTipoPessoa}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Tipo de Pessoa</label>
              </span>
            </Grid>
            {formValues.tipo_pessoa === 'j' && (
              <Grid item xs={4} md={5}>
                <span className="p-float-label input-wrapper">
                  <InputText
                    type="text"
                    name="cnpj"
                    value={formValues.cnpj}
                    onChange={(e) => { updateForm(e) }} />
                  <label>CNPJ</label>
                </span>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>


            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="cep"
                  keyfilter={/[\d]/g}
                  value={formValues.cep}
                  onChange={(e) => { updateForm(e) }} />
                <label>CEP</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="estado"
                  keyfilter={/[\d]/g}
                  value={formValues.estado}
                  onChange={(e) => { updateForm(e) }} />
                <label>Estado</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="cidade"
                  keyfilter={/[\d]/g}
                  value={formValues.cidade}
                  onChange={(e) => { updateForm(e) }} />
                <label>Cidade</label>
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="logradouro"
                  keyfilter={/[\d]/g}
                  value={formValues.logradouro}
                  onChange={(e) => { updateForm(e) }} />
                <label>Endereço</label>
              </span>
            </Grid>
            <Grid item xs={4} md={2}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="numero"
                  keyfilter={/[\d]/g}
                  value={formValues.numero}
                  onChange={(e) => { updateForm(e) }} />
                <label>Número</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="bairro"
                  keyfilter={/[\d]/g}
                  value={formValues.bairro}
                  onChange={(e) => { updateForm(e) }} />
                <label>Bairro</label>
              </span>
            </Grid>
            <Grid item xs={4} md={6}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="complemento"
                  keyfilter={/[\d]/g}
                  value={formValues.complemento}
                  onChange={(e) => { updateForm(e) }} />
                <label>Complemento</label>
              </span>
            </Grid>



            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>


            <Grid item xs={4} md={4}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="banco"
                  value={formValues.banco}
                  options={listBancos}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Banco</label>
              </span>
            </Grid>
            <Grid item xs={4} md={4}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="agencia"
                  keyfilter={/[\d]/g}
                  value={formValues.agencia}
                  onChange={(e) => { updateForm(e) }} />
                <label>Agencia</label>
              </span>
            </Grid>
            <Grid item xs={4} md={4}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="conta_digito"
                  keyfilter={/[\d]/g}
                  value={formValues.conta_digito}
                  onChange={(e) => { updateForm(e) }} />
                <label>Conta c/ Digito</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="tipo_cliente"
                  value={formValues.tipo_cliente}
                  options={listTipoTrabalhador}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Tipo de Trabalhador</label>
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="pessoa_politica"
                  value={formValues.pessoa_politica}
                  options={listPoliticamenteExposto}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                />
                <label>Políticamente Exposto</label>
              </span>
            </Grid>



            <Grid item xs={12}>
              <Divider className="mt-0"></Divider>
            </Grid>


            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="user_fee"
                  keyfilter={/[\d]/g}
                  value={formValues.user_fee}
                  onChange={(e) => { updateForm(e) }} />
                <label>Taxa do Trabalhador/Investidor</label>
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="user_withdrawfee"
                  keyfilter={/[\d]/g}
                  value={formValues.user_withdrawfee}
                  onChange={(e) => { updateForm(e) }} />
                <label>Taxa de Saque</label>
              </span>
            </Grid>
            <Grid item xs={12} md={3}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="user_minvalue"
                  keyfilter={/[\d]/g}
                  value={formValues.user_minvalue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Valor Mínimo para Investir</label>
              </span>
            </Grid> 
          </Grid>
        </div>
      </Panel>*/}
    </>
  );
};

export default FieldsUsuario;
