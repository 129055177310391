import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Icon from "../../components/Icon";

import API from "../../api";

const EndRegisterMessage = () => {
  const [user, setUser] = useState({});
  const history = useHistory();

  useEffect(() => {
    axios.get(`${API}/user/${localStorage.getItem("hashcode")}`).then((res) => {
      let data = res.data[0];
      setUser({
        hash: data?.user_hashcode,
        verifiedAcc:
          data?.user_bank_account !== null &&
          res.data[0]?.user_dateregister !== null,
      });
    });
  }, []);

  const endRegister = () => {
    history.push(`/cadastro?code=${user.hash}`);
  };

  if (user.verifiedAcc !== null && user.verifiedAcc === false) {
    return (
      <div className="end-register-message-wrapper">
        <Icon name="exclamation" size={18} bgColor="mr-1" />
        <h6 className="mr-3">Complete o cadastro para investir</h6>
        <Button
          label="Finalizar Cadastro"
          level="primary"
          size="md"
          onClick={() => endRegister()}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default EndRegisterMessage;
