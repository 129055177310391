import React, { useState, useEffect } from "react";
import axios from "axios";
import DashClient from "./components/dashClient";
import DashInvest from "./components/dashInvest";

import "./../../styles/scss/Grid.scss";
import "./Dashboard.scss";

import API from "../../api";

const Dashboard = () => {
  const [accType, setAccType] = useState();

  useEffect(() => {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then((res) => setAccType(res.data[0].user_accounttype));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDash = (accType) => {
    if (accType === "i") {
      return (
        <>
          <DashInvest></DashInvest>
        </>
      );
    } else if (accType === "c") {
      return (
        <>
          <DashClient></DashClient>
        </>
      );
    } else {
      return (
        <>
          <div className="p-d-flex p-ai-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2em" }}
            ></i>{" "}
            <span className="ml-1 mb-0">Carregando...</span>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div>{renderDash(accType)}</div>
      </div>
    </>
  );
};

export default Dashboard;
