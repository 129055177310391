import React, { Component } from 'react';
import Divider from '../../Divider';

import './../Stepper.scss';

class StepperHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepsArr: [],
      slideIndex: null
    }
  }

  // componentDidMount() {
  //   this.getItems();
  // }

  // getItems() {
  //   this.setState({ stepsArr: this.props.dados }, () => {
  //   });
  // }

  setActiveItem(index) {
    if (index === this.props.actualItem && this.props.dados[index].finished !== true) {
      return 'active'
    } else if (index >= this.props.actualItem && this.props.dados[index].finished !== true) {
      return 'standby'
    } else {
      return 'finished'
    }
  }

  labelIndex(step, index) {
    if (index >= 0 && index < this.props.dados.length - 1) {
      return <p className={`stepper-label`} title={step.itemSection}>{step.itemSection}</p>
    } else { }
  }

  render() {

    const stepsDisplay = this.props.dados.map((step, index) => {
      return (
        <div key={index} className={`stepper-header-wrapper ${this.setActiveItem(index)}`}>
          <div className="stepper-items-inner">
            <span className={`stepper-number`}>{index + 1}</span>
            {this.labelIndex(step, index)}
          </div>
          <Divider height="2" solid={`solid ${this.setActiveItem(index)}`} />
        </div >
      )
    });

    return (
      <div className="stepper-header py-3 mt-4 mb-3" >
        {stepsDisplay}
      </div>
    );
  }
}

export default StepperHeader;