import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Icon from "../../components/Icon";
import EndRegisterMessage from "../../components/EndRegisterMessage";
// import Button from './../Button/index';
import AvatarImg from "./../../assets/img/photo.png";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import "./../../styles/scss/Grid.scss";
import "./Sidebar.scss";

import { resetScroll } from "./../../common/reset-scroll";

import API from "../../api";

const Sidebar = ({ children }) => {
  const axios = require("axios");
  let history = useHistory();

  const blockRoutes = ["/", "/cadastro", "/recuperar-senha", "/xi854w6aRo2"];

  const [menuState, setMenuState] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const [profileAccount, setProfileAccount] = useState("");
  const [name, setName] = useState("");
  const [mdlLogout, setMdlLogout] = useState({ open: false });

  useEffect(() => {
    resetScroll();
    compareBlocked(history.location.pathname);
    getMainInfos();
    history.listen((location, action) => {
      resetScroll();
      compareBlocked(location.pathname);
      getMainInfos();
    });
    let elem = document.getElementsByClassName("triggerClass");
    for (var i = 0; i < elem.length; i += 2) {
      elem[i].addEventListener("click", () => {}, false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getMainInfos() {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        let data = response.data[0];
        setProfileAccount(data ? data.user_accounttype : "");
        setName(data ? data.user_name : "");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function compareBlocked(pathName) {
    let newPATH = "/" + pathName.split("/")[1];

    if (blockRoutes.indexOf(newPATH) === -1) {
      // DEVE EXIBIR MENU
      setShowHeader(true);
      document.querySelector(".main-content-wrapper").classList.remove("p-0");
      document
        .querySelector(".main-content-wrapper")
        .classList.remove("bg-transparent");
    } else {
      // NAO DEVE EXIBIR MENU
      setShowHeader(false);
      document.querySelector(".main-content-wrapper").classList.add("p-0");
      document
        .querySelector(".main-content-wrapper")
        .classList.add("bg-transparent");
    }
  }

  function toggleMenu(actualState) {
    const bodyOverflow = document.querySelector("body");

    setMenuState(actualState);

    if (menuState === true) {
      bodyOverflow.classList.add("menuOpened");
      bodyOverflow.classList.remove("menuClosed");
    } else {
      bodyOverflow.classList.remove("menuOpened");
      bodyOverflow.classList.add("menuClosed");
    }
  }

  function resetMenu() {
    const bodyOverflow = document.querySelector("body");

    setMenuState(true);
    bodyOverflow.classList.remove("menuClosed");
    bodyOverflow.classList.remove("menuOpened");
  }

  const logoutApp = () => {
    setMdlLogout((prev) => ({ ...prev, open: true }));
  };

  const clearAndLogout = () => {
    axios
      .post(`${API}/logout`, {
        user_hashcode: sessionStorage.getItem("token"),
      })
      .then(function (response) {
        localStorage.removeItem("hashcode");
        sessionStorage.removeItem("firebaseToken");
        sessionStorage.removeItem("token");
        window.location.href = "/";
      })
      .catch(function (error) {
        localStorage.removeItem("hashcode");
        sessionStorage.removeItem("firebaseToken");
        sessionStorage.removeItem("token");
        window.location.href = "/";
      });

  };

  const menuItems = () => {
    return (
      <ul className="menu-item-wrapper">
        {profileAccount === "c" ? (
          <li key="menu_dashboard" className={`flex menu-link`}>
            <NavLink to="/dashboard" title="Dashboard" activeClassName="active">
              <Icon name="dashboard" size={18}></Icon>
              <span className="ml-2">Acompanhar</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" ? (
          <li key="menu_dashboard" className={`flex menu-link`}>
            <NavLink
              to="/dashboard"
              title="Dashboard"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="dashboard" size={18}></Icon>
              <span className="ml-2">Oportunidades</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" ? (
          <li key="menu_investimentos" className={`flex menu-link`}>
            <NavLink
              to="/investimentos-realizados"
              title="Investimentos Realizados"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="chart-line" size={18}></Icon>
              <span className="ml-2">Investimentos Realizados</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_transacoes" className={`flex menu-link`}>
            <NavLink
              to="/transacoes"
              title="Extrato"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="history" size={18}></Icon>
              <span className="ml-2">Extrato</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_faq" className={`flex menu-link`}>
            <NavLink
              to="/faq"
              title="FAQ"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="question-circle" size={18}></Icon>
              <span className="ml-2">FAQ</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_conta" className={`flex menu-link`}>
            <NavLink
              to="/minha-conta"
              title="Configurações"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="user-avatar" size={18}></Icon>
              <span className="ml-2">Configurações</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_financeiro" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/dashboard"
              title="Painel"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="currency-up" size={18}></Icon>
              <span className="ml-2">Painel</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_processos" className={`flex menu-link `}>
            <NavLink
              to="/backoffice/lista-processos"
              title="Lista de Processos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="docs" size={18}></Icon>
              <span className="ml-2">Processos</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_usuarios" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/lista-usuarios"
              title="Lista de Usuários"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="user-avatar" size={18}></Icon>
              <span className="ml-2">Usuários</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_saques" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/saques"
              title="Saques"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="withdraw" size={18}></Icon>
              <span className="ml-2">Saques</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_investimentos" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/investimentos"
              title="Investimentos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="chart-line" size={18}></Icon>
              <span className="ml-2">Investimentos</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_depositos" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/depositos"
              title="Depósitos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="currency" size={18}></Icon>
              <span className="ml-2">Depósitos</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_recebimentos" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/recebimentos"
              title="Recebimentos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="currency" size={18}></Icon>
              <span className="ml-2">Recebimentos</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_configs" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/configuracoes"
              title="Configurações"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
            >
              <Icon name="configs" size={18}></Icon>
              <span className="ml-2">Configurações</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        <li key="menu_exit" className={`flex menu-link`}>
          <NavLink
            to="#"
            title="Sair"
            onClick={() => {
              logoutApp();
            }}
          >
            <Icon name="exit-app" size={22}></Icon>
            <span className="ml-2">Sair</span>
          </NavLink>
        </li>
      </ul>
    );
  };

  const menuItemsMobile = () => {
    return (
      <ul>
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_dashboard" className={`flex menu-link`}>
            <NavLink
              to="/dashboard"
              title="Dashboard"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Oportunidades
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" ? (
          <li key="menu_investimentos" className={`flex menu-link`}>
            <NavLink
              to="/investimentos-realizados"
              title="Investimentos Realizados"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Investimentos Realizados
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_transacoes" className={`flex menu-link`}>
            <NavLink
              to="/transacoes"
              title="Extrato"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Extrato
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_faq" className={`flex menu-link`}>
            <NavLink
              to="/faq"
              title="FAQ"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              F.A.Q
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "i" || profileAccount === "c" ? (
          <li key="menu_conta" className={`flex menu-link`}>
            <NavLink
              to="/minha-conta"
              title="Configurações"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Configurações
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_financeiro" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/dashboard"
              title="Painel"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Painel
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_processos" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/lista-processos"
              title="Lista de Processos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Lista de Processos
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_usuarios" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/lista-usuarios"
              title="Lista de Usuários"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Lista de Usuários
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_saques" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/saques"
              title="Saques"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Saques
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_depositos" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/depositos"
              title="Depósitos"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Depósitos
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {profileAccount === "b" ? (
          <li key="backoffice_configs" className={`flex menu-link`}>
            <NavLink
              to="/backoffice/configuracoes"
              title="Configurações"
              activeClassName="active"
              className={(isActive) => `menu-link ` + (!isActive ? "" : "")}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              Configurações
            </NavLink>
          </li>
        ) : (
          ""
        )}

        <li key="menu_exit" className={`flex menu-link`}>
          <NavLink
            to="#"
            title="Sair"
            onClick={() => {
              logoutApp();
            }}
          >
            Sair
          </NavLink>
        </li>
      </ul>
    );
  };

  return (
    <div className="wrapper-page" style={{ display: "block" }}>
      {showHeader && (
        <>
          <EndRegisterMessage />
          <header className="flex align-items-start menu-content-wrapper">
            <div className="menu-header">
              <div className="menu-user">
                <div className="flex avatar">
                  <NavLink
                    to="/dashboard"
                    title="Voltar ao Inicio"
                    onClick={() => {
                      resetMenu();
                    }}
                  >
                    <img src={AvatarImg} alt="Profile Pic" />
                  </NavLink>
                </div>
                <div className="flex justify-content-start flex-column d-none d-sm-flex">
                  <p className="name flex-0">{name.split(" ")[0]}</p>
                  <span className="profile flex-0">
                    {profileAccount === "c"
                      ? "Trabalhador"
                      : profileAccount === "i"
                      ? "Investidor"
                      : "Backoffice"}
                  </span>
                </div>
                <div className="d-flex flex justify-content-start d-sm-none ">
                  {menuState && (
                    <Button
                      className="menu-trigger only-icon p-button-icon-only"
                      onClick={() => {
                        toggleMenu(false);
                      }}
                    >
                      <Icon name="menu" size={18}></Icon>
                    </Button>
                  )}
                  {!menuState && (
                    <Button
                      className="menu-trigger only-icon p-button-icon-only"
                      onClick={() => {
                        toggleMenu(true);
                      }}
                    >
                      <Icon name="menu" size={18}></Icon>
                    </Button>
                  )}
                </div>
              </div>
              {menuItems()}
            </div>
            <div
              id="mobileItens"
              className="mobile-menu"
              data-collapse={menuState}
            >
              <div className="visual-side"></div>
              <div className="menu-mobile-items">{menuItemsMobile()}</div>
            </div>
          </header>
        </>
      )}
      <div style={{ display: "block" }}>
        <div className="main-content-wrapper">{children}</div>
      </div>
      <Dialog
        className="mdl-logout"
        modal
        blockScroll
        visible={mdlLogout.open}
        closable={false}
        showHeader={false}
        onHide={() => {}}
      >
        <h4 className="title text-center mt-4 mb-0">Saindo...</h4>
        <h6 className="lead text-center mb-3">
          Você está prestes a encerrar a sessão, deseja sair agora?
        </h6>
        <div className="p-d-flex p-ai-center p-jc-center">
          <Button
            className="p-button-text mr-1"
            label="Cancelar"
            onClick={() => {
              setMdlLogout((prev) => ({ ...prev, open: false }));
            }}
          />
          <Button
            label="Sair"
            icon="pi pi-power-off"
            onClick={() => {
              clearAndLogout();
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Sidebar;
