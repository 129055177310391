import React from "react";
import Disclaimer from "./components/Disclaimer";
import ButtonWpp from "./components/ButtonWpp/ButtonWpp";

import ReactDOM from "react-dom";
import "./styles/scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";

axios.interceptors.request.use(
  (request) => {
    if (
      !request.url.includes("login") &&
      !request.url.includes("savepassword")
    ) {
      request.headers["authorization"] = `Bearer ${sessionStorage.getItem(
        "token"
      )}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <ButtonWpp href="https://api.whatsapp.com/send?phone=+5511913593753"></ButtonWpp>
      <App />
      <Disclaimer />
    </HttpsRedirect>
    ,
  </Provider>,
  rootElement
);
serviceWorker.unregister();
