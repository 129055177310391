import React, { useState, useEffect } from "react";
import axios from "axios";
import Panel from "../../components/Panel";

import { Accordion, AccordionTab } from "primereact/accordion";
import API from "../../api";

import "./Faq.scss";

const Faq = () => {
  const [accType, setAccType] = useState();
  const [faqInvestidor] = useState([
    {
      q: "Qual tipo de Crédito pode ser negociado na JusCredit?",
      a: `
      <p>
        Os créditos negociados na plataforma são adquiridos diretamente com os seus titulares (proprietários), após
        criteriosa analise de elegibilidade.
      </p>
      <p>
        Para um crédito ser elegível, ele precisar atender os seguintes requisitos:
      </p>
      <ul class="list type-disc">
        <li>
          <p>Estar em <b>fase de execução</b>, com valor <b>mínimo de R$ 10 mil;</b></p>
        </li>
        <li>
          <p>Estimativa de <b>recebimento em até 18 meses;</b></p>
        </li>
        <li>
          <p><b>Deságio</b> mínimo de <b>20% a.a.;</b></p>
        </li>
        <li>
          <p>As empresas processadas devem possuir ao menos 12 meses de atividade no CNPJ/MF, <b>faturamento anual
          acima de R$ 6 milhões</b> e <b>não constar no  Banco Nacional de Devedores Trabalhistas.</b></p>
        </li>
        <li>
          <p>Excepcionalmente, poderão ser aceitos processos que não se enquadrem nos requisitos acima, desde que
          com <b>garantias judiciais (dinheiro depositado no processo, imóveis, móveis e outras tipos de garantia legal).</b></p>
        </li>
      </ul>
      `,
    },
    {
      q: "O que é a JusCredit?",
      a: `
      <p>
        Somos uma plataforma de intermediação de compra e venda de Créditos Judiciais entre pessoas que desejam
        obter liquidez sobre um processo judicial já ganho e investidores que querem rentabilidade e diversificação de
        portfólio. A nossa missão é <b>democratizar</b> os investimentos em créditos judiciais de maneira prática, ágil,
        rentável e segura.
      </p>
      `,
    },
    {
      q: "Como funciona o investimento pela JusCredit?",
      a: `
      <p>
        Fluxo de Investimento:
      </p>
      <ol class="list type-dec">
        <li>
          <p>Análise pela JusCredit: Escritório Parceiro e a Equipe da JusCredit analisam todos os aspectos do crédito;</p>
        </li>
        <li>
          <p>Proposta de Compra: A JusCredit realiza proposta pelo crédito aprovado;</p>
        </li>
        <li>
          <p>Formalização Jurídica: Com a aceitação da proposta, elaboramos o Instrumento de Cessão de Crédito e o
          pagamento do vendedor;</p>
        </li>
        <li>
          <p>Aberto para Investimento: Os créditos adquiridos são disponibilizados aos investidores para investimento
          a partir do valor de R$2.000,00.</p>
        </li>
      </ol>
      `,
    },
    {
      q: "Segurança Jurídica no investimento em Créditos Judiciais",
      a: `
      <p>
        O investimento em créditos judiciais é lícito e permitido pelo ordenamento jurídico brasileiro, 
        mantendo os créditos negociados a sua natureza jurídica, conforme prevê a legislação pátria. Vejamos.
      </p>
      <p class="mb-0" style="text-decoration: underline;"><b>Artigo 286 do Código Civil</b></p>
      <p>
        <b>O credor pode ceder o seu crédito</b>, se a isso não se opuser a natureza da obrigação, a lei, ou a
        convenção com o devedor.
      </p>
      <p class="mb-0" style="text-decoration: underline;"><b>Lei nº 14.112 (24/12/2020)</b></p>
      <p>
        <b>Manutenção da natureza do crédito</b>, ainda que este seja cedido a terceiros, ou seja, o crédito continuará
        preferencial na ordem de classificação.
      </p>
      <p class="mb-0" style="text-decoration: underline;"><b>Recurso Extraordinário 631.537 (21/05/2020)</b></p>
      <p>
        STF decidiu com repercussão geral que a cessão de crédito alimentício para terceiro
        <b>não implica alteração na natureza</b>.
      </p>
      `,
    },
    {
      q: "É seguro enviar meus documentos?",
      a: `
      <p>
        Sim. Seguimos as melhores práticas de segurança da informação e todas as informações/documentos enviados
        são tratados como sigilosos e utilizados apenas para fins de análise do crédito solicitado. Além disso,
        utilizamos em nossa operação os serviços do Google Cloud Platform – uma suíte de computação em nuvem
        oferecida pela Google, funcionando na mesma infraestrutura que a empresa usa para seus produtos dirigidos
        ao usuários, dentre eles o Buscador Google e o Youtube, o que garante a máxima segurança dos dados enviados
        a Plataforma e a estrita observância das melhores diretrizes de privacidade e segurança, como a Lei Geral
        de Proteção de Dados “LGPD”.
      </p>
      `,
    },
    {
      q: "Como funciona a tributação?",
      a: `
      <p>
        A tributação incidente é o imposto de renda sobre o ganho de capital, regulado no artigo 21 da Lei nº 8.981/95.
        A alíquota é de 15%* e incidirá apenas sobre o valor do ganho de capital auferido pelo investidor na operação,
        deduzidos eventuais custos de transação. É importante verificar que há isenção do imposto de renda caso o
        valor total dos créditos judiciais pagos ao investidor no mês do recebimento não exceda R$35.000,00.
      </p>
      <p><b>
        *As alíquotas do ganho de capital são progressivas, se iniciando em 15% sobre a parcela dos ganhos que não
        ultrapassar R$5.000.000,00, podendo chegar até 22,5% sobre a parcela dos ganhos que ultrapassar R$30.000.000,00.
      </b></p>
      `,
    },
    {
      q: "Qual é a taxa para investir?",
      a: `
      <p>
        A JusCredit não cobra tarifas de administração dos investidores, tampouco de custódia. Os investidores somente
        pagam tarifa pela performance do investimento, no valor de 20% sobre o lucro bruto.
      </p>
      `,
    },
    {
      q: "O Investimento na JusCredit possui garantia?",
      a: `
      <p>
        As operações possuem como garantia o patrimônio da empresa devedora dos créditos e, em alguns casos (por exemplo,
        débitos trabalhistas), o patrimônio de seus sócios. As operações podem, ainda, conter outros tipos de garantias
        como depósitos judiciais, bens móveis, imóveis, etc. que podem garantir o processo no todo ou em parte.
        Caso haja inadimplência, a JusCredit e/ou seus parceiros executarão a dívida e/ou suas garantias a fim de
        tentar receber a totalidade dos valores creditórios adquiridos.
      </p>
      `,
    },
    {
      q: "Qual é o risco de investir na JusCredit?",
      a: `
      <p>
        Os riscos do investimento em Créditos Judiciais são:
      </p>
      <ol class="list type-dec">
        <li>
          <p>
            Execução frustrada: Não conseguir cobrar o crédito adquirido por falta de bens do devedor aptos a satisfazer a dívida.
            Por exemplo, no caso de falência, recuperação judicial, dentre outros;
          </p>
        </li>
        <li>
          <p>
            Alterações legislativas e/ou judiciais: Podem impactar a rentabilidade e o prazo de pagamento dos créditos; e,
          </p>
        </li>
        <li>
          <p>
            Fraude: Como se trata de ativos sem custódia, há o risco de o vendedor negociar ilegalmente com terceiros.
          </p>
        </li>
      </ol>
      <p>
        Para evitar esses riscos a JusCredit realiza: due diligence jurídica; Seleção de créditos com garantia e/ou
        devedores elegíveis; Formalização digital do contrato de cessão. E quando cabível a plataforma transfere
        o processo aos cuidados do escritório parceiro.
      </p>
      <p>
        Incentivamos fortemente a diversificação dos seus investimentos e "pulverizar" sua carteira na maior quantidade
        possível de empresas. Vale lembrar que em caso de um evento de inadimplência, a equipe da JusCredit
        e/ou seus parceiros buscam judicialmente reaver o valor em aberto o mais rápido possível.
      </p>
      `,
    },
    {
      q: "Como eu declaro minha posição de investimento com a JusCredit?",
      a: `
      <p>
       Dentro da aba de Bens e Direitos, você deve declarar o seu saldo investido junto a JusCredit.</p>  <p> O preenchimento deve se dar no Grupo 04 – Aplicações e Investimentos; e, o Código 99 – Outras Aplicações e Investimentos.
        </p>  <p>Preencher o CNPJ da JusCredit e colocar o detalhe das suas operações no campo Discriminação.
       Preencher com a sua posição de saldos no ano de 2020 e 2021.
      </p>
      `,
    },
    {
      q: "Como eu declaro a minha rentabilidade com a JusCredit?",
      a: `
      <p>
       A rentabilidade do nosso investimento tem isenção em ganhos de até R$ 35 mil mensais.  </p>
        <p>Para isso, na aba, “Rendimentos Isentos e Não Tributados” selecione o código 05 e preencha o valor do seu ganho mensal.
      </p>
      `,
    },
  ]);
  const [faqCliente] = useState([
    {
      q: "A JusCredit é uma empresa de empréstimos?",
      a: `<p>
      A JusCredit não é uma instituição financeira a fim de fornecer empréstimos. Somos uma plataforma de
      intermediação de compra e venda de créditos judiciais e conectamos pessoas quedesejem antecipar
      processos judiciais ganhos com investidores.
    </p>`,
    },
    {
      q: "Como funciona o modelo de negócio da JusCredit?",
      a: `<p>
      O modelo de negócio da JusCredit conecta pessoas com processos ganhos com investidores, sendo a
      JusCredit pioneira neste segmento.
    </p>
    <p>
      A plataforma fica aberta 24 (vinte e quatro) horas por dia para a captação de capital/investimento
      para aquisição de créditos judiciais selecionados.
    </p>
    <p>
      Após esse processo, é realizada a assinatura do contrato e em poucos dias o dinheiro fica disponível
      na conta do vendedor do crédito judicial. O pagamento ao vendedor é realizado quando da formalização
      do contrato e o investidor recebe o seu capital investido tão logo o dinheiro retorne para a Plataforma.
    </p>`,
    },
    {
      q: "A JusCredit é confiável?",
      a: `<p>
      Sim, a JusCredit é uma empresa devidamente registrada e organizada sobre as Leis do Brasil.
    </p>`,
    },
    {
      q: "É seguro enviar meus documentos?",
      a: `<p>
      Sim. Seguimos as melhores práticas de segurança da informação e todas as informações/documentos
      enviados são tratados como sigilosos e utilizados apenas para fins de análise do crédito solicitado.
      Além disso, utilizamos em nossa operação os serviços do Google Cloud Platform – uma suíte de
      computação em nuvem oferecida pela Google, funcionando na mesma infraestrutura que a empresa usa
      para seus produtos dirigidos ao usuários, dentre eles o Buscador Google e o Youtube, o que garante
      a máxima segurança dos dados enviados a Plataforma e a estrita observância das melhores diretrizes
      de privacidade e segurança, como a Lei Geral de Proteção de Dados “LGPD”.
    </p>`,
    },
    {
      q: "Qual é o valor mínimo e máximo de crédito que posso ofertar na Plataforma?",
      a: `<p>
      O valor mínimo do crédito a ser transacionado através do nosso site pode ser de R$10.000,00.
      Contudo, não há valor máximo de crédito a ser ofertado.
    </p>`,
    },
    {
      q: "Quais são os critérios mínimos de elegibilidade para venda do meu crédito?",
      a: `<p>
      Para ser elegível, o crédito deve preencher as seguintes condições:
    </p>
    <ul class="list">
      <li>
        <p>A empresa deve possuir ao menos 12 (doze) meses de atividade no Cadastro Nacional de Pessoas Jurídicas do Ministério da Fazenda “CNPJ/MF”;</p>
      </li>
      <li>
        <p>Possuir faturamento anual superior a R$ 500.000,00 (quinhentos mil Reais);</p>
      </li>
      <li>
        <p>Enviar todos os documentos e informações solicitadas;</p>
      </li>
      <li>
        <p>A empresa deve estar fora do Banco Nacional de Devedores Trabalhistas “BNDT” ou, possuir Certidão Positiva com Efeitos de Negativa.</p>
      </li>
    </ul>
    <p>
      Fique tranquilo que toda essa análise de elegibilidade é feita pela Plataforma com base nos documentos e informações prestadas.
    </p>`,
    },
    {
      q: "Quais documentos são necessários para fazer uma solicitação de venda de crédito?",
      a: `<p>
      Para solicitar a venda do seu crédito é necessário cadastrar os seguintes documentos, em PDF, no site da JusCredit:
    </p>
    <ul class="list">
      <li>
        <p>Registro Geral “RG” e Cadastro de Pessoas Físicas do Ministério da Fazenda “CPF/MF” ou Carteira Nacional de Habilitação “CNH” – frente e verso;</p>
      </li>
      <li>
        <p>Comprovante de residência atualizado;</p>
      </li>
    </ul>`,
    },
    {
      q: "Qual prazo máximo para pagamento do crédito vendido?",
      a: `<p>
      O nosso prazo de pagamento é de 5 (cinco) dias úteis após a formalização judicial da venda,
      diretamente na conta corrente informada pelo vendedor.
    </p>`,
    },
  ]);

  useEffect(() => {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then((res) => setAccType(res.data[0].user_accounttype));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDash = (accType) => {
    switch (accType) {
      case "i":
        return (
          <Panel isPositive>
            <Accordion activeIndex={0}>
              {faqInvestidor.map((res, index) => {
                return (
                  <AccordionTab
                    key={"item-faq-invest-" + index}
                    header={
                      <>
                        <span className="text-primary light bold">{res.q}</span>
                      </>
                    }
                  >
                    <div dangerouslySetInnerHTML={{ __html: res.a }}></div>
                  </AccordionTab>
                );
              })}
            </Accordion>
          </Panel>
        );

      default:
        return (
          <Panel isPositive>
            <Accordion activeIndex={0}>
              {faqCliente.map((res, index) => {
                return (
                  <AccordionTab
                    key={"item-faq-invest-" + index}
                    header={
                      <>
                        <span className="text-primary light bold">{res.q}</span>
                      </>
                    }
                  >
                    <div dangerouslySetInnerHTML={{ __html: res.a }}></div>
                  </AccordionTab>
                );
              })}
            </Accordion>
          </Panel>
        );
    }
  };

  const titleByAccType = (accType) => {
    if (accType === "i") {
      return "Perguntas frequentes: Investidor";
    } else {
      return "Perguntas frequentes: Trabalhador";
    }
  };

  return (
    <div className="pb-4">
      <div className="mb-3">
        <h4 className="title">{titleByAccType(accType)}</h4>
      </div>
      <div className="accordion-faq">{renderDash(accType)}</div>
    </div>
  );
};

export default Faq;
