import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import API from "../../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import XLSX from "xlsx";
import { authenticateWithCustomToken } from "../../../auth";
import "./../../../styles/scss/Forms.scss";

import { BO_ListaProcessosService } from "./services/MainService";
import { parseValue } from "./../../../common/format-number";

const ListaProcessos = () => {
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [btnExport, setBtnExport] = useState({
    disabled: false,
    loading: false,
  });
  const dt = useRef(null);

  let history = useHistory();
  const pageService = new BO_ListaProcessosService();

  useEffect(() => {
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        pageService.getProcessos().then((data) => setTableData(data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
          />
        </span>
        <div>
          {tableData.length !== 0 && (
            <Button
              label="Exportar"
              disabled={btnExport.loading}
              style={{ fontSize: "1rem" }}
              icon="pi pi-file-excel"
              onClick={() => exportTable()}
            />
          )}
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label="Ver detalhes"
          className="p-button-primary"
          onClick={() => {
            goToDetail(rowData.idProcesso);
          }}
        ></Button>
      </React.Fragment>
    );
  };

  const idClienteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Vendedor</span>
        {rowData.idCliente}
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {rowData.data ? moment(rowData.data).format("L") : "Sem data"}
      </React.Fragment>
    );
  };

  const idProcessoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Processo</span>
        {rowData.idProcesso}
      </React.Fragment>
    );
  };

  const trabalhadorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Vendedor</span>
        {rowData.pessoaFisica}
      </React.Fragment>
    );
  };

  const valorProcessoClienteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido</span>
        {parseValue(rowData.valorProcessoCliente)}
      </React.Fragment>
    );
  };

  const valorAntecipadoBrutoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Bruto Antecipado</span>
        {parseValue(rowData.valorBrutoAntecipado)}
      </React.Fragment>
    );
  };

  const valorAntecipadoLiqBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Líquido Antecipado</span>
        {parseValue(rowData.valorLiqAntecipado)}
      </React.Fragment>
    );
  };

  // const valorTaxaBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <span className="p-column-title">Taxa do Vendedor</span>
  //       {parseValue(rowData.taxaTrabalhador)}
  //     </React.Fragment>
  //   );
  // }

  const etapaBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status do Processo</span>
        <span className={"status-badge status-" + rowData.status}>
          {rowData.etapa}
        </span>
      </React.Fragment>
    );
  };

  function goToDetail(id) {
    history.push("/backoffice/lista-processos/processo/" + id);
  }

  function exportTable() {
    setBtnExport((prev) => ({
      ...prev,
      loading: true,
    }));
    let data = [];
    let columns = Object.keys(tableData[0]);
    data.push(columns);
    tableData.forEach((value) => {
      var data_value = [];
      columns.forEach((i) => data_value.push(value[i]));
      data.push(data_value);
    });
    data[0] = [
      "Id Processo",
      "Data",
      "Id Status",
      "Status do Processo",
      "Id Vendedor",
      "Vendedor",
      "Valor Investido",
      "Valor Bruto Recebido",
      "Valor Líquido Recebido",
      "Taxa do Trabalhador",
    ];
    // console.log(data[0]);
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    XLSX.writeFile(new_workbook, "processos.xlsx");
    setBtnExport((prev) => ({
      ...prev,
      loading: false,
    }));
  }

  const header = renderHeader();

  return (
    <div>
      <div className="mb-4">
        <h4 className="title">Lista de Processos</h4>
      </div>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable
              ref={dt}
              value={tableData}
              resizableColumns={true}
              autoLayout={true}
              sortField="idProcesso"
              sortOrder={-1}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              globalFilter={globalFilter}
              paginator
              rows={50}
              emptyMessage="Nenhum dado foi encontrado"
              currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                field="idProcesso"
                header="Id Processo"
                body={idProcessoBodyTemplate}
                sortable
              />
              <Column
                field="data"
                header="Data"
                body={dateBodyTemplate}
                sortable
              />
              <Column
                field="etapa"
                header="Status do Processo"
                body={etapaBodyTemplate}
                sortable
              />
              <Column
                field="idCliente"
                header="Id Vendedor"
                body={idClienteBodyTemplate}
                sortable
              />
              <Column
                field="pessoaFisica"
                header="Vendedor"
                body={trabalhadorBodyTemplate}
                sortable
              />
              <Column
                field="valorProcessoCliente"
                header="Valor Investido"
                body={valorProcessoClienteBodyTemplate}
                sortable
              />
              <Column
                field="valorBrutoAntecipado"
                header="Valor Bruto Recebido"
                body={valorAntecipadoBrutoBodyTemplate}
                sortable
              />
              <Column
                field="valorLiqAntecipado"
                header="Valor Líquido Recebido"
                body={valorAntecipadoLiqBodyTemplate}
                sortable
              />
              {/* <Column field="taxaTrabalhador" header="Taxa do Vendedor" body={valorTaxaBodyTemplate} sortable /> */}
              <Column
                body={actionBodyTemplate}
                headerStyle={{ width: "10em", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListaProcessos;
