import React, { useState, useEffect } from "react";
import { matchPath } from "react-router";
import axios from "axios";
// import moment from 'moment';
import { notNullStr } from "../../../../../common/not-null";
import { addLocale, locale } from "primereact/api";
import { currencyFormatter } from "../../../../../common/br-currency";
import rules from "../../../../../common/validation-rules";

import Grid from "@material-ui/core/Grid";

import Panel from "../../../../../components/Panel";

import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
// import { Calendar } from 'primereact/calendar';
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import API from "../../../../../api";

const FieldsProcesso = (props) => {
  const [proccessID, setProccessID] = useState(null);
  const [formValues, setFormValues] = useState({
    // id: '',
    // user_id: '',
    // clientName: '',
    // proccess_status: '',
    // dateProccess: '',
    // cnpj: '',
    // proccessNumber: '',
    // // proccessMinValue: '',
    // companyName: '',
    // companyScore: '',
    // companyAbout: '',
    // proccessAbout: '',
    // totalValue: '',
    // clientValue: '',
    // bruteValue: '',
    // liqValue: '',
    // dateFinalization: '',
    // placeProccess: '',
    // typeProccess: '',
    // expectParc: '',
    // expectSpreadValue: '',
    // expectPrazo: '',
    // expectSpreadPeriod: '',
    // proccessPaidInvestors: false,

    label: "",
    downloadLabel: "",
    downloadLink: "",
  });
  const [configFees, setConfigFees] = useState({});
  const [listProccess, setListProccess] = useState([]);
  const [listTypeProccess, setListTypeProccess] = useState([]);
  const [listPaidInvestors, setListPaidInvestors] = useState([]);
  const [defaultValues] = useState({
    id: "",
    user_id: "",
    clientName: "",
    proccess_status: "",
    dateProccess: "",
    cnpj: "",
    proccessNumber: "",
    // proccessMinValue: '',
    companyName: "",
    companyScore: "",
    companyAbout: "",
    proccessAbout: "",
    totalValue: "",
    clientValue: "",
    bruteValue: "",
    liqValue: "",
    dateFinalization: "",
    placeProccess: "",
    typeProccess: "",
    expectParc: "",
    expectSpreadValue: "",
    expectPrazo: "",
    expectSpreadPeriod: "",
    proccessPaidInvestors: false,

    // label: '',
    // downloadLabel: '',
    // downloadLink: ''
  });

  const {
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const wtBruteValue = watch("bruteValue");

  addLocale("pt-br", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  locale("pt-br");

  useEffect(() => {
    getRouterID();
    setListProccess([
      // { value: '', label: 'Selecione um status' },
      { value: "0", label: "Pedido em analise" },
      { value: "1", label: "Proposta de antecipação" },
      { value: "7", label: "Assinatura Digital" },
      { value: "2", label: "Captação de investimentos" },
      // { value: '3', label: 'Envio de documentação' },
      // { value: '4', label: 'Anexando documento' },
      { value: "5", label: "Antecipação finalizada" },
      { value: "6", label: "Recusado" },
    ]);
    setListTypeProccess([
      // { value: '', label: 'Tipo de processo' },
      { value: "0", label: "Trabalhista" },
      { value: "1", label: "Cível" },
      { value: "2", label: "Honorário" },
    ]);
    setListPaidInvestors([
      { value: "0", label: "Investimento Finalizado" },
      { value: "1", label: "Pagamento Integral" },
      { value: "2", label: "Pagamento Parcelado" },
    ]);
  }, []);

  useEffect(() => {
    if (proccessID !== null) {
      getProccessInfo(proccessID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proccessID]);

  useEffect(() => {
    if (Object.keys(configFees).length > 0) {
      getLiqValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configFees, wtBruteValue]);

  // useEffect(() => {
  //   props.onFormValues(formValues);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formValues]);

  useEffect(() => {
    const subscription = watch((form) => {
      // setFormValues(prev => ({
      //   ...prev,
      //   form
      // }));
      props.onFormUpdate(form);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    props.onFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const getRouterID = () => {
    const pathName = window.location.pathname;
    const match = matchPath(pathName, {
      path: "/backoffice/lista-processos/processo/:id",
      exact: true,
      strict: false,
    });

    setProccessID(match.params.id);
  };

  const getProccessInfo = (proccessID) => {
    const reqProccess = axios.get(`${API}/proccess/${proccessID}`);
    const reqConfig = axios.get(`${API}/config`);

    axios.all([reqProccess, reqConfig]).then(
      axios.spread((...responses) => {
        const resProccess = responses[0].data[0];
        const resConfig = responses[1].data[0];

        setFormValues((prev) => ({
          ...prev,
          // id: resProccess.proccess_id ? resProccess.proccess_id : 0,
          // user_id: resProccess.user_user_id ? resProccess.user_user_id : '',
          // clientName: resProccess.user_name ? resProccess.user_name : '',
          // proccess_status: resProccess.proccess_status ? resProccess.proccess_status : '',
          // dateProccess: resProccess.proccess_date ? resProccess.proccess_date : '',
          // cnpj: resProccess.proccess_cnpj ? resProccess.proccess_cnpj : '',
          // proccessNumber: resProccess.proccess_number ? resProccess.proccess_number : '',
          // // proccessMinValue: resProccess.proccess_minvalue ? resProccess.proccess_minvalue : '',
          // companyName: resProccess.proccess_companyname ? resProccess.proccess_companyname : '',
          // companyScore: resProccess.proccess_companyscore ? resProccess.proccess_companyscore : '',
          // companyAbout: resProccess.proccess_companyabout ? resProccess.proccess_companyabout : '',
          // proccessAbout: resProccess.proccess_proccessabout ? resProccess.proccess_proccessabout : '',
          // totalValue: resProccess.proccess_totalvalue ? resProccess.proccess_totalvalue : '',
          // clientValue: resProccess.proccess_clientvalue ? resProccess.proccess_clientvalue : '',
          // bruteValue: resProccess.proccess_brutevalue ? resProccess.proccess_brutevalue : '',
          // dateFinalization: resProccess.proccess_datefinalization ? resProccess.proccess_datefinalization : '',
          // placeProccess: resProccess.proccess_place ? resProccess.proccess_place : '',
          // typeProccess: resProccess.proccess_type ? resProccess.proccess_type : '',
          // expectParc: resProccess.proccess_expectparcspread ? resProccess.proccess_expectparcspread : '',
          // expectSpreadValue: resProccess.proccess_expectvaluespread ? resProccess.proccess_expectvaluespread : '',
          // expectSpread: resProccess.proccess_expectspread ? resProccess.proccess_expectspread : '',
          // expectPrazo: resProccess.proccess_expecttermspread ? resProccess.proccess_expecttermspread : '',
          // expectSpreadPeriod: resProccess.proccess_expectperiodspread ? resProccess.proccess_expectperiodspread : '',
          // proccessPaidInvestors: +resProccess.proccess_paid_investors !== 0,

          label: resProccess.proccess_label,
          downloadLabel: resProccess.proccess_downloadlabel,
          downloadLink: resProccess.proccess_downloadlink,
        }));

        setValue("id", notNullStr(resProccess.proccess_id), {
          shouldValidate: true,
        });
        setValue("user_id", notNullStr(resProccess.user_user_id), {
          shouldValidate: true,
        });
        setValue("clientName", notNullStr(resProccess.user_name), {
          shouldValidate: true,
        });
        setValue("proccess_status", notNullStr(resProccess.proccess_status), {
          shouldValidate: true,
        });
        setValue("dateProccess", notNullStr(resProccess.proccess_date), {
          shouldValidate: true,
        });
        setValue("cnpj", notNullStr(resProccess.proccess_cnpj), {
          shouldValidate: true,
        });
        setValue("proccessNumber", notNullStr(resProccess.proccess_number), {
          shouldValidate: true,
        });
        setValue("companyName", notNullStr(resProccess.proccess_companyname), {
          shouldValidate: true,
        });
        setValue(
          "companyScore",
          notNullStr(resProccess.proccess_companyscore),
          { shouldValidate: true }
        );
        setValue(
          "companyAbout",
          notNullStr(resProccess.proccess_companyabout),
          { shouldValidate: true }
        );
        setValue(
          "proccessAbout",
          notNullStr(resProccess.proccess_proccessabout),
          { shouldValidate: true }
        );
        setValue("totalValue", notNullStr(resProccess.proccess_totalvalue), {
          shouldValidate: true,
        });
        setValue("clientValue", notNullStr(resProccess.proccess_clientvalue), {
          shouldValidate: true,
        });
        setValue("bruteValue", notNullStr(resProccess.proccess_brutevalue), {
          shouldValidate: true,
        });
        setValue(
          "dateFinalization",
          notNullStr(resProccess.proccess_datefinalization),
          { shouldValidate: true }
        );
        setValue("placeProccess", notNullStr(resProccess.proccess_place), {
          shouldValidate: true,
        });
        setValue("typeProccess", notNullStr(resProccess.proccess_type), {
          shouldValidate: true,
        });
        setValue(
          "expectParc",
          notNullStr(resProccess.proccess_expectparcspread),
          { shouldValidate: true }
        );
        setValue(
          "expectSpreadValue",
          notNullStr(resProccess.proccess_expectvaluespread),
          { shouldValidate: true }
        );
        setValue(
          "expectSpread",
          notNullStr(resProccess.proccess_expectspread),
          { shouldValidate: true }
        );
        setValue(
          "expectPrazo",
          notNullStr(resProccess.proccess_expecttermspread),
          { shouldValidate: true }
        );
        setValue(
          "expectSpreadPeriod",
          notNullStr(resProccess.proccess_expectperiodspread),
          { shouldValidate: true }
        );
        setValue(
          "proccessPaidInvestors",
          notNullStr(resProccess.proccess_paid_investors),
          { shouldValidate: true }
        );

        setConfigFees({
          clientFee: resConfig.configuration_feeclient
            ? resConfig.configuration_feeclient
            : "",
          investmentFee: resConfig.configuration_feeinvestment
            ? resConfig.configuration_feeinvestment
            : "",
          investorFee: resConfig.configuration_feeinvestor
            ? resConfig.configuration_feeinvestor
            : "",
          withdrawFee: resConfig.configuration_feewithdraw
            ? resConfig.configuration_feewithdraw
            : "",
        });
      })
    );
  };

  const getClientFee = () => {
    let clientFeeRS =
      parseFloat(wtBruteValue.replace("R$ ", "").replace(",", ".")) *
      (parseInt(configFees.clientFee) / 100);

    return clientFeeRS;
  };

  const getLiqValue = () => {
    let valorLiq = 0;
    if (
      wtBruteValue !== "" &&
      wtBruteValue !== undefined &&
      wtBruteValue !== null
    ) {
      valorLiq =
        parseFloat(wtBruteValue.replace("R$ ", "").replace(",", ".")) -
        getClientFee();
      console.log("Replace " + wtBruteValue.replace(",", "."));
      console.log("Client Fee: " + getClientFee());
      console.log("Valor Liquido" + valorLiq);
    }

    let valorLiqString = valorLiq;
    setFormValues((prev) => ({
      ...prev,
      liqValue: valorLiqString,
    }));
    setValue(
      "liqValue",
      valorLiqString.toLocaleString("pt-br", { minimumFractionDigits: 2 }),
      {
        shouldValidate: true,
      }
    );
  };

  const updateForm = (ev) => {
    const { name, type, value, checked } = ev.target;
    const blockedList = ["id", "clientName", "liqValue"];

    // eslint-disable-next-line array-callback-return
    if (!blockedList.includes(name)) {
      if (type === "checkbox") {
        setFormValues((prev) => ({
          ...prev,
          [name]: checked,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <Panel isPositive>
        <div>
          <Grid className="pt-2" container spacing={1}>
            <Grid item xs={4} md={3}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled
                      readOnly
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.id })}>
                  ID
                </label>
                {getFormErrorMessage("id")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  disabled
                  type="text"
                  name="id"
                  keyfilter={/[\d]/g}
                  value={formValues.id}
                  onChange={(e) => { updateForm(e) }} />
                <label>ID</label>
              </span> */}
            </Grid>
            <Grid item xs={8} md={5}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="clientName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled
                      readOnly
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.clientName })}>
                  Trabalhador
                </label>
                {getFormErrorMessage("clientName")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  disabled
                  type="text"
                  name="clientName"
                  keyfilter={/[\d]/g}
                  value={formValues.clientName}
                  onChange={(e) => { updateForm(e) }} />
                <label>Trabalhador</label>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="proccess_status"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listProccess}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Status do Processo</label>
              </span>
              {/* <span className="input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="proccess_status"
                  value={formValues.proccess_status}
                  options={listProccess}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                  placeholder="Status do processo"
                />
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="dateProccess"
                  control={control}
                  rules={{ pattern: rules.data.pattern }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      id={field.name}
                      {...field}
                      mask="99/99/9999"
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.dateProccess })}
                >
                  Data do Processo
                </label>
                {getFormErrorMessage("dateProccess")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <Calendar
                  style={{ width: '100%' }}
                  name="dateProccess"
                  dateFormat="dd/mm/yy"
                  mask="99/99/9999"
                  maxLength={10}
                  value={new Date(moment(formValues.dateProccess, 'DD/MM/YYYY').format('YYYY/MM/DD'))}
                  // value={formValues.dateProccess}
                  onChange={(e) => { updateForm(e) }}
                />
                <label>Data do processo</label>
                <p className="input-helper"><small>Preencher no formato dd/mm/aaaa</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.companyName })}
                >
                  Nome da Empresa
                </label>
                {getFormErrorMessage("companyName")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="companyName"
                  value={formValues.companyName}
                  onChange={(e) => { updateForm(e) }} />
                <label>Nome da empresa</label>
              </span> */}
            </Grid>
            <Grid item xs={12} md={2}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="companyScore"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      keyfilter={/[\d]/g}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.companyScore })}
                >
                  Score
                </label>
                {getFormErrorMessage("companyScore")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="companyScore"
                  keyfilter={/[\d]/g}
                  value={formValues.companyScore}
                  onChange={(e) => { updateForm(e) }} />
                <label>Score</label>
                <p className="input-helper"><small>Apenas números</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="companyAbout"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={5}
                      cols={47}
                      maxLength={200}
                      autoResize
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.companyAbout })}
                >
                  Sobre a empresa
                </label>
                <p className="input-helper">
                  <small>Máximo de 200 caracteres</small>
                </p>
                {getFormErrorMessage("companyAbout")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputTextarea
                  rows={5}
                  cols={47}
                  name="companyAbout"
                  maxLength={200}
                  value={formValues.companyAbout}
                  onChange={(e) => { updateForm(e) }}
                  autoResize
                />
                <label>Sobre a empresa</label>
                <p className="input-helper"><small>Máximo de 200 caracteres</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="proccessAbout"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={5}
                      cols={47}
                      maxLength={500}
                      autoResize
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.proccessAbout })}
                >
                  Sobre o processo
                </label>
                <p className="input-helper">
                  <small>Máximo de 500 caracteres</small>
                </p>
                {getFormErrorMessage("proccessAbout")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputTextarea
                  rows={5}
                  cols={47}
                  name="proccessAbout"
                  maxLength={500}
                  value={formValues.proccessAbout}
                  onChange={(e) => { updateForm(e) }}
                  autoResize
                />
                <label>Sobre o processo</label>
                <p className="input-helper"><small>Máximo de 500 caracteres</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="cnpj"
                  control={control}
                  rules={{ pattern: rules.cnpj.pattern }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      id={field.name}
                      {...field}
                      mask={"99.999.999/9999-99"}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.cnpj })}>
                  CNPJ
                </label>
                {getFormErrorMessage("cnpj")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="cnpj"
                  value={formValues.cnpj}
                  onChange={(e) => { updateForm(e) }} />
                <label>CNPJ</label>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="proccessNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputMask
                      id={field.name}
                      {...field}
                      mask={"9999999-99.9999.9.99.9999"}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.proccessNumber })}
                >
                  Número do Processo
                </label>
                {getFormErrorMessage("proccessNumber")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="proccessNumber"
                  value={formValues.proccessNumber}
                  onChange={(e) => { updateForm(e) }} />
                <label>Número do Processo</label>
              </span> */}
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="proccessMinValue"
                  value={formValues.proccessMinValue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Valor Mínimo para Investir</label>
                <p className="input-helper"><small>Apenas números</small></p>
              </span>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="totalValue"
                  control={control}
                  // rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.totalValue })}>
                  Valor Total
                </label>
                {getFormErrorMessage("totalValue")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="totalValue"
                  value={formValues.totalValue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Valor Total</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="clientValue"
                  control={control}
                  // rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.clientValue })}
                >
                  Valor do Trabalhador
                </label>
                {getFormErrorMessage("clientValue")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="clientValue"
                  value={formValues.clientValue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Valor do Trabalhador</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="bruteValue"
                  control={control}
                  rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.bruteValue })}>
                  Valor Bruto Antecipado
                </label>
                {getFormErrorMessage("bruteValue")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="bruteValue"
                  value={formValues.bruteValue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Valor Bruto Antecipado</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="liqValue"
                  control={control}
                  // rules={{ pattern: rules.brl.pattern }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      disabled
                      readOnly
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.liqValue })}>
                  Valor Líquido Antecipado
                </label>
                {getFormErrorMessage("liqValue")}
              </span>
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="dateFinalization"
                  control={control}
                  rules={{ pattern: rules.data.pattern }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      id={field.name}
                      {...field}
                      mask={"99/99/9999"}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.dateFinalization })}
                >
                  Data de finalização do investimento
                </label>
                {getFormErrorMessage("dateFinalization")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="dateFinalization"
                  value={formValues.dateFinalization}
                  onChange={(e) => { updateForm(e) }} />
                <label>Data de finalização do investimento</label>
                <p className="input-helper"><small>Preencher no formato dd/mm/aaaa</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="placeProccess"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.placeProccess })}
                >
                  Local do processo
                </label>
                {getFormErrorMessage("placeProccess")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="placeProccess"
                  value={formValues.placeProccess}
                  onChange={(e) => { updateForm(e) }} />
                <label>Local do processo</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="typeProccess"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listTypeProccess}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Tipo de Processo</label>
              </span>
              {/* <span className="input-wrapper" style={{ display: 'block' }}>
                <Dropdown
                  name="typeProccess"
                  value={formValues.typeProccess}
                  options={listTypeProccess}
                  onChange={(e) => { updateForm(e) }}
                  optionLabel="label"
                  placeholder="Tipo de processo"
                />
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="expectParc"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label className={classNames({ "p-error": errors.expectParc })}>
                  Expectativa de Parcelas
                </label>
                {getFormErrorMessage("expectParc")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="expectParc"
                  value={formValues.expectParc}
                  onChange={(e) => { updateForm(e) }} />
                <label>Expectativa de Parcelas</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="expectSpreadValue"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({
                    "p-error": errors.expectSpreadValue,
                  })}
                >
                  Expectativa de rentabilidade bruta
                </label>
                {getFormErrorMessage("expectSpreadValue")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="expectSpreadValue"
                  value={formValues.expectSpreadValue}
                  onChange={(e) => { updateForm(e) }} />
                <label>Expectativa de rentabilidade bruta</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="expectPrazo"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({ "p-error": errors.expectPrazo })}
                >
                  Expectativa do prazo total de recebimento (meses)
                </label>
                {getFormErrorMessage("expectPrazo")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="expectPrazo"
                  value={formValues.expectPrazo}
                  onChange={(e) => { updateForm(e) }} />
                <label>Expectativa do prazo total de recebimento (meses)</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={2}>
              <span className="p-float-label input-wrapper">
                <Controller
                  name="expectSpreadPeriod"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  className={classNames({
                    "p-error": errors.expectSpreadPeriod,
                  })}
                >
                  Periodo do spread
                </label>
                {getFormErrorMessage("expectSpreadPeriod")}
              </span>
              {/* <span className="p-float-label input-wrapper">
                <InputText
                  type="text"
                  name="expectSpreadPeriod"
                  value={formValues.expectSpreadPeriod}
                  onChange={(e) => { updateForm(e) }} />
                <label>Periodo do spread</label>
                <p className="input-helper"><small>Necessário preencher</small></p>
              </span> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <span
                className="p-float-label input-wrapper"
                style={{ display: "block" }}
              >
                <Controller
                  name="proccessPaidInvestors"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={listPaidInvestors}
                      optionLabel="label"
                    />
                  )}
                />
                <label>Investidores Pagos</label>
              </span>
              {/* <span className="p-field-checkbox input-wrapper">
                <Controller
                  name="proccessPaidInvestors"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      {...field}
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.checked)}
                      className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )} />
                <label htmlFor="proccessPaidInvestors" className={classNames({ 'p-error': errors.proccessPaidInvestors })}>Investidores pagos?</label>
                {getFormErrorMessage('proccessPaidInvestors')}
              </span> */}
              {/* <div className="p-field-checkbox input-checkbox">
                <Checkbox
                  inputId="binary"
                  name="proccessPaidInvestors"
                  checked={formValues.proccessPaidInvestors}
                  onChange={(e) => { updateForm(e) }}
                />
                <label htmlFor="binary">Investidores pagos?</label>
              </div> */}
            </Grid>
          </Grid>
        </div>
      </Panel>
    </>
  );
};

export default FieldsProcesso;
