import React, { useState, useEffect, useRef } from "react";
import { parseValue } from "./../../common/format-number";
import { storage } from "../../firebase";
import moment from "moment";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import MainHeading from "../../components/MainHeading";
import MiniModal from "../../components/MiniModal";
import Panel from "../../components/Panel";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Message } from "primereact/message";

import "./InvestimentosRealizados.scss";

import { InvestimentosRealizadosService } from "./services/MainService";
import API from "../../api";

const InvestimentosRealizados = () => {
  const investService = new InvestimentosRealizadosService();

  const [tableData, setTableData] = useState(null);
  const [chartProccessData, setChartProccessData] = useState({
    labels: [],
    datasets: [],
  });
  const [chartTotalJusData, setChartTotalJusData] = useState({
    labels: [],
    datasets: [],
  });
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [mdlInvestimentoDetalhe, setMdlInvestimentoDetalhe] = useState({
    openState: false,
    docUrl: "",
    idProcesso: null,
    infosProcesso: [],
  });
  const [updatesOfInvestment, setUpdatesOfInvestment] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [snacks, setSnacks] = useState({
    openState: false,
    severity: "",
    message: "",
  });
  const [maxValue, setMaxValue] = useState(null);
  // const [taxaRetorno, setTaxaRetorno] = useState(null);
  const dt = useRef(null);
  const proccessOpts = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ||
            0;
          var name = data.seller[tooltipItem.index] || "";
          var id = data.labels[tooltipItem.index] || "";

          var newLabel = `${id}: ${name} - ${parseValue(value)}`;
          // var newLabel = `${id}: ${parseValue(value)}`;

          return newLabel;
        },
      },
    },
    legend: {
      display: false,
    },
  };
  const investmentOpts = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ||
            0;
          var id = data.labels[tooltipItem.index] || "";

          if (id) {
            id += ": ";
          }
          id += parseValue(value);

          return id;
        },
      },
    },
    legend: {
      display: false,
      labels: {
        fontColor: "#495057",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#495057",
            fontStyle: 500,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#495057",
            fontStyle: 500,
            stepSize: maxValue / 5,
            callback: function (label, index, labels) {
              // return label / 1000 + 'K';
              return parseValue(label);
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (chartTotalJusData) {
      let arrChartData = chartTotalJusData.datasets[0]?.data;

      if (arrChartData) {
        let max = arrChartData[0];

        for (let i = 0; i < arrChartData.length; i++) {
          if (arrChartData[i] > max) max = arrChartData[i];
        }

        setMaxValue(max);
      }
    }
  }, [chartTotalJusData]);

  useEffect(() => {
    if (chartProccessData) {
      // console.log(chartProccessData)
    }
  }, [chartProccessData]);

  useEffect(() => {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        investService
          .getInvestimentosByUser(response.data[0].user_id)
          .then((data) => setTableData(data));
        callChartProccessData(response.data[0].user_id);
        callChartTotalJuscredit(response.data[0].user_id);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
          />
        </span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label="Ver detalhes"
          className="p-button-primary"
          onClick={() => {
            openDetalheInvestimento(rowData.idProcesso, rowData.percPedido);
            getAtualizacoesFromReq(rowData.idProcesso);
          }}
        ></Button>
      </React.Fragment>
    );
  };

  const idBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id/Número do Processo</span>
        {rowData.idProcesso}
      </React.Fragment>
    );
  };

  const userNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Nome do Vendedor</span>
        {rowData.userName}
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data do Investimento</span>
        <span>{moment(rowData.dataProcesso).format("L")}</span>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status do Processo</span>
        <span
          className={
            "status-badge invest-realizados-status-" + rowData.statusProcesso
          }
        >
          {rowData.status}
        </span>
      </React.Fragment>
    );
  };

  const investValueBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido</span>
        {parseValue(rowData.valorInvestido)}
      </React.Fragment>
    );
  };

  const receivedValueBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Líq. Recebido</span>
        {parseValue(rowData.valorRecebido)}
      </React.Fragment>
    );
  };

  function callChartProccessData(id) {
    investService
      .getChartInvestimentosByProcesso(id)
      .then((data) => setChartProccessData(data));
  }

  function callChartTotalJuscredit(id) {
    investService
      .getChartTotalJuscredit(id)
      .then((data) => setChartTotalJusData(data));
  }

  function openDetalheInvestimento(id, percPedido) {
    setDocUrl("");
    investService.getInvestimentoByID(id).then((data) => {
      let invData = data;
      invData = invData.map((item) => {
        item.perc =
          (item.proccess_investvalue * 100) /
          parseFloat(
            item.proccess_brutevalue !== null
              ? item.proccess_brutevalue.replace(".", "").replace(",", ".")
              : "0"
          );
        return item;
      });
      invData[0].perc_pedido = percPedido;
      console.log(invData);
      setMdlInvestimentoDetalhe({
        ...mdlInvestimentoDetalhe,
        openState: true,
        infosProcesso: invData,
        idProcesso: id,
      });
    });
    storage
      .ref(`PROCESSOS`)
      .child(id.toString())
      .listAll()
      .then((res) => {
        if (res.items.length > 0) {
          res.items.forEach(function (itemRef, index) {
            // All the items under listRef.
            var fullPath = itemRef
              .toString()
              .substring(itemRef.toString().lastIndexOf("/") + 1);
            var namePath = fullPath.split(".").slice(0, -1).join(".");
            var extensionPath = fullPath.split(".").slice(1).join(".");

            storage
              .ref(`PROCESSOS/${id.toString()}`)
              .child(namePath + "." + extensionPath)
              .getDownloadURL()
              .then((url) => {
                setDocUrl(url);
              });
          });
        } else {
          setDocUrl("#");
        }
      })
      .catch((error) => {
        console.log(error);
        setDocUrl("Nenhum anexo encontrado");
      });
  }

  function getAtualizacoesFromReq(id) {
    investService.getAtualizacoesProcesso(id).then((r) => {
      setUpdatesOfInvestment(r);
    });
  }

  function renderAtualizacoesProcesso(arr) {
    console.log(arr);
    if (arr.length > 0) {
      return arr.map((item, index) => {
        return (
          <div
            key={`update-item-` + index}
            className="flex align-items-start mb-1"
          >
            <h6 className="text-primary" style={{ flex: "initial" }}>
              {moment(item.data).format("L")}
            </h6>
            <span className="flex-0 mx-1">-</span>
            <h6 className="text-primary">
              <small>{item.text}</small>
            </h6>
          </div>
        );
      });
    } else {
      return (
        <div className="flex align-items-center mb-1">
          <h6 className="text-primary">Nenhuma atualização ainda...</h6>
        </div>
      );
    }
  }

  function closeModal() {
    setMdlInvestimentoDetalhe({ ...mdlInvestimentoDetalhe, openState: false });
  }

  function closeSnacks() {
    setSnacks({ ...snacks, openState: false });
  }

  const header = renderHeader();
  return (
    <div>
      <Snackbar
        open={snacks.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snacks.severity}
        >
          {snacks.message}
        </Alert>
      </Snackbar>
      <MainHeading></MainHeading>
      <div className="py-4">
        <h4 className="title mb-3">Investimentos Realizados</h4>
        <div className="mb-4">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <Panel isPositive style={{ minHeight: "auto" }}>
                <h5 className="title mb-3">Investimentos em andamento</h5>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {window.innerWidth < 768 && (
                      <Message
                        className="w-100 p-2 mb-2"
                        severity="info"
                        text="Clique no gráfico para ver os valores"
                      />
                    )}
                    {window.innerWidth > 768 && (
                      <Message
                        className="w-100 p-2 mb-2"
                        severity="info"
                        text="Passe o mouse sobre o gráfico para ver os valores"
                      />
                    )}
                  </Grid>
                  {/* <Grid item xs={12} sm={5}>
                    <div className="mb-2 destaqueTaxaMedia">
                      <label className="lead card-label">Taxa média de retorno</label>
                      <h6 className="text-primary mt-1">{'Não disponível'}</h6>
                    </div>
                  </Grid> */}
                </Grid>
                {chartProccessData.datasets.length === 0 && (
                  <div className="p-d-flex p-jc-center">
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: "2em" }}
                    ></i>
                  </div>
                )}
                {chartProccessData.datasets.length > 0 && (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Chart
                          type="pie"
                          width={"100%"}
                          data={chartProccessData}
                          options={proccessOpts}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={3}>
                        <div className="mb-2 destaqueTaxaMedia">
                          <label className="lead card-label">Taxa média de retorno</label>
                          <h6 className="text-primary mt-1">{'Não disponível'}</h6>
                        </div>
                        <div className="mb-2 destaqueCharts">
                          <label className="lead card-label">Investimentos em andamento</label>
                          <h6 className="text-primary mt-1">{'Não disponível'}</h6>
                        </div>
                        <div className="mb-2 destaqueCharts">
                          <label className="lead card-label">Total Recebido Líquido</label>
                          <h6 className="text-primary mt-1">{'Não disponível'}</h6>
                        </div>
                      </Grid> */}
                      <Grid item xs={12} sm={4}>
                        <div className="destaqueTaxaMedia">
                          <label className="lead card-label">
                            Taxa média de retorno ao ano
                          </label>
                          <h6 className="text-primary mt-1">
                            {(
                              (
                                parseFloat(
                                  chartProccessData.averageFee || "0"
                                ) * 100
                              ).toFixed(2) + "%"
                            ).toString()}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="destaqueCharts">
                          <label className="lead card-label">
                            Investimentos em andamento
                          </label>
                          <h6 className="text-primary mt-1">
                            {chartProccessData.onGoingInvestments.toString() ||
                              "0"}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="destaqueCharts">
                          <label className="lead card-label">
                            Total Recebido Líquido
                          </label>
                          <h6 className="text-primary mt-1">
                            {parseValue(
                              chartProccessData.totalLiqReceived.toString()
                            ) || "0"}
                          </h6>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Panel>
            </div>
            <div className="p-col-12 p-lg-6">
              <Panel isPositive className="pb-10" style={{ minHeight: "auto" }}>
                <h5 className="title mb-3">Patrimônio na JusCredit</h5>
                <div className="relativeChartTotalJus">
                  {chartProccessData.datasets.length === 0 && (
                    <div className="p-d-flex p-jc-center">
                      <i
                        className="pi pi-spin pi-spinner"
                        style={{ fontSize: "2em" }}
                      ></i>
                    </div>
                  )}
                  {chartProccessData.datasets.length > 0 && (
                    <Chart
                      id="chartTotalJus"
                      type="line"
                      data={chartTotalJusData}
                      options={investmentOpts}
                    />
                  )}
                </div>
              </Panel>
            </div>
          </div>
        </div>
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable
              ref={dt}
              value={tableData}
              sortField="dataProcesso"
              sortOrder={-1}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              globalFilter={globalFilter}
              selection={selectedCustomers}
              onSelectionChange={(e) => setSelectedCustomers(e.value)}
              paginator
              rows={50}
              emptyMessage="Nenhum dado foi encontrado"
              currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                field="idProcesso"
                header="Id/Número do Processo"
                body={idBodyTemplate}
                sortable
              />
              <Column
                field="userName"
                header="Nome do Vendedor"
                body={userNameBodyTemplate}
                sortable
              />
              <Column
                field="dataProcesso"
                header="Data do Investimento"
                body={dateBodyTemplate}
                sortable
              />
              <Column
                field="status"
                header="Status do Processo"
                body={statusBodyTemplate}
                sortable
              />
              <Column
                field="valorInvestido"
                header="Valor Investido"
                body={investValueBodyTemplate}
                sortable
              />
              <Column
                field="valorRecebido"
                header="Valor Líq. Recebido"
                body={receivedValueBodyTemplate}
                sortable
              />
              <Column
                body={actionBodyTemplate}
                headerStyle={{ width: "10em", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
      {mdlInvestimentoDetalhe.openState && (
        <MiniModal
          id="investimento-modal-detail"
          title="Detalhes do Investimento"
          openState={mdlInvestimentoDetalhe.openState}
          actionClose={() => {
            closeModal();
          }}
        >
          <Grid className="investment-card" container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className="mb-1">
                <h6 className="text-primary title">
                  Processo: {mdlInvestimentoDetalhe.idProcesso}
                </h6>
              </div>

              {mdlInvestimentoDetalhe.infosProcesso.map((item, index) => {
                return (
                  <div key={"info-processo-" + index}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <div className="mb-1">
                          <label className="lead card-label">Empresa</label>
                          <h6 className="text-primary">
                            {item.proccess_companyname}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="mb-1 mr-2">
                          <label className="lead card-label">
                            Número do processo
                          </label>
                          <h6 className="text-primary">
                            {item.proccess_number}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Valor do trabalhador
                          </label>
                          <h6 className="text-primary">
                            {parseValue(item.proccess_clientvalue)}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Valor da antecipação
                          </label>
                          <h6 className="text-primary">
                            {parseValue(item.proccess_brutevalue)}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Percentual do Investimento
                          </label>
                          <h6 className="text-primary">
                            {parseFloat(item?.perc).toFixed(2)} %
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Investidores
                          </label>
                          <h6 className="text-primary">
                            {item.proccess_investors}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Expectativa de rentabilidade bruta
                          </label>
                          <h6 className="text-primary">
                            {item.proccess_expectvaluespread}
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Perspectiva de prazo total de recebimento do
                            processo
                          </label>
                          <h6 className="text-primary">
                            {item.proccess_expecttermspread} meses
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="mb-1">
                          <label className="lead card-label">
                            Atualizações sobre o processo
                          </label>
                          <div className="mt-2">
                            {renderAtualizacoesProcesso(updatesOfInvestment)}
                          </div>
                          {/* <h6 className="text-primary">{item.proccess_updates ? item.proccess_updates : 'Nada para exibir'}</h6> */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
              <div className="flex justify-content-start mb-0 mt-2">
                {docUrl ? (
                  docUrl.length > 1 ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docUrl}
                      title="Baixar doc..."
                      className="button primary md"
                    >
                      Ver Documento
                    </a>
                  ) : (
                    <span>Nenhum documento encontrado</span>
                  )
                ) : (
                  <div>
                    <i
                      className="pi pi-spin pi-spinner text-primary"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </MiniModal>
      )}
    </div>
  );
};

export default InvestimentosRealizados;
