import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// import { useHistory } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';

const ListaAtualizacaoProcessoTable = (props) => {

  const [tableData, setTableData] = useState(props.dataSource);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    setTableData(props.dataSource)
  }, [props.dataSource]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
      </div>
    );
  }

  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" label="Ver detalhes" className="p-button-primary" onClick={() => { goToDetail(rowData.idProcesso) }}></Button>
  //     </React.Fragment>
  //   );
  // }

  const idTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID/Cod.</span>
        {rowData.idAtualizacao}
      </React.Fragment>
    );
  }

  const dataTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.data).format('L')}
      </React.Fragment>
    );
  }

  const descricaoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Descrição</span>
        {rowData.text}
      </React.Fragment>
    );
  }

  const header = renderHeader();

  return (
    <>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="idProcesso" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="idAtualizacao" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="idAtualizacao" header="ID/Cod." body={idTemplate} sortable />
              <Column field="data" header="Data" body={dataTemplate} sortable />
              <Column field="text" header="Descrição" body={descricaoTemplate} sortable />
              {/* <Column body={actionBodyTemplate} headerStyle={{ width: '10em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListaAtualizacaoProcessoTable;