import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import XLSX from 'xlsx';

import './../../../styles/scss/Forms.scss';

import { BO_RecebimentosService } from './services/MainService';
import { parseValue } from './../../../common/format-number';

const Recebimentos = () => {

  const pageService = new BO_RecebimentosService();

  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [mdlSaque, setMdlSaque] = useState({ openState: false, data: {} });
  const [snack, setSnack] = useState({ openState: false, severity: '', message: '' });
  const dt = useRef(null);

  useEffect(() => {
    pageService.getAllRecebimentos().then(res => setTableData(res));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" />
        </span>
        <div>
          {(tableData.length !== 0) && (
            <Button label="Exportar" style={{ fontSize: '1rem' }} icon="pi pi-file-excel" onClick={() => exportTable()} />
          )}
        </div>
      </div>
    );
  }

  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" label="Ver detalhes" className="p-button-primary" onClick={() => { console.log('goToDetail(rowData.id)') }}></Button>
  //     </React.Fragment>
  //   );
  // }

  const idProcessoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Processo</span>
        {rowData.idProcesso}
      </React.Fragment>
    );
  }

  const idUsuarioTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id Usuário</span>
        {rowData.idUsuario}
      </React.Fragment>
    );
  }

  const nomeUsuarioTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Nome</span>
        {rowData.nomeUsuario}
      </React.Fragment>
    );
  }

  const dataInvestimentoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data Investimento</span>
        {moment(rowData.dataInvestimento).format('L')}
      </React.Fragment>
    );
  }

  const dataRecebimentoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data Recebimento</span>
        {moment(rowData.dataRecebimento).format('L')}
      </React.Fragment>
    );
  }

  const irAplicadoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">IR Aplicado</span>
        {rowData.irAplicado}
      </React.Fragment>
    );
  }

  const valorInvestidoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Investido</span>
        {parseValue(rowData.valorInvestido)}
      </React.Fragment>
    );
  }

  const valorRecebidoBrutoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Recebido B.</span>
        {parseValue(rowData.valorRecebidoBruto)}
      </React.Fragment>
    );
  }

  const valorRecebidoLiqTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor Recebido L.</span>
        {parseValue(rowData.valorRecebidoLiq)}
      </React.Fragment>
    );
  }

  const irTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">IR</span>
        {rowData.ir}
      </React.Fragment>
    );
  }

  const taxaInvestidorTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Taxa Investidor</span>
        {parseValue(rowData.taxaInvestidor)}
      </React.Fragment>
    );
  }

  // function goToDetail(id, idUser) {
  //   pageService.getSaqueByID(id, idUser).then(data => {
  //     setMdlSaque({ ...mdlSaque, openState: true, data: data });
  //   })
  // }

  // function closeModal() {
  //   setMdlSaque({ ...mdlSaque, openState: false })
  // }

  function closeSnacks() {
    setSnack({ ...snack, openState: false });
  }

  function exportTable() {
    let data = []
    let columns = Object.keys(tableData[0])
    data.push(columns)
    tableData.forEach((value) => {
      var data_value = []
      columns.forEach((i) => data_value.push(value[i]))
      data.push(data_value)
    })
    data[0] = [
      "ID Processo",
      "ID Usuário",
      "Nome do Usuário",
      "Data do Investimento",
      "Data do Recebimento",
      "Valor Investido",
      "Valor Recebido Bruto",
      "Valor Recebido Liquido",
      "Taxa do Investidor",
    ]
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
    XLSX.writeFile(new_workbook, "recebimentos.xlsx");
  }

  const header = renderHeader();

  return (
    <div>
      <Snackbar open={snack.openState} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => { closeSnacks() }}>
        <Alert onClose={() => { closeSnacks() }} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
      <div className="mb-4">
        <h4 className="title">Recebimentos</h4>
      </div>
      <div className="pb-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable ref={dt} value={tableData} resizableColumns={true} autoLayout={true} sortField="dataRecebimento" sortOrder={-1}
              header={header} className="p-datatable-customers" dataKey="id" rowHover globalFilter={globalFilter}
              paginator rows={50} emptyMessage="Nenhum dado foi encontrado" currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25, 50]}>
              <Column field="idProcesso" header="Id Processo" body={idProcessoTemplate} sortable />
              <Column field="idUsuario" header="Id Usuário" body={idUsuarioTemplate} sortable />
              <Column field="nomeUsuario" header="Nome" body={nomeUsuarioTemplate} sortable />
              <Column field="dataInvestimento" header="Data Investimento" body={dataInvestimentoTemplate} sortable />
              <Column field="dataRecebimento" header="Data Recebimento" body={dataRecebimentoTemplate} sortable />
              <Column field="valorInvestido" header="Valor Investido" body={valorInvestidoTemplate} sortable />
              <Column field="valorRecebidoBruto" header="Valor Recebido B." body={valorRecebidoBrutoTemplate} sortable />
              <Column field="valorRecebidoLiq" header="Valor Recebido L." body={valorRecebidoLiqTemplate} sortable />
              <Column field="taxaInvestidor" header="Taxa Investidor" body={taxaInvestidorTemplate} sortable />
              {/* <Column body={actionBodyTemplate} headerStyle={{ width: '10em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recebimentos;