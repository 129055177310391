import React, { Component } from 'react';
import './../Stepper.scss';

class StepperFooter extends Component {
  render() {
    return (
      <div className="stepper-footer mt-3">
        {this.props.children}
      </div>
    );
  }
}

export default StepperFooter;