import React, { useState, useEffect, useRef } from "react";
import { parseValue } from "./../../common/format-number";
import { bankName } from "./../../common/bank-name";
import moment from "moment";
import axios from "axios";

import MainHeading from "../../components/MainHeading";
import MiniModal from "../../components/MiniModal";
import Icon from "../../components/Icon";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import "./HistoricoMovimentacoes.scss";

import { HistoricoTransacoesService } from "./services/MainService";
import API from "../../api";

const HistoricoMovimentacoes = () => {
  const [tableData, setTableData] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [mdlInvestimentoDetalhe, setMdlInvestimentoDetalhe] = useState({
    openState: false,
    infosProcesso: [],
  });
  const [snacks, setSnacks] = useState({
    openState: false,
    severity: "",
    message: "",
  });
  const dt = useRef(null);

  const historicoService = new HistoricoTransacoesService();

  useEffect(() => {
    axios
      .get(`${API}/user/${localStorage.getItem("hashcode")}`)
      .then(function (response) {
        let data = response.data[0];
        historicoService
          .getTransacoesRealizadas(data.user_id, data.user_accounttype)
          .then((data) => setTableData(data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => {
    return (
      <div className="table-header">
        <h4 className="title">Histórico de Transações</h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
          />
        </span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label="Ver detalhes"
          className="p-button-primary"
          onClick={() => {
            openDetalheInvestimento(rowData.idMovimentacao);
          }}
        ></Button>
      </React.Fragment>
    );
  };

  const idBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Id</span>
        {rowData.idMovimentacao}
      </React.Fragment>
    );
  };

  const metodoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Método</span>
        <span>{rowData.metodo}</span>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={"status-badge status-" + rowData.statusProcesso}>
          {rowData.status}
        </span>
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Data</span>
        {moment(rowData.dataMovimentacao).format("L")}
      </React.Fragment>
    );
  };

  const receivedValueBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Valor</span>
        <span
          className={rowData.trending ? rowData.trending : "lead"}
          style={{ display: "flex" }}
        >
          {rowData.trending && (
            <Icon name={rowData.trending} bgColor="mr-1" size={18}></Icon>
          )}
          {parseValue(rowData.valorMovimentacao)}
        </span>
        {/* {rowData.valorMovimentacao} */}
      </React.Fragment>
    );
  };

  function openDetalheInvestimento(id) {
    historicoService.getTransacaoByID(id).then((data) => {
      setMdlInvestimentoDetalhe({
        ...mdlInvestimentoDetalhe,
        openState: true,
        infosProcesso: data,
      });
    });
  }

  function closeModal() {
    setMdlInvestimentoDetalhe({ ...mdlInvestimentoDetalhe, openState: false });
  }

  function closeSnacks() {
    setSnacks({ ...snacks, openState: false });
  }

  const header = renderHeader();

  const getDetailModalTransaction = (item, metodo) => {
    console.log(item);
    switch (metodo) {
      case "Recebimento Líquido":
        return (
          <>
            <div className="mb-1">
              <label className="lead card-label">Recebimento Bruto</label>
              <h6 className="text-primary">{parseValue(item.gross_receipt)}</h6>
            </div>
            <div className="mb-1">
              <label className="lead card-label">Taxa do Investidor</label>
              <h6 className="text-primary">{parseValue(item.fee_investor)}</h6>
            </div>
          </>
        );

      case "Investimento":
        return (
          <>
            <div className="mb-1">
              <label className="lead card-label">ID do Processo</label>
              <h6 className="text-primary">{item.proccess_id.toString()}</h6>
            </div>
            <div className="mb-1">
              <label className="lead card-label">Número do Processo</label>
              <h6 className="text-primary">
                {item.proccess_number.toString()}
              </h6>
            </div>
          </>
        );

      case "Saque":
        return (
          <>
            <div className="mb-1">
              <label className="lead card-label">Banco</label>
              <h6 className="text-primary">{bankName(item.bank_code)}</h6>
            </div>
            <div className="mb-1">
              <label className="lead card-label">Agência</label>
              <h6 className="text-primary">{item.bank_agency}</h6>
            </div>
            <div className="mb-1">
              <label className="lead card-label">Conta</label>
              <h6 className="text-primary">{item.bank_account}</h6>
            </div>
          </>
        );

      case "Depósito":
        return <></>;

      default:
        return (
          <>
            <div className="mb-1">
              <h6 className="text-primary">
                Não identificado o tipo de transação
              </h6>
            </div>
          </>
        );
    }
  };

  const renderInvestidoresPagos = (status) => {
    switch (status) {
      case "1":
        return "Investimento Finalizado";
      case "2":
        return "Pagamento Parcelado";
      case "0":
        return "Pagamento Integral";
      default:
        return "Nenhuma Informação";
    }
  };

  return (
    <div>
      <Snackbar
        open={snacks.openState}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => {
          closeSnacks();
        }}
      >
        <Alert
          onClose={() => {
            closeSnacks();
          }}
          severity={snacks.severity}
        >
          {snacks.message}
        </Alert>
      </Snackbar>
      <MainHeading></MainHeading>
      <div className="py-4">
        <div className="custom-table">
          <div className="datatable-doc-demo">
            <DataTable
              ref={dt}
              value={tableData}
              sortField="idMovimentacao"
              sortOrder={-1}
              header={header}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              globalFilter={globalFilter}
              selection={selectedCustomers}
              onSelectionChange={(e) => setSelectedCustomers(e.value)}
              paginator
              rows={50}
              emptyMessage="Nenhum dado foi encontrado"
              currentPageReportTemplate="Mostrando {last} de {totalRecords} resultados"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                field="idMovimentacao"
                header="Id"
                body={idBodyTemplate}
                sortable
              />
              <Column
                field="metodo"
                header="Método"
                body={metodoBodyTemplate}
                sortable
              />
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
                sortable
              />
              <Column
                field="dataMovimentacao"
                header="Data"
                body={dateBodyTemplate}
                sortable
              />
              <Column
                field="valorMovimentacao"
                header="Valor"
                body={receivedValueBodyTemplate}
                sortable
              />
              <Column
                body={actionBodyTemplate}
                headerStyle={{ width: "10em", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
      {mdlInvestimentoDetalhe.openState && (
        <MiniModal
          id="transaction-modal-detail"
          title="Detalhes da Transação"
          openState={mdlInvestimentoDetalhe.openState}
          actionClose={() => {
            closeModal();
          }}
        >
          {mdlInvestimentoDetalhe.infosProcesso.map((item, index) => {
            return (
              <Grid
                key={"info-processo-" + index}
                className="investment-card"
                container
                spacing={2}
              >
                <Grid item xs={12} sm={5}>
                  <div className="mb-1">
                    <label className="lead card-label">ID / Método</label>
                    <h6 className="text-primary">
                      {item.transaction_id} - {item.transaction_desc}
                    </h6>
                  </div>
                  <div className="mb-1">
                    <label className="lead card-label">Data Início</label>
                    <h6 className="text-primary">
                      {item.transaction_registerdate != null
                        ? item.transaction_registerdate
                        : "Nenhuma informação"}
                    </h6>
                  </div>
                  <div className="mb-1">
                    <label className="lead card-label">Data Fim</label>
                    <h6 className="text-primary">
                      {item.transaction_finalizationdate != null
                        ? item.transaction_finalizationdate
                        : "Nenhuma informação"}
                    </h6>
                  </div>
                  <div className="mb-1">
                    <label className="lead card-label">
                      Investidores Pagos
                    </label>
                    <h6 className="text-primary">
                      {renderInvestidoresPagos(item.status)}
                    </h6>
                  </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <h5 className="title">Detalhes</h5>
                  {getDetailModalTransaction(item, item.transaction_desc)}
                </Grid>
              </Grid>
            );
          })}
        </MiniModal>
      )}
    </div>
  );
};

export default HistoricoMovimentacoes;
