import React from 'react';
import './Select.scss';


class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      stateClass: '',
      dataArr: this.props.options || []
    };
    this.handleChange.bind(this);
    this.handleBlur.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.options !== state.dataArr) {
      return {
        dataArr: props.options
      };
    }
    return null;
  }

  handleChange = event => {
    this.setState({ value: event.target.value, stateClass: 'has-value' }, () => {
      this.handleBlur();
      this.props.sideFunc(this.state.value);
    });
  }

  handleBlur = event => {
    const state = this.state;
    if (state.value !== '') {
      this.setState({ stateClass: 'has-value' })
    } else {
      this.setState({ stateClass: 'invalid-value' })
    }
  };

  render() {
    const props = this.props;
    const state = this.state;

    const displayOptions = this.state.dataArr.map((option, index) => {
      return (
        <option key={index} value={option.value}>{option.title}</option>
      )
    });

    return (
      <div className="input-wrapper">
        <div className="input-inner">
          <select
            className={state.stateClass}
            value={props.value ? props.value : state.value}
            name={props.name ? props.name : null}
            onKeyDown={props.onKeyDown}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          >
            {displayOptions}
          </select>
        </div>
        <p className="input-helper"><small>{props.helpText}</small></p>
      </div>
    )
  }
}

Select.defaultProps = {
  sideFunc: function () { }
}


export default Select;