import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_InvestimentosService {
  constructor() {
    moment.locale("pt-br");
  }

  getAllInvestimentos() {
    var mainData = [];
    return axios.get(`${API}/backoffice/investment`).then(function (response) {
      const dataAPI = response.data;

      console.log(dataAPI);
      mainData = dataAPI.map((item) => {
        return {
          idProcesso: item.proccess_id,
          idUsuario: item.user_id,
          nomeUsuario: item.user_name,
          dataInvestimento: item.investment_date
            ? moment(item.investment_date, "DD/MM/YYYY").format("YYYY/MM/DD")
            : null,
          dataRecebimento: item.receipt_date
            ? moment(item.receipt_date, "DD/MM/YYYY").format("YYYY/MM/DD")
            : null,
          valorInvestido: item.investment ? item.investment : 0,
          valorRecebidoBruto: item.receiptdetail_brute_value
            ? item.receiptdetail_brute_value
            : 0,
          valorRecebidoLiq: item.receiptdetail_liq_value
            ? item.receiptdetail_liq_value
            : 0,
          taxaInvestidor: item.receiptdetail_user_fee_value
            ? item.receiptdetail_user_fee_value
            : 0,
        };
      });

      return mainData;
    });
  }
}
