import React from "react";
import "./Disclaimer.scss";

class Disclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      stateClass: "",
    };
  }

  render() {
    return (
      <footer>
        <p className="lead">
          A JusCredit Tecnologia e Serviços S.A. “JusCredit”, pessoa jurídica de
          direito privado, inscrita no Cadastro Nacional de Pessoas Jurídicas do
          Ministério da Fazenda “CNPJ/MF” sob o nº 35.604.390/0001-93, com sede
          na Alameda Cores da Mata, 1973, Tamboré, na cidade de Santana de
          Parnaíba, Estado de São Paulo, CEP 06543-003, não é uma instituição
          financeira e não realiza operações de financiamento, investimentos ou
          quaisquer outras operações privativas de instituições financeiras.
          Tampouco solicitamos o pagamento de valores prévios para a análise de
          antecipação de direitos creditórios judiciais. Em caso de dúvidas, por
          favor, entre em contato conosco por meio do e-mail
          oi@juscredit.com.br.
        </p>
      </footer>
    );
  }
}

export default Disclaimer;
