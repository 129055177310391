import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_DepositosService {
  constructor() {
    moment.locale("pt-br");
  }

  getDepositos() {
    var mainData = [];
    return axios.get(`${API}/transaction/deposit`).then(function (response) {
      let dataAPI = response.data;

      mainData = dataAPI.map((depositos) => {
        return {
          idDeposito: depositos.transaction_id,
          idCliente: depositos.user_id,
          nomeCliente: depositos.user_name,
          data: moment(depositos.transaction_registerdate, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          ),
          valor: depositos.transaction_value,
        };
      });

      return mainData;
    });
  }
}
