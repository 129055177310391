import React, { Component } from "react";
import { storage } from "../../firebase";
import { uniqueId } from "lodash";
import axios from "axios";
import filesize from "filesize";
import Dropzone from "react-dropzone";
import update from "react-addons-update";

import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import Input from "../../components/Input";
import Button from "../../components/Button";
import Divider from "../../components/Divider";
import Select from "../../components/Select";
import StepperContainer from "../../components/Stepper";
import StepperFooter from "../../components/Stepper/StepperFooter";
import StepperHeader from "../../components/Stepper/StepperHeader";
import StepperItem from "../../components/Stepper/StepperItem";
import Checkbox from "../../components/Checkbox";
import Icon from "../../components/Icon";

import "./Cadastro.scss";
import "./../../styles/scss/Forms.scss";
import LoginImg from "./../../assets/img/media1.png";
import API from "../../api";
import  { authenticateWithCustomToken } from "../../auth";
class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      stepperItens: [],
      initialIndex: 0,
      isLastIndex: false,
      loadingFinalizar: false,
      nacionalidadeOptions: [
        { value: "", title: "Selecione sua nacionalidade" },
        { value: "brasileiro", title: "Brasileiro" },
        { value: "americano", title: "Estrangeiro" },
      ],
      estadoCivilOptions: [
        { value: "", title: "Selecione seu estado civil" },
        { value: "solteiro", title: "Solteiro" },
        { value: "uniaoEstavel", title: "União Estável" },
        { value: "casado", title: "Casado" },
        { value: "separadoJudicial", title: "Separado Judicialmente" },
        { value: "divorciado", title: "Divorciado" },
        { value: "viuvo", title: "Viúvo" },
      ],
      bankOptions: [
        { value: "", title: "Selecione seu banco" },
        { value: "1", title: "1 - Banco do Brasil" },
        { value: "33", title: "33 - Santander" },
        { value: "36", title: "36 - Banco Bradesco BBI S.A." },
        { value: "77", title: "77 - Banco Inter S.A." },
        { value: "104", title: "104 - Caixa Econômica Federal" },
        { value: "121", title: "121 - Banco Agibank S.A." },
        { value: "208", title: "208 - Banco BTG Pactual S.A." },
        { value: "212", title: "212 - Banco Original S.A." },
        { value: "237", title: "237 - Banco Bradesco S.A." },
        { value: "246", title: "246 - Banco ABC Brasil S.A." },
        { value: "260", title: "260 - NuBank" },
        { value: "318", title: "318 - Banco BMG S.A." },
        { value: "341", title: "341 - Itaú Unibanco S.A." },
        { value: "422", title: "422 - Banco Safra S.A" },
        { value: "748", title: "748 - Banco Cooperativo Sicredi S.A." },
        {
          value: "756",
          title: "756 - Banco Cooperativo do Brasil S.A. - BANCOOB",
        },
      ],
      person_type: "",
      formValues: {
        rg: "",
        nome_mae: "",
        data_nascimento: "",
        nacionalidade: "",

        profissao: "",
        renda_mensal: "",
        patrimonio_aproximado: "",
        endereco: {
          logradouroE: "",
          cep: "",
          numero: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          estado: "",
          complemento: "",
        },
        estado_civil: "",
        pessoa_politica: null,

        banco: "",
        agencia: "",
        conta_digito: "",

        cnpj: "",
        razao_social: "",
      },
      confirmarSenha: "",
      uploadedFiles: [],
      uploadedFilesCNPJ: [],
      countDown: 10,
    };
    this.goNextIndex = this.goNextIndex.bind(this);
    this.goPrevIndex = this.goPrevIndex.bind(this);
    this.updateFinished = this.updateFinished.bind(this);
    this.atualizaFormValues = this.atualizaFormValues.bind(this);
    this.atualizaCep = this.atualizaCep.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.uploadFilesCNPJ = this.uploadFilesCNPJ.bind(this);
    this.initStepper = this.initStepper.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    // this.renderStepPersonTypeJ = this.renderStepPersonTypeJ.bind(this);
  }

  componentDidMount() {
    let self = this;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    axios
      .get(`${API}/user/${urlParams.get("code")}`)
      .then(function (response) {
        self.setState({ user: response.data[0] });
        self.setState({ person_type: response.data[0].user_persontype }, () => {
          self.initStepper();
          // console.log(self.state)
        });
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ person_type: "j" }, () => {
          // console.log(self.state)
          self.initStepper();
        });
      })
      .then(() => {
        self.countdownToRedirect();
      });
  }

  countdownToRedirect() {
    const { user } = this.state;

    if (user?.user_rg) {
      var countIntervel = setInterval(() => {
        if (this.state.countDown === 0) {
          clearInterval(countIntervel);
          this.props.history.push("/");
        } else {
          this.setState({ countDown: this.state.countDown - 1 });
        }
      }, 1000);
    }
  }

  updateUser() {
    let self = this;
    const email = this.state.formValues.code;
    const password = this.state.formValues.password;
    this.setState({ loadingFinalizar: true });
    setTimeout(() => {
      axios
        .put(`${API}/user/1/completeregister`, this.state.formValues)
        .then(function (response) {
          let data = response.data;
          if (data.affectedRows > 0) {
            let hash = localStorage.getItem("hashcode");
            if (!hash) {
              // axios.post(`${API}/login?`, {
              //   email: email,
              //   password: password
              // })
              //   .then(function (response) {
              //     console.log(response.data);
              //     localStorage.setItem('hashcode', response.data[0].user_hashcode);
              //     self.props.history.push("/dashboard");
              //   })
              //   .catch(function (error) {
              //     console.log(error);
              //   });
              self.props.history.push("/login");
            } else {
              self.props.history.push("/dashboard");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      this.setState({ loadingFinalizar: false });
    }, 1000);
  }

  initStepper() {
    let itemsArr = [];
    this.setState({ stepperItens: [] });
    itemsArr = document.querySelectorAll(".stepper-item");
    for (let index = 0; index < itemsArr.length; index++) {
      this.setState((state) => {
        state.stepperItens.push({
          itemSection: `${itemsArr[index].attributes["name"].value}`,
          finished: false,
        });
      });
    }
  }

  goNextIndex() {
    const scrollContainer = document.querySelector(".stepper-item-wrapper");
    if (scrollContainer) {
      const scrollWidth =
        scrollContainer.scrollWidth / scrollContainer.children.length;
      scrollContainer.scrollLeft += scrollWidth;
      if (this.state.initialIndex < this.state.stepperItens.length - 1) {
        this.setState(
          {
            initialIndex: this.state.initialIndex + 1,
          },
          () => {
            this.updateFinished(this.state.initialIndex, "next");
            if (
              this.state.initialIndex ===
              this.state.stepperItens.length - 1
            ) {
              this.setState({ isLastIndex: true });
            }
          }
        );
      }
    }
    scrollContainer.scrollTop = 0;
  }

  goPrevIndex() {
    const scrollContainer = document.querySelector(".stepper-item-wrapper");
    if (this.state.initialIndex === 0) {
      this.props.history.goBack();
    } else {
      if (scrollContainer) {
        const scrollWidth =
          scrollContainer.scrollWidth / scrollContainer.children.length;
        scrollContainer.scrollLeft -= scrollWidth;
        if (this.state.initialIndex > 0) {
          this.setState(
            {
              initialIndex: this.state.initialIndex - 1,
              isLastIndex: false,
            },
            () => {
              this.updateFinished(this.state.initialIndex, "prev");
            }
          );
        }
      }
    }
  }

  updateFinished(i, dir) {
    if (dir === "next") {
      this.setState(
        update(this.state, {
          stepperItens: {
            [i - 1]: {
              $set: { ...this.state.stepperItens[i - 1], finished: true },
            },
          },
        })
      );
    } else {
      this.setState(
        update(this.state, {
          stepperItens: {
            [i]: {
              $set: { ...this.state.stepperItens[i], finished: false },
            },
          },
        })
      );
    }
  }

  atualizaFormValues = (target, value, formItem) => {
    if (formItem === "endereco") {
      if (target === "logradouroE") {
        this.setState(
          {
            formValues: {
              ...this.state.formValues,
              endereco: { ...this.state.formValues.endereco, [target]: value },
            },
          },
          () => {
            // console.log(this.state.formValues)
          }
        );
      } else {
        this.setState(
          {
            formValues: {
              ...this.state.formValues,
              endereco: {
                ...this.state.formValues.endereco,
                [target.toLowerCase()]: value,
              },
            },
          },
          () => {
            // console.log(this.state.formValues)
          }
        );
      }
    } else {
      this.setState({
        formValues: Object.assign(this.state.formValues, { [formItem]: value }),
      });
    }

    // this.setState({ formValues: Object.assign(this.state.formValues, { [formItem]: value }) }, () => {
    //   console.log(this.state.formValues);
    // });
  };

  atualizaCep = (value) => {
    var self = this;
    if (value.length > 7) {
      axios
        .get("https://viacep.com.br/ws/" + value + "/json/unicode/")
        .then(function (response) {
          if (response.status === 200) {
            // console.log(response);
            let respData = response.data;
            self.setState(
              {
                formValues: {
                  ...self.state.formValues,
                  endereco: {
                    ...self.state.formValues.endereco,
                    logradouroE: `${respData.logradouro} - ${respData.bairro}, ${respData.localidade} - ${respData.uf}`,
                    logradouro: `${respData.logradouro}`,
                    bairro: `${respData.bairro}`,
                    cidade: `${respData.localidade}`,
                    estado: `${respData.uf}`,
                  },
                },
              },
              () => {
                // console.log(self.state.formValues);
              }
            );
          }
        });
    }
  };

  testarSenha = (event) => {
    this.setState({ confirmarSenha: event });
  };

  removeUploadedFile(e, uploadedId, index) {
    var array = [...this.state.uploadedFiles]; // make a separate copy of the array
    array.splice(index, 1);
    this.setState(
      {
        uploadedFiles: array,
      },
      () => {
        // console.log(this.state.uploadedFiles);
      }
    );
  }

  removeUploadedFileCNPJ(e, uploadedId, index) {
    var array = [...this.state.uploadedFilesCNPJ]; // make a separate copy of the array
    array.splice(index, 1);
    this.setState({
      uploadedFilesCNPJ: array,
    });
  }

  uploadFiles(files) {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState(
      {
        uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
      },
      () => {
        // console.log(this.state.uploadedFiles);
      }
    );
  }

  uploadFilesCNPJ(files) {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFilesCNPJ: this.state.uploadedFilesCNPJ.concat(uploadedFiles),
    });
  }

  submitForm = () => {
    let self = this;
    const queryString = window.location.search;
    authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
    // console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    // console.log(urlParams.get('code'))

    // Primeiro os documentos são enviados pro storage
    // Depois a função updateUser() é chamada quando o processo dos documentos é finalizado
    const { uploadedFiles, uploadedFilesCNPJ, formValues } = this.state;
    let arrayToUp = uploadedFiles.concat(uploadedFilesCNPJ);
    for (let index = 0; index < arrayToUp.length; index++) {
      const uploadTask = storage
        .ref(`docsCADASTRO/${formValues.rg}/doc-cadastro${index}`)
        .put(arrayToUp[index].file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
        },
        (error) => {
          // error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          // storage.ref(`docsCADASTRO/${formValues.rg}`).child(`doc-cadastro${index}`).getDownloadURL().then(url => {
          //   // URL DA IMAGEM DO UPLOAD
          //   // console.log(url);
          // });

          self.setState(
            {
              formValues: Object.assign(self.state.formValues, {
                code: urlParams.get("code"),
              }),
            },
            () => {
              this.updateUser();
            }
          );
        }
      );
    }
  };

  isDisabled = () => {
    const { formValues, uploadedFiles, uploadedFilesCNPJ } = this.state;

    if (this.state.person_type === "f") {
      if (this.state.initialIndex === 0) {
        const buttonIsEnabled =
          formValues.rg.length > 3 &&
          formValues.estado_civil.length > 0 &&
          formValues.nome_mae.length > 3 &&
          formValues.data_nascimento.length > 0 &&
          formValues.nacionalidade.length > 0;

        return !buttonIsEnabled;
      } else if (this.state.initialIndex === 1) {
        const buttonIsEnabled =
          formValues.endereco.cep.length > 3 &&
          formValues.endereco.numero.length > 0 &&
          formValues.endereco.logradouroE.length > 3 &&
          formValues.pessoa_politica !== null;

        return !buttonIsEnabled;
      } else {
        const buttonIsEnabled =
          formValues.banco.length > 0 &&
          formValues.agencia.length > 2 &&
          formValues.conta_digito.length > 2 &&
          uploadedFiles.length === 2;

        return !buttonIsEnabled;
      }
    } else {
      if (this.state.initialIndex === 0) {
        const buttonIsEnabled =
          formValues.rg.length > 3 &&
          formValues.estado_civil.length > 0 &&
          formValues.nome_mae.length > 3 &&
          formValues.data_nascimento.length > 0 &&
          formValues.nacionalidade.length > 0;

        return !buttonIsEnabled;
      } else if (this.state.initialIndex === 1) {
        const buttonIsEnabled =
          formValues.endereco.cep.length > 3 &&
          formValues.endereco.numero.length > 0 &&
          formValues.endereco.logradouroE.length > 3 &&
          formValues.pessoa_politica !== null;

        return !buttonIsEnabled;
      } else if (this.state.initialIndex === 2) {
        const buttonIsEnabled =
          formValues.banco.length > 0 &&
          formValues.agencia.length > 2 &&
          formValues.conta_digito.length > 2 &&
          uploadedFiles.length === 2;

        return !buttonIsEnabled;
      } else {
        const buttonIsEnabled =
          formValues.cnpj.length > 10 &&
          formValues.razao_social.length > 3 &&
          uploadedFilesCNPJ.length === 2;

        return !buttonIsEnabled;
      }
    }
  };

  render() {
    const { user } = this.state;

    const fileList = this.state.uploadedFiles.map((uploadedFile, index) => {
      return (
        <li key={`uploadedFile-${uploadedFile.id}`} className="drop-file-item">
          <div className="drop-file-item-inner">
            {uploadedFile.file.type !== "application/pdf" ? (
              <img src={uploadedFile.preview} alt="Prévia da lista de upload" />
            ) : (
              ""
            )}
            <strong>{uploadedFile.name}</strong>
          </div>
          <strong style={{ display: "flex", alignItems: "center" }}>
            {uploadedFile.readableSize}
            <Button
              level="primary"
              size="only-icon"
              onClick={(e) => {
                this.removeUploadedFile(e, uploadedFile.id, index);
              }}
            >
              <Icon name="close" size={18}></Icon>
            </Button>
          </strong>
        </li>
      );
    });

    const fileListCNPJ = this.state.uploadedFilesCNPJ.map(
      (uploadedFile, index) => {
        return (
          <li
            key={`uploadedFile-${uploadedFile.id}`}
            className="drop-file-item"
          >
            <div className="drop-file-item-inner">
              {uploadedFile.file.type !== "application/pdf" ? (
                <img
                  src={uploadedFile.preview}
                  alt="Prévia da lista de upload"
                />
              ) : (
                ""
              )}
              <strong>{uploadedFile.name}</strong>
            </div>
            <strong style={{ display: "flex", alignItems: "center" }}>
              {uploadedFile.readableSize}
              <Button
                level="primary"
                size="only-icon"
                onClick={(e) => {
                  this.removeUploadedFileCNPJ(e, uploadedFile.id, index);
                }}
              >
                <Icon name="close" size={18}></Icon>
              </Button>
            </strong>
          </li>
        );
      }
    );

    const renderDragMessage = (isDragActive, isDragReject, personType) => {
      if (!isDragActive) {
        return (
          <div style={{ minWidth: "350px" }}>
            <Icon name="cloud" size={34}></Icon>
            <p className="lead">Por favor, anexe 2 documentos diferentes</p>
            <p className="lead">conforme a lista abaixo:</p>
            <ul className="my-2" style={{ listStyle: "inside" }}>
              <li className="lead">RG ou CNH</li>
              <li className="lead">Comprovante de residência</li>
            </ul>
            <p className="drop-text">
              Arraste seus arquivos ou clique com o mouse
            </p>
          </div>
        );
      }

      if (isDragReject) {
        return (
          <div style={{ minWidth: "350px" }}>
            <Icon name="cloud" size={34}></Icon>
            <p className="lead">Por favor, anexe 2 documentos diferentes</p>
            <p className="lead">conforme a lista abaixo:</p>
            <ul className="my-2" style={{ listStyle: "inside" }}>
              <li className="lead">RG ou CNH</li>
              <li className="lead">Comprovante de residência</li>
            </ul>
            <p className="drop-text drop-text-error">Arquivo não suportado</p>
          </div>
        );
      }

      return (
        <div style={{ minWidth: "350px" }}>
          <Icon name="cloud" size={34}></Icon>
          <p className="lead">Por favor, anexe 2 documentos diferentes</p>
          <p className="lead">conforme a lista abaixo:</p>
          <ul className="my-2" style={{ listStyle: "inside" }}>
            <li className="lead">RG ou CNH</li>
            <li className="lead">Comprovante de residência</li>
          </ul>
          <p className="drop-text">Solte os Arquivos aqui</p>
        </div>
      );
    };

    const renderDragMessageCNPJ = (isDragActive, isDragReject, personType) => {
      if (!isDragActive) {
        return (
          <div style={{ minWidth: "350px" }}>
            <Icon name="cloud" size={34}></Icon>
            <p className="lead">Por favor, anexe 2 documentos diferentes</p>
            <p className="lead">conforme a lista abaixo:</p>
            <ul className="my-2" style={{ listStyle: "inside" }}>
              <li className="lead">Contrato social (última atualização)</li>
              <li className="lead">Cartão CNPJ</li>
            </ul>
            <p className="drop-text">
              Arraste seus arquivos ou clique com o mouse
            </p>
          </div>
        );
      }

      if (isDragReject) {
        return (
          <div style={{ minWidth: "350px" }}>
            <Icon name="cloud" size={34}></Icon>
            <p className="lead">Por favor, anexe 2 documentos diferentes</p>
            <p className="lead">conforme a lista abaixo:</p>
            <ul className="my-2" style={{ listStyle: "inside" }}>
              <li className="lead">Contrato social (última atualização)</li>
              <li className="lead">Cartão CNPJ</li>
            </ul>
            <p className="drop-text drop-text-error">Arquivo não suportado</p>
          </div>
        );
      }

      return (
        <div style={{ minWidth: "350px" }}>
          <Icon name="cloud" size={34}></Icon>
          <p className="lead">Por favor, anexe 2 documentos diferentes</p>
          <p className="lead">conforme a lista abaixo:</p>
          <ul className="my-2" style={{ listStyle: "inside" }}>
            <li className="lead">Contrato social (última atualização)</li>
            <li className="lead">Cartão CNPJ</li>
          </ul>
          <p className="drop-text">Solte os Arquivos aqui</p>
        </div>
      );
    };

    if (user?.user_rg) {
      return (
        <div className="flex wrapper-page">
          <div className="col-1 d-none d-sm-block">
            <img src={LoginImg} className="side-img-cad" alt="Imagem" />
          </div>
          <div className="col-2">
            <div>
              <div
                className="px-2 py-4 pl-sm-4 pr-sm-5"
                style={{ maxWidth: "660px" }}
              >
                <h1 className="title">Cadastro já finalizado</h1>
                <Divider className="my-4" height="1" />
                <p className="lead">
                  Parece que você está tentando finalizar seu cadastro
                  novamente.
                </p>
                <p className="lead mb-2">
                  Se está tentando alterar algum dado que não aparece em suas
                  configurações, entre em contato com a Juscredit.
                </p>
                <p className="lead mb-2">
                  Você será redirecionado em{" "}
                  <b className="text-primary">
                    {this.state.countDown} segundos
                  </b>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.person_type === "f") {
      return (
        <div className="flex wrapper-page">
          <div className="col-1 d-none d-sm-block">
            <img src={LoginImg} className="side-img-cad" alt="Imagem" />
          </div>
          <div className="col-2">
            <div>
              <div
                className="px-2 py-4 pl-sm-4 pr-sm-5"
                style={{ maxWidth: "660px" }}
              >
                <div className="mb-4">
                  <h1 className="title">Informações pessoais</h1>
                  <p className="lead mb-2">
                    Insira suas informações e seus dados.
                  </p>
                  <Divider height="1" />
                </div>
                {this.state.person_type !== "" ? (
                  <StepperContainer>
                    <StepperHeader
                      dados={this.state.stepperItens}
                      actualItem={this.state.initialIndex}
                    ></StepperHeader>

                    <div className="stepper-item-wrapper">
                      <StepperItem id={0} slideName="Informações Pessoais">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={12}>
                            <Input
                              label="RG"
                              type="text"
                              // regex="[0-9]*"
                              helpText="Preencher apenas com números"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "rg");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Select
                                options={this.state.estadoCivilOptions}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 9 || e.which === 9)
                                    e.preventDefault();
                                }}
                                sideFunc={(e) => {
                                  this.atualizaFormValues(
                                    null,
                                    e,
                                    "estado_civil"
                                  );
                                }}
                              ></Select>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Nome da mãe"
                              regex="[a-zA-Zà-úÀ-Ú ]*"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "nome_mae");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <Input
                              label="Data Nascimento"
                              type="text"
                              mask="'alias': 'datetime', 'inputFormat': 'dd/mm/yyyy', 'placeholder': 'dd/mm/aaaa'"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "data_nascimento"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Select
                              options={this.state.nacionalidadeOptions}
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.which === 9)
                                  e.preventDefault();
                              }}
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "nacionalidade"
                                );
                              }}
                            ></Select>
                          </Grid>
                        </Grid>
                      </StepperItem>

                      <StepperItem id={1} slideName="Dados Bancários">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={6} md={6}>
                            <Input
                              label="CEP"
                              type="text"
                              regex="[0-9]*"
                              sideFunc={(e) => {
                                this.atualizaFormValues("cep", e, "endereco");
                                this.atualizaCep(e);
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Input
                              label="Endereço"
                              value={
                                this.state.formValues.endereco.logradouroE || ""
                              }
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "logradouroE",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Input
                              label="Número do Endereço"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "numero",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Complemento"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "complemento",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              <h6 className="text-primary">
                                É considerada uma pessoa politicamente exposta?
                              </h6>
                              <div className="flex align-items-center justify-content-start">
                                <Checkbox
                                  group="pessoa_politica"
                                  id="0"
                                  sendValue={true}
                                  helpText="Sim"
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9 || e.which === 9)
                                      e.preventDefault();
                                  }}
                                  sideFunc={(e) => {
                                    this.atualizaFormValues(
                                      null,
                                      e,
                                      "pessoa_politica"
                                    );
                                  }}
                                ></Checkbox>
                                <Checkbox
                                  group="pessoa_politica"
                                  id="1"
                                  sendValue={false}
                                  helpText="Não"
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9 || e.which === 9)
                                      e.preventDefault();
                                  }}
                                  sideFunc={(e) => {
                                    this.atualizaFormValues(
                                      null,
                                      e,
                                      "pessoa_politica"
                                    );
                                  }}
                                ></Checkbox>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </StepperItem>

                      <StepperItem id={2} slideName="Nova Senha">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={12}>
                            <p className="lead text-center mb-3">
                              Conta bancária deve ser da mesma titularidade
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              options={this.state.bankOptions}
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "banco");
                              }}
                            ></Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Agência (sem Dígito)"
                              type="text"
                              // regex="[0-9]*"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "agencia");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Conta (com Dígito)"
                              type="text"
                              // regex="[0-9]*"
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.which === 9)
                                  e.preventDefault();
                              }}
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "conta_digito"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Dropzone onDrop={this.uploadFiles}>
                              {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                                isDragReject,
                              }) => (
                                <section
                                  className={`drop-container ${
                                    isDragActive ? "drop-success" : ""
                                  } ${isDragReject ? "drop-error" : ""}`}
                                >
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    {renderDragMessage(
                                      isDragActive,
                                      isDragReject,
                                      this.state.person_type
                                    )}
                                  </div>
                                  {!!this.state.uploadedFiles.length &&
                                    fileList}
                                </section>
                              )}
                            </Dropzone>
                          </Grid>
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              Anexe os 2 documentos pedidos
                            </Alert>
                          </Grid>
                        </Grid>
                      </StepperItem>
                    </div>
                    <StepperFooter>
                      <div className="start">
                        <Button
                          level="transparent"
                          size="lg"
                          label="Voltar"
                          onClick={this.goPrevIndex}
                        ></Button>
                      </div>
                      {/* <div className={`end`}>
                    <Button loading={this.state.loadingFinalizar} level="primary" size="lg" label="Finalizar" onClick={this.submitForm}></Button>
                  </div> */}
                      {this.state.isLastIndex !== true ? (
                        <div className={`end`}>
                          <Button
                            disabled={this.isDisabled()}
                            level="primary"
                            size="lg"
                            label="Continuar"
                            onClick={this.goNextIndex}
                          ></Button>
                        </div>
                      ) : (
                        <div className={`end`}>
                          <Button
                            loading={this.state.loadingFinalizar}
                            disabled={this.isDisabled()}
                            level="primary"
                            size="lg"
                            label="Finalizar"
                            onClick={this.submitForm}
                          ></Button>
                        </div>
                      )}
                    </StepperFooter>
                  </StepperContainer>
                ) : (
                  <p className="lead">Aguardando informações...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex wrapper-page">
          <div className="col-1 d-none d-sm-block">
            <img src={LoginImg} className="side-img-cad" alt="Imagem" />
          </div>
          <div className="col-2">
            <div>
              <div
                className="px-2 py-4 pl-sm-4 pr-sm-5"
                style={{ maxWidth: "660px" }}
              >
                <div className="mb-4">
                  <h1 className="title">Informações pessoais</h1>
                  <p className="lead mb-2">
                    Insira suas informações e seus dados.
                  </p>
                  <Divider height="1" />
                </div>
                {this.state.person_type !== "" ? (
                  <StepperContainer>
                    <StepperHeader
                      dados={this.state.stepperItens}
                      actualItem={this.state.initialIndex}
                    ></StepperHeader>

                    <div className="stepper-item-wrapper">
                      <StepperItem id={0} slideName="Informações Pessoais">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={12}>
                            <Input
                              label="RG"
                              type="text"
                              // regex="[0-9]*"
                              helpText="Preencher apenas com números"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "rg");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Select
                                options={this.state.estadoCivilOptions}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 9 || e.which === 9)
                                    e.preventDefault();
                                }}
                                sideFunc={(e) => {
                                  this.atualizaFormValues(
                                    null,
                                    e,
                                    "estado_civil"
                                  );
                                }}
                              ></Select>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Nome da mãe"
                              regex="[a-zA-Zà-úÀ-Ú ]*"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "nome_mae");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <Input
                              label="Data Nascimento"
                              type="text"
                              mask="'alias': 'datetime', 'inputFormat': 'dd/mm/yyyy', 'placeholder': 'dd/mm/aaaa'"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "data_nascimento"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Select
                              options={this.state.nacionalidadeOptions}
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.which === 9)
                                  e.preventDefault();
                              }}
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "nacionalidade"
                                );
                              }}
                            ></Select>
                          </Grid>
                        </Grid>
                      </StepperItem>

                      <StepperItem id={1} slideName="Dados Bancários">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={6}>
                            <Input
                              label="CEP"
                              type="text"
                              regex="[0-9]*"
                              sideFunc={(e) => {
                                this.atualizaFormValues("cep", e, "endereco");
                                this.atualizaCep(e);
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Input
                              label="Endereço"
                              value={
                                this.state.formValues.endereco.logradouroE || ""
                              }
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "logradouroE",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Input
                              label="Número do Endereço"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "numero",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Complemento"
                              type="text"
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  "complemento",
                                  e,
                                  "endereco"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              <h6 className="text-primary">
                                É considerada uma pessoa politicamente exposta?
                              </h6>
                              <div className="flex align-items-center justify-content-start">
                                <Checkbox
                                  group="pessoa_politica"
                                  id="0"
                                  sendValue={true}
                                  helpText="Sim"
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9 || e.which === 9)
                                      e.preventDefault();
                                  }}
                                  sideFunc={(e) => {
                                    this.atualizaFormValues(
                                      null,
                                      e,
                                      "pessoa_politica"
                                    );
                                  }}
                                ></Checkbox>
                                <Checkbox
                                  group="pessoa_politica"
                                  id="1"
                                  sendValue={false}
                                  helpText="Não"
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9 || e.which === 9)
                                      e.preventDefault();
                                  }}
                                  sideFunc={(e) => {
                                    this.atualizaFormValues(
                                      null,
                                      e,
                                      "pessoa_politica"
                                    );
                                  }}
                                ></Checkbox>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </StepperItem>

                      <StepperItem id={2} slideName="Dados Jurídicos">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={12}>
                            <p className="lead text-center mb-3">
                              Conta bancária deve ser da mesma titularidade
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              options={this.state.bankOptions}
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "banco");
                              }}
                            ></Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Agência (sem Dígito)"
                              type="text"
                              // regex="[0-9]*"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "agencia");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Conta (com Dígito)"
                              type="text"
                              // regex="[0-9]*"
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.which === 9)
                                  e.preventDefault();
                              }}
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "conta_digito"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Dropzone onDrop={this.uploadFiles}>
                              {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                                isDragReject,
                              }) => (
                                <section
                                  className={`drop-container ${
                                    isDragActive ? "drop-success" : ""
                                  } ${isDragReject ? "drop-error" : ""}`}
                                >
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    {renderDragMessage(
                                      isDragActive,
                                      isDragReject,
                                      this.state.person_type
                                    )}
                                  </div>
                                  {!!this.state.uploadedFiles.length &&
                                    fileList}
                                </section>
                              )}
                            </Dropzone>
                          </Grid>
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              Anexe os 2 documentos pedidos
                            </Alert>
                          </Grid>
                        </Grid>
                      </StepperItem>

                      <StepperItem id={3} slideName="">
                        <Grid className="pt-2" container spacing={1}>
                          <Grid item xs={12}>
                            <Input
                              label="CNPJ"
                              type="text"
                              regex="[0-9]*"
                              sideFunc={(e) => {
                                this.atualizaFormValues(null, e, "cnpj");
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Razão Social"
                              type="text"
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.which === 9)
                                  e.preventDefault();
                              }}
                              sideFunc={(e) => {
                                this.atualizaFormValues(
                                  null,
                                  e,
                                  "razao_social"
                                );
                              }}
                            ></Input>
                          </Grid>
                          <Grid item xs={12}>
                            <Dropzone onDrop={this.uploadFilesCNPJ}>
                              {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                                isDragReject,
                              }) => (
                                <section
                                  className={`drop-container ${
                                    isDragActive ? "drop-success" : ""
                                  } ${isDragReject ? "drop-error" : ""}`}
                                >
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    {renderDragMessageCNPJ(
                                      isDragActive,
                                      isDragReject,
                                      this.state.person_type
                                    )}
                                  </div>
                                  {!!this.state.uploadedFilesCNPJ.length &&
                                    fileListCNPJ}
                                </section>
                              )}
                            </Dropzone>
                          </Grid>
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              Anexe os 2 documentos pedidos
                            </Alert>
                          </Grid>
                        </Grid>
                      </StepperItem>
                    </div>
                    <StepperFooter>
                      <div className="start">
                        <Button
                          level="transparent"
                          size="lg"
                          label="Voltar"
                          onClick={this.goPrevIndex}
                        ></Button>
                      </div>
                      {/* <div className={`end`}>
                    <Button loading={this.state.loadingFinalizar} level="primary" size="lg" label="Finalizar" onClick={this.submitForm}></Button>
                  </div> */}
                      {this.state.isLastIndex !== true ? (
                        <div className={`end`}>
                          <Button
                            disabled={this.isDisabled()}
                            level="primary"
                            size="lg"
                            label="Continuar"
                            onClick={this.goNextIndex}
                          ></Button>
                        </div>
                      ) : (
                        <div className={`end`}>
                          <Button
                            loading={this.state.loadingFinalizar}
                            disabled={this.isDisabled()}
                            level="primary"
                            size="lg"
                            label="Finalizar"
                            onClick={() => {
                              this.submitForm();
                            }}
                          ></Button>
                        </div>
                      )}
                    </StepperFooter>
                  </StepperContainer>
                ) : (
                  <p className="lead">Aguardando informações...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Cadastro;
