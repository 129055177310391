import React, { useState, useEffect } from "react";
import { storage } from "../../../../../firebase";
import { authenticateWithCustomToken }  from '../../../../../auth';
import { Button } from "primereact/button";

const ListaDocumento = (props) => {
  const [listDocs, setListDocs] = useState([]);

  useEffect(() => {
    if (props.collection !== "" && props.childCollection !== "") {
      getDocs(props.collection, props.childCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collection && props.childCollection]);

  const getDocs = (collection, childCollection) => {
    if (collection && childCollection) {
      authenticateWithCustomToken(sessionStorage.getItem("firebaseToken"));
      storage
        .ref(collection)
        .child(childCollection)
        .listAll()
        .then((res) => {
          res.items.forEach(function (itemRef, index) {
            var fullPath = itemRef
              .toString()
              .substring(itemRef.toString().lastIndexOf("/") + 1);
            storage
              .ref(`${collection}/${childCollection}`)
              .child(fullPath)
              .getDownloadURL()
              .then((url) => {
                setListDocs((prev) => [
                  ...prev,
                  {
                    file: fullPath,
                    fileURL: url,
                  },
                ]);
              });
          });
        });
    }
  };

  const showDoc = (url) => {
    window.open(url);
  };

  return (
    <div className="list-docs-wrapper">
      {listDocs.map((item) => {
        return (
          <div key={item.file} className="list-row">
            <div className="list-column main-column">
              <i className="pi pi-file-pdf mr-1"></i>
              {item.file}
            </div>
            <div className="list-column">
              <Button
                label="Visualizar"
                icon="pi pi-eye"
                onClick={() => {
                  showDoc(item.fileURL);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListaDocumento;
