import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Inputmask from "inputmask";
import { matchPath } from "react-router";
import Button from "../../../components/Button";
import MainHeading from "../../../components/MainHeading";
import ModalInvestment from "./modalInvestment";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";

import API from "../../../api";

const DashInvest = (props) => {
  const [user, setUser] = useState({
    accType: "",
    fee: "",
    minValue: "",
    user_id: null,
    verifiedAcc: null,
  });
  const [snack, setSnack] = useState({
    openState: false,
    severity: "success",
    message: "",
  });
  const [investments, setInvestments] = useState([]);
  const [mdlInvestment, setMdlInvestment] = useState({
    openState: false,
    infos: { id: null },
  });
  const [userMoneyData, setUserMoneyData] = useState({
    saldo_disponivel: 0,
    saldo_investido: 0,
    saldo_receber: 0,
    saldo_total: 0,
  });
  const history = useHistory();
  let isLoading = useState(false);
  useEffect(() => {
    axios.get(`${API}/user/${localStorage.getItem("hashcode")}`).then((res) => {
      setUser({
        accType: res.data[0].user_accounttype,
        hash: res.data[0].user_hashcode,
        fee: res.data[0].user_fee,
        minValue: res.data[0].user_minvalue,
        user_id: res.data[0].user_id,
        verifiedAcc:
          res.data[0].user_bank_account !== null &&
          res.data[0].user_dateregister !== null,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.user_id !== null) {
      if (user.fee === null || user.fee === "") {
        axios.get(`${API}/config`).then(function (response) {
          setUser((prevState) => ({
            ...prevState,
            fee:
              user.accType !== "i"
                ? response.data[0].configuration_feeclient
                : response.data[0].configuration_feeinvestor,
            minValue:
              user.accType === "i" &&
              (user.minValue === "0" || user.minValue === null)
                ? response.data[0].configuration_feeinvestment
                : user.minValue,
          }));
          getInvestments(user.user_id);
        });
      }
    }
  }, [getInvestments, user]);

  function updateAgain() {
    getInvestments(user.user_id);
  }
  function process_line(item) {
    let value =
      100.0 -
      ((item.valor_antecipacao - item.valor_investido) * 100.0) /
        item.valor_antecipacao;
    if (value > 100) {
      value = 100;
    }
    return value.toFixed(2);
  }
  function process_value(item) {
    let value =
      100.0 -
      ((item.valor_antecipacao - item.valor_investido) * 100.0) /
        item.valor_antecipacao;
    if (value > 100) {
      value = 100;
    }
    return value.toFixed(2);
  }

  function compareArray(a, b) {
    switch (b.status && a.status) {
      case 0:
        return a.status - b.status || b.proccess_id - a.proccess_id;
      default:
        return b.proccess_id - a.proccess_id;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getInvestments(id) {
    isLoading = true;
    axios
      .get(`${API}/proccess/status/${id}`)
      .then(function (response) {
        const dataAPI = response.data;
        dataAPI.sort(compareArray);

        let newData = dataAPI.map((processo) => {
          let valor_antecipacao = parseFloat(
            processo.proccess_brutevalue !== null
              ? processo.proccess_brutevalue.replace(".", "").replace(",", ".")
              : "0"
          );
          let valor_investido = parseFloat(
            processo.proccess_investvalue !== null
              ? processo.proccess_investvalue
              : "0"
          );
          let spread_liq = 0;
          if (
            processo.receipt_brutevalue != null ||
            processo.receipt_brutevalue != undefined
          ) {
            let invest_fee =
              processo.user_fee == null || processo.user_fee == 0
                ? processo.investFee / 100
                : processo.user_fee;
            let perc =
              processo.proccess_investvalue /
              parseFloat(
                processo.proccess_brutevalue !== null
                  ? processo.proccess_brutevalue
                      .replace(".", "")
                      .replace(",", ".")
                  : "0"
              );
            let received_brute = processo.receipt_brutevalue * perc;
            let lucroBrutoDaParcela =
              received_brute - processo.receipt_investmentvalue * perc;
            let feeInvestor = invest_fee * lucroBrutoDaParcela;
            spread_liq =
              (perc *
                (processo.receipt_brutevalue -
                  processo.receipt_investmentvalue) -
                feeInvestor) /
              (processo.receipt_investmentvalue * perc);
          }

          return {
            id: processo.proccess_id,
            empresa: processo.proccess_companyname,
            vendedor: processo.user_name,
            cnpj: processo.proccess_cnpj,
            numero_processo: processo.proccess_number,
            sobre: processo.proccess_companyabout,
            sobre_processo: processo.proccess_proccessabout,
            score: processo.proccess_companyscore,
            tipo: processo.proccess_type,
            valor_processo: parseFloat(
              processo.proccess_clientvalue !== null
                ? processo.proccess_clientvalue
                    .replace(".", "")
                    .replace(",", ".")
                : "0"
            ),
            valor_antecipacao: parseFloat(
              processo.proccess_brutevalue !== null
                ? processo.proccess_brutevalue
                    .replace(".", "")
                    .replace(",", ".")
                : "0"
            ),
            valor_spread:
              processo.proccess_expectvaluespread !== null
                ? processo.proccess_expectvaluespread
                : "0",
            periodo_spread: processo.proccess_expectperiodspread,
            recebimento_processo: processo.proccess_expecttermspread,
            valor_investido: parseFloat(
              processo.proccess_investvalue !== null
                ? processo.proccess_investvalue
                : "0"
            ),
            taxa_investimento: user.fee,
            valor_minimo_investimento:
              valor_antecipacao - valor_investido < user.minValue
                ? parseFloat((valor_antecipacao - valor_investido).toFixed(2))
                : parseFloat(parseFloat(user.minValue).toFixed(2)),
            user_id: processo.user_user_id,
            investors: parseInt(processo.proccess_investors),
            status: processo.status,
            invested: processo.valueInvested,
            investment_date: processo.investment_date
              ? processo.investment_date
              : "",
            receipt_date: processo.receipt_date ? processo.receipt_date : "",
            receiptdetail_liq_value: spread_liq,
          };
        });

        setTimeout(() => {
          setInvestments(newData);
        }, 1000);
        isLoading = false;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function moreInfoInvestment(itemInvest, verifiedAcc) {
    if (user.verifiedAcc) {
      setMdlInvestment((prevState) => ({
        ...prevState,
        openState: true,
        infos: {
          id: itemInvest.id,
          cnpj: itemInvest.cnpj,
          empresa: itemInvest.empresa,
          investors: itemInvest.investors,
          numero_processo: itemInvest.numero_processo,
          periodo_spread: itemInvest.periodo_spread,
          recebimento_processo: itemInvest.recebimento_processo,
          score: itemInvest.score,
          sobre: itemInvest.sobre,
          sobre_processo: itemInvest.sobre_processo,
          status: itemInvest.status,
          taxa_investimento: itemInvest.taxa_investimento,
          user_id: itemInvest.user_id,
          valor_antecipacao: itemInvest.valor_antecipacao,
          valor_investido: itemInvest.valor_investido,
          valor_minimo_investimento: itemInvest.valor_minimo_investimento,
          valor_processo: itemInvest.valor_processo,
          valor_spread: itemInvest.valor_spread,
          vendedor: itemInvest.vendedor,
        },
      }));
    } else {
      history.push(`/cadastro?code=${user.hash}`);
    }
  }

  function closeSnacks() {
    setSnack((prevState) => ({
      ...prevState,
      openState: false,
    }));
  }

  const verifyLabel = (status, verifiedAcc) => {
    if (verifiedAcc) {
      switch (status) {
        case 1:
          return "100% INVESTIDO";
        case 2:
          return "INVESTIDORES PAGOS";
        case 3:
          return "RECEBIMENTO PARCELADO";
        default:
          return "INVESTIR";
      }
    } else {
      return "Para conseguir investir finalize o seu cadastro";
    }
  };

  const verifyClass = (status) => {
    switch (status) {
      case 1:
        return "max-investido";
      case 2:
      case 3:
        return "max-investido";
      default:
        return "";
    }
  };

  const verifyDisabled = (itemStatus, verifiedAcc) => {
    let status =
      (itemStatus === 1 || itemStatus === 2 || itemStatus === 3) && verifiedAcc;
    return status;
  };

  const isInvested = (invested) => {
    if (invested !== 0) {
      return (
        <div
          style={{ flex: 0, position: "absolute", right: "1em", top: "1em" }}
        >
          <Tag value="Investido" className="primary" icon="pi pi-tag"></Tag>
        </div>
      );
    }
  };

  const getInvestmentType = (tipo) => {
    switch (tipo) {
      case "0":
        return "Trabalhista";

      case "1":
        return "Cível";

      case "2":
        return "Honorário";

      default:
        return "Não definido";
    }
  };

  const tooltipInvestment =
    "O score varia de 0 a 100 e representa o potencial do investimento, considerando a empresa devedora, a situação do processo, o tempo estimado de recebimento e o spread de compra.";

  const investmentDisplay = investments.map((item, index) => {
    return (
      <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
        <div
          className="card investment-card flex flex-column align-items-start"
          style={{ opacity: item.status === 1 ? 0.7 : 1 }}
        >
          <div>
            {isInvested(item.invested)}
            <div className="flex-0 mb-2">
              <label className="lead card-label">id do processo</label>
              <h6 className="text-primary">{item.id}</h6>
            </div>
            <div className="flex-0 mb-2">
              <Tooltip target=".score-tooltip" />
              <label
                className="lead card-label score-tooltip"
                data-pr-tooltip={tooltipInvestment}
                style={{ cursor: "pointer" }}
              >
                score
                <i
                  className="pi pi-info-circle ml-1"
                  style={{ fontSize: "0.8rem" }}
                ></i>
              </label>
              <h6 className="text-primary">{item.score ? item.score : "-"}</h6>
            </div>
            <div className="flex-0 mb-2">
              <label className="lead card-label">vendedor</label>
              <h6 className="text-primary">
                {item.vendedor ? item.vendedor : "Nenhum"}
              </h6>
            </div>
            <div className="flex-0 mb-2">
              <label className="lead card-label">devedor</label>
              <h6 className="text-primary">{item.empresa}</h6>
            </div>
            {item.status !== 2 && (
              <>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">tipo</label>
                  <h6 className="text-primary">
                    {getInvestmentType(item.tipo)}
                  </h6>
                </div>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">valor</label>
                  <h6 className="text-primary">
                    R${" "}
                    {Inputmask.format(item.valor_antecipacao, {
                      alias: "currency",
                      radixPoint: ",",
                    })}
                  </h6>
                </div>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">
                    Expectativa de rentabilidade bruta no período
                  </label>
                  <h6 className="text-primary text-spread">
                    {Inputmask.format(item.valor_spread, {
                      alias: "decimal",
                      radixPoint: ",",
                      groupSeparator: ",",
                      autoGroup: true,
                      suffix: "%",
                    })}{" "}
                    {item.periodo_spread}
                  </h6>
                </div>
                <div className="flex-0 mb-3" style={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={process_line(item)}
                  />
                  <label className="lead card-label">
                    {process_value(item)}% de R${" "}
                    {Inputmask.format(item.valor_antecipacao, {
                      alias: "currency",
                      radixPoint: ",",
                    })}{" "}
                    Investido
                  </label>
                </div>
              </>
            )}
            {item.status === 2 && (
              <>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">
                    data do investimento
                  </label>
                  <h6 className="text-primary">{item.investment_date}</h6>
                </div>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">data do pagamento</label>
                  <h6 className="text-primary">{item.receipt_date}</h6>
                </div>
                <div className="flex-0 mb-2">
                  <label className="lead card-label">
                    retorno líquido (spread líquido)
                  </label>
                  <h6 className="text-primary">
                    {(item.receiptdetail_liq_value * 100).toFixed(2)} %
                  </h6>
                </div>
              </>
            )}
          </div>
          <Button
            disabled={verifyDisabled(item.status, user.verifiedAcc)}
            label={verifyLabel(item.status, user.verifiedAcc)}
            level={`primary block ${verifyClass(item.status)}`}
            size="md"
            onClick={() => {
              moreInfoInvestment(item);
            }}
          ></Button>
        </div>
      </Grid>
    );
  });

  return (
    <>
      <MainHeading></MainHeading>
      <div className="py-4">
        <Snackbar
          open={snack.openState}
          autoHideDuration={3000}
          onClose={() => {
            closeSnacks();
          }}
        >
          <Alert
            onClose={() => {
              closeSnacks();
            }}
            severity={snack.severity}
          >
            <div dangerouslySetInnerHTML={{ __html: snack.message }}></div>
          </Alert>
        </Snackbar>
        <h4 className="title mb-3">Oportunidades de investimentos</h4>
        <div className="mb-10">
          <Grid className="pt-2" container spacing={2}>
            {investments.length > 0 ? (
              investmentDisplay
            ) : isLoading ? (
              <img src="/r1.svg" alt="loading" />
            ) : (
              <Grid item xs={12}>
                <p className="lead">
                  Nenhuma oportunidade de investimento encontrada
                </p>
              </Grid>
            )}
          </Grid>
        </div>
        {mdlInvestment.openState && user.verifiedAcc && (
          <ModalInvestment
            user={user}
            modalInfo={mdlInvestment}
            onMoneyInvest={(e) => updateAgain()}
          />
        )}
      </div>
    </>
  );
};

export default DashInvest;
