import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../api";

let userID = "";

export class HistoricoTransacoesService {
  constructor() {
    moment.locale("pt-br");
  }

  getTransacaoByID(id) {
    return axios
      .get(`${API}/transaction/user/${userID}`)
      .then((res) => {
        const dataAPI = res.data;
        return dataAPI.filter((item) => item.transaction_id === id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getTransacoesRealizadas(id, profile) {
    var userData = [];
    userID = id;
    return axios.get(`${API}/transaction/user/${id}`).then((res) => {
      // Mapeia o dataAPI e adiciona os valores ao array userData
      const dataAPI = res.data;
      console.log(dataAPI);
      let arrStatus = ["", "Pendente", "Cancelado", "Realizado", "Investido"];

      dataAPI.forEach(function (item) {
        userData.push({
          idMovimentacao: item.transaction_id,
          metodo: item.transaction_desc,
          statusProcesso: item.transaction_status,
          status: arrStatus[item.transaction_status],
          dataMovimentacao: moment(
            item.transaction_registerdate,
            "DD/MM/YYYY"
          ).format("YYYY/MM/DD"),
          trending: getTrending(item.transaction_desc, profile),
          valorMovimentacao:
            item.transaction_value !== null
              ? parseFloat(item.transaction_value?.replace(/,/g, "."))
              : 0,
        });
      });
      return userData;
    });
  }
}

const getTrending = (metodo, perfil) => {
  console.log(perfil);
  if (perfil === "i") {
    switch (metodo) {
      // Negativos
      case "Investimento":
      case "Saque":
        return "";

      // Positivos
      case "Depósito":
      case "Recebimento Líquido":
        return "trending-up";

      default:
        return "";
    }
  }
  if (perfil === "c") {
    switch (metodo) {
      // Negativos
      case "Saque":
      case "Taxa Juscredit - Cliente":
        return "";

      // Positivos
      case "Depósito":
      case "Recebimento Líquido":
      case "Investimento":
        return "trending-up";

      default:
        return "";
    }
  }
};
