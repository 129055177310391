import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '../Button'
import Icon from '../Icon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { openState, actionClose } = props;
  const { isSubmit, actionSubmit } = props;
  const [children] = React.useState(props.children);
  const [modalID] = React.useState(props.id ? props.id : 'mdl_opened');
  const [title] = React.useState(props.modalTitle);

  const handleClose = () => {
    // setOpen(false);
    actionClose();
  };

  const onSubmit = () => {
    actionSubmit();
  }

  // Similar ao componentDidMount e componentDidUpdate:
  // useEffect(() => {
  //   console.log(props.openState);
  // });

  return (
    <Dialog
      id={modalID}
      open={openState}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="text-center title" id="alert-dialog-slide-title">
        <div className="d-flex align-items-center justify-content-between">
          {title}
          <Button level="transparent" size="only-icon" onClick={handleClose}>
            <Icon name="close" bgColor="text-primary" size={24}></Icon>
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      {
        actionSubmit ?
          <DialogActions>
            <Button label="Cancelar" level="primary" size="md" onClick={handleClose}>
            </Button>
            <Button label="Incluir" level="primary" size="md" onClick={onSubmit}>
            </Button>
          </DialogActions> :
          ""
      }
    </Dialog>
  );
}