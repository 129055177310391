import React from 'react';
import './Button.scss';

const Button = (props) => {
  return (
    <button disabled={props.disabled} type={props.type} className={`button ${props.level} ${props.size}`} onClick={props.onClick}>
      {props.loading === true ? <span className="donut"></span> :
        props.children
      }
      {props.loading === true ? '' :
        props.label
      }
    </button>
  )
}

export default Button;