import React from 'react';
import './Divider.scss';

const Divider = (props) => {
  return (
    <div className={`divider-wrapper ${props.className} ${props.zIndex}`} width="100%">
      <div className={`divider-inner height-${props.height} divider-inner-${props.solid}`}></div>
    </div>
  )
}

export default Divider;