import React, { Component } from 'react';

import "./Checkbox.scss";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      stateClass: ''
    };
    this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({ value: this.props.sendValue }, () => {
      this.props.sideFunc(this.state.value);
    });
  }

  render() {
    const props = this.props;
    let idGroup = props.group + '--' + props.id
    // const state = this.state;

    return (
      <div className="checkbox-item">
        <input
          checked={props.isChecked}
          type="radio" id={idGroup}
          name={props.group}
          value={this.state.value}
          onKeyDown={props.onKeyDown}
          onChange={this.handleChange} />
        <label htmlFor={idGroup}>{props.helpText}{props.actionLabel ?
          <a target="_blank" rel="noopener noreferrer" href={`${window.location.origin}${props.actionLink}`} title="teste">{props.actionLabel}</a> :
          ''}</label>
      </div>
    )
  }
}

Checkbox.defaultProps = {
  sideFunc: function () { }
}

export default Checkbox;