import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import API from "../../../../api";

export class BO_FinanceiroService {
  constructor() {
    moment.locale("pt-br");
  }

  getFinanceiro() {
    var mainData = [];
    return axios
      .get(`${API}/transaction/financial`)
      .then(function (response) {
        const dataAPI = response.data;
        console.log(dataAPI);
        mainData.push({
          total_open_investment: dataAPI.total_open_investment,
          total_pending_withdrawal: dataAPI.total_pending_withdrawal,
          total_possible_withdrawal: dataAPI.total_possible_withdrawal,
        });

        return mainData[0];
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFinanceiroByInvestors() {
    return axios
      .get(`${API}/transaction/financial`)
      .then(function (response) {
        const dataAPI = response.data;
        console.log(dataAPI.investor);
        return dataAPI.investor;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFinanceiroByCustomer() {
    return axios
      .get(`${API}/transaction/financial`)
      .then(function (response) {
        const dataAPI = response.data;
        return dataAPI.customer;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFinanceiroByProccess() {
    return axios
      .get(`${API}/transaction/financial`)
      .then(function (response) {
        const dataAPI = response.data;
        return dataAPI.proccess.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
